import BaseModel from '../core/BaseModel'
//import UserModel from '@/models/UserModel'
export default class DocumentCategoryModel extends BaseModel {

    constructor() {
        super({
                name: "document_category",
                tableName: 'document_category',
                label: "Document category",
                display_key: 'name'
            },
            {
                stt: {
                    name: "stt",
                    label: "No.",
                    type: "Computed",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                },
                id: {
                    name: 'id',
                    type: "Uuid",
                    label: 'ID',
                    primaryKey: true,
                    display: false
                },
                name: {
                    name: 'name',
                    type: "String",
                    label: 'Name',
                    width: 15,
                    class:"large-col ",
                    filter: {type: 'input', match: 'contains'},
                },
                ordinal: {
                    name: 'ordinal',
                    type: "String",
                    label: 'Order',
                    class:"xs-col ",
                },
                /*active: {
                    name: "active",
                    label: "Active",
                    type: "Checkbox"
                },*/

                active: {
                    name: "active",
                    label: "Active",
                    type: "Selection",
                    custom: 'SelectionText',
                    options: [
                        {value: 'true', label: 'Yes', style: 'color:  #000000;'},
                        {value: 'false', label: 'No',style: 'color:  #000000;'}
                    ],
                    filter: {
                        type: 'select',
                        optionLabel: 'label',
                        optionKey: 'value',
                        match: 'equals'
                    },
                    class:"center txt-xs-col",
                },
                updated_by_key: {
                    name: "updated_by_key",
                    label: "Updated by",
                    type: "Computed",
                    class:"small-col-midle ",
                    computed: (data) => {
                        data.updated_by_key ='' ;
                    },
                },
                updated_at : { 
                    name: 'updated_at', 
                    type: "Datetime",
                    label: 'Updated date',
                    class:"small-col-midle ",
                    //filter: { type: 'DateRange', match: 'contains'}
                },
                is_use_en: { 
                    name: 'is_use_en', 
                    display: false
                },
                detail_get: {
                    refs:  ' obj_updated_by { id fullname }  lang_arr {id id_lang description document_category_id name }',
                    display: false
                },
            })
    }

}