<template>
    <div class="calendar-footer">
        <Button label="OK" class="p-button-success" @click="applyDateFilter"></Button>
    </div>
</template>

<script>
export default {
    methods: {
        applyDateFilter() {
            document.querySelector('.p-datepicker').style.display = 'none';
        },
    },
};
</script>

<style scoped>
.calendar-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
}

.calendar-footer .p-button {
    margin: 0 0.5rem;
    justify-content: center;
    align-items: center;
    text-align: center;
    justify-items: center;
    margin-left: 155px;
    margin-top: -70px;
    background-color: #1D76BC;
    border: none;
    height: 39px;
    width: 80px;
}

.calendar-footer .p-button:hover {
    background-color: #1D76BC;
}
</style>