import BaseModel from '../core/BaseModel'
// import UserModel from '@/models/UserModel'
export default class BookingModel extends BaseModel {

    constructor() {
        super({
                name: "booking",
                tableName: 'booking',
                label: "Booking Section"
            },
            {
                stt: {
                    name: "stt",
                    label: "No.",
                    type: "Computed",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                },
                id: {
                    name: 'id',
                    type: "Uuid",
                    label: 'ID',
                    primaryKey: true,
                    display: false
                },
                property: {
                    refs: "property {id name part {id part}}",
                    display: false
                },
                booking_round: {
                    refs: "booking_round {id round status ordinal part_id week_id}",
                    display: false
                },
                user_update: {
                    refs: "user_update {id fullname}",
                    display: false
                },
                is_done: {
                    name: "is_done",
                    display: false
                },
                property_name: {
                    name: 'property_name',
                    type: "String",
                    label: 'Property',
                    // width : 20,
                    filter: {type: 'input', match: 'contains'},
                    computed: (data) => {
                        data.property_name = '';
                    },
                },
                from_to: {
                    name: 'from_to',
                    type: "String",
                    label: 'From - to',
                    sortable: false,
                    // width: 8,
                    filter: { type: 'DateRange', match: 'contains'},
                    computed: (data) => {
                        data.from_to = '';
                    },
                },
                start_visit_date: {
                    name: 'start_visit_date',
                    display: false
                },
                end_visit_date: {
                    name: 'end_visit_date',
                    display: false
                },
                start_booking_date: {
                    name: 'start_booking_date',
                    display: false
                },
                end_booking_date: {
                    name: 'end_booking_date',
                    display: false
                },
                start_end: {
                    name: 'start_end',
                    type: "String",
                    label: 'Start - End',
                    sortable: false,
                    // width: 8,
                    filter: { type: 'DateRange', match: 'contains'},
                    computed: (data) => {
                        data.start_end = '';
                    },
                },
                part : {
                    name: 'part',
                    type: "String",
                    label: 'No. Part',
                    // width : 20,
                    // filter: {type: 'input', match: 'contains'},
                    // display: false
                    computed: (data) => {
                        data.part = '';
                    }
                },
                done: {
                    name: "done",
                    label: "Done",
                    type: "String",
                    options: [{
                        label: 'Done',
                        value: true
                    },{
                        label: 'Process',
                        value: false
                    }],
                    filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                    computed: (data) => {
                        data.done = '';
                    },
                },
                updated_name : { 
                    name: 'updated_name', 
                    type: "String",
                    // custom: "SelectionText",
                    label: 'Updated by',
                    // models: new UserModel(),
                    // filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                    filter: {type: 'input', match: 'contains'},
                    computed: (data) => {
                        data.updated_name = '';
                    },
                },
                updated_at : { 
                    name: 'updated_at', 
                    type: "Datetime",
                    label: 'Updated date',
                    filter: { type: 'DateRange', match: 'contains'}
                }
            })
    }

}