<template>
  <div>
    <div class="row manager-title-row">
      <div class="p-col-4 manager-title-custom">{{ title_service }}</div>
    </div>
    <form id="frm_action" v-if="is_change_product == 0" :key="componentKeyService">
      <div class="p-formgrid">
        <Toolbar class="fixed-bottom-toolbar div-button css-button-top-right">
          <template slot="right">
            <Button label="Back" @click="backToList()" id="back" class="main-style-button" />
          </template>
        </Toolbar>
        <div class="col-6">
          <div class="p-card-field">
            <label class="p-col-fixed txt-right txt-required">Service type of property</label>
            <div class="p-col">
              <v-select :key="dropdown_action" :clearable="false" title="Property" class="" @search="SearchPropertyType"
                placeholder="Choose" v-model="obj_data.type_id" :options="listPropertyType" :reduce="item => item.id"
                label="name" @input="changePropertyType">
                <span slot="no-options" @click="$refs.select.open = false">
                  Empty data
                </span>
              </v-select>
              <input class="input_tmp_validator" id="type_id">
            </div>
          </div>
          <!-- <div class="p-card-field">
            <label class="p-col-fixed txt-right txt-required">Service name</label>
            <div class="p-col">
              <InputText maxlength="100" id="name" type="text" class="" v-model="obj_data.name" placeholder="Input" />
            </div>
          </div> -->

          <div class="p-card-field">
            <div class="row">
              <div class="col-6">
                <label class="p-col-fixed txt-right txt-required">Available for use from</label>
                <div class="p-col">
                  <Calendar :showOnFocus="false" dateFormat="MM dd, yy" :showIcon="true" appendTo="body"
                    :showClear="true" :manualInput="false" v-model="obj_data.available_from" placeholder="Choose" />
                  <input class="input_tmp_validator" id="available_from" />
                </div>
              </div>

              <div class="col-6">
                <label class="p-col-fixed txt-right">To</label>
                <div class="p-col">
                  <Calendar :showOnFocus="false" dateFormat="MM dd, yy" id="end_booking_date" :showIcon="true"
                    appendTo="body" :showClear="true" :manualInput="false" v-model="obj_data.available_to"
                    placeholder="Choose" />
                  <input class="input_tmp_validator" id="available_to" />
                </div>
              </div>
            </div>
          </div>
          <div class="p-card-field">
            <label class="p-col-fixed txt-right ">Need to be order before arrivle date</label>
            <div class="p-col">
              <div class="p-inputgroup">
                <!--              <span class="p-inputgroup-addon">$</span>-->
                <InputNumber id="before_arrival_date" v-model="obj_data.before_arrival_date"
                  placeholder="0, the users can order at anytime." />
                <span class="p-inputgroup-addon">day</span>
              </div>
            </div>
          </div>
        </div>




        <div v-for="(item, index) in listLang" :key="item.id">
          <!-- Field for Service Name and Description -->
          <div class="col-6" v-if="item.iso_code === 'dk'">
            <div class="p-card-field">
              <label class="p-col-fixed txt-right txt-required">Service name ({{ item.iso_code.toUpperCase() }})</label>
              <div class="p-col">
                <InputText maxlength="100" :id="'name_' + item.iso_code" type="text" v-model="item.service_name"
                  placeholder="Input" />
              </div>
            </div>
          </div>

          <div class="col-12" v-if="item.iso_code === 'dk'">
            <div class="p-card-field">
              <label class="p-col-fixed txt-right txt-required">Description ({{ item.iso_code.toUpperCase() }})</label>
              <div class="p-col">
                <input class="input_tmp_validator" :id="'validator_content_' + item.iso_code">
                <CkditorCore :contentSet="item.content" @newdata="handleDataCkditor($event, index, 'content')" />
              </div>
            </div>
          </div>

          <!-- Checkbox to enable English fields -->
          <div class="col-6" v-if="item.iso_code === 'dk' && index === 0">
            <div class="p-card-field div_check">
              <div class="p-col checkbox">
                <Checkbox v-model="obj_data.is_use_en" :binary="true" />
                <label class="p-col-fixed txt-right text">Use English</label>
              </div>
            </div>
          </div>

          <!-- English Section -->
          <div class="col-6" v-if="item.iso_code === 'en'">
            <div class="p-card-field">
              <label class="p-col-fixed txt-right txt-required">Service name ({{ item.iso_code.toUpperCase() }})</label>
              <div class="p-col">
                <InputText :disabled="!obj_data.is_use_en" maxlength="100" :id="'name_' + item.iso_code" type="text"
                  v-model="item.service_name" placeholder="Input" />
              </div>
            </div>
          </div>

          <div class="col-12" v-if="item.iso_code === 'en'">
            <div class="p-card-field">
              <label class="p-col-fixed txt-right txt-required">Description ({{ item.iso_code.toUpperCase() }})</label>
              <div class="p-col">
                <!-- input class="input_tmp_validator" :id="'validator_content_' + item_ll.iso_code"> -->
                <input class="input_tmp_validator" :id="'validator_content_' + item.iso_code">
                <CkditorCore :disabledCK="!obj_data.is_use_en" :contentSet="item.content"
                  @newdata="handleDataCkditor($event, index, 'content')" />
              </div>
            </div>
          </div>
        </div>




        <div class="col-6">
          <div class="p-card-field">
            <label class="p-col-fixed txt-right txt-required">Images</label>
            <div class="p-col">
              <div class="cus-text">Recommend: 1.000x1.000px Maximum:10 MB</div>
              <div class="cus-text">Accepted file:JPG, JPEG, PNG, GIF.</div>
              <input class="p-inputtext p-component" accept="image/*" name="images[]" id="images_service" type="file"
                @change="onFileSelectFile" multiple /><!--@change="onFileSelectLogo"-->
              <input class="input_tmp_validator" id="list_file">
              <div class="cus-text style_list_image">
                <div class="box-image" v-for="(item, index) in list_file" :key="index">
                  <img class="style_img" :src="item.objectURL" />
                  <div class="button-clear" @click="onFileClear(item, index)"><img
                      src="/assets/images/icon/cancel_black_24dp.png" /></div>
                </div>
                <!--              <span :key="comFilename">{{ obj_data.filename }}</span>-->
              </div>

              <!--            <div class="select-button" style="background-color: rgb(227, 237, 252);height: max-content;text-align: justify;justify-items: center;display: flex;padding: 0.55rem;margin: -0.429rem;">
              <span style="margin: auto;">Tải lên</span>
            </div>-->
            </div>
          </div>
          <div class="p-card-field">
            <label class="p-col-fixed txt-right style_title_group ">PRODUCTS</label>
            <!--            <div class="p-col">
            </div>-->
          </div>
        </div>
        <div class="col-12">
          <div class="p-card-field">
            <div class="p-col">
              <BaseDatatable v-if="dataModelProduct" :key="componentKeyProduct" :tableData="listDataProduct"
                :model="dataModelProduct" :actions="getRowActions()" v-on:action-click="onActionClicked">
              </BaseDatatable>
            </div>
          </div>
        </div>
        <!--      <div class="col-12">
        <div class="p-card-field">
          <label class="p-col-fixed txt-right style_title_group ">MANDATORY</label>
          <div class="p-col">
          </div>
        </div>
        <div class="p-card-field">
          <div class="p-col">
            <BaseDatatableMadatory
                v-if="dataModelMadatory"
                :key="componentKeyMadatory"
                :tableData="listDataMadatory"
                :model="dataModelMadatory"
                v-on:action-click="onActionClicked"
                @onDataChange = "DataOnChangeMadatory"
            ></BaseDatatableMadatory>
          </div>
        </div>
      </div>-->
        <div class="col-6">

          <div class="p-card-field div_check">
            <div class="p-col checkbox">
              <Checkbox v-model="obj_data.need_assign" :binary="true" />
              <label class="p-col-fixed txt-right text">Need to assign</label>
            </div>
          </div>

          <div class="p-card-field div_check">
            <div class="p-col checkbox">
              <Checkbox v-model="obj_data.time_to_finish" :binary="true" />
              <label class="p-col-fixed txt-right text">Time to be finish</label>
            </div>
          </div>



          <div class="p-card-field div_check">
            <div class="p-col checkbox">
              <Checkbox v-model="obj_data.sold_out" :binary="true" />
              <label class="p-col-fixed txt-right text">Sold out</label>
            </div>
          </div>
          <!--          <div class="p-card-field">
            <label class="p-col-fixed txt-right">Order</label>
            <div class="p-col">
              <InputNumber v-model="obj_data.ordinal">
              </InputNumber>
            </div>
          </div>-->
          <div class="p-card-field div_check">
            <div class="p-col checkbox">
              <Checkbox v-model="obj_data.active" :binary="true" />
              <label class="p-col-fixed txt-right text">Active</label>
            </div>
          </div>

        </div>
        <Toolbar class="fixed-bottom-toolbar div-button">
          <template slot="right">
            <Button :label="$constants.TEXT_BUTTON.BACK" @click="backToList()" class="p-button-outlined" id="back" />
            <Button v-if="mode == 'edit'" :label="$constants.TEXT_BUTTON.EDIT" @click="saveData()"
              class="main-style-button" />
            <Button v-else :label="$constants.TEXT_BUTTON.ADD" @click="saveData()" class="main-style-button" />
          </template>
        </Toolbar>
        <Dialog header="Delete" :visible.sync="showDeleteDialog" :contentStyle="{ overflow: 'visible' }" :modal="true">
          Are you want to delete this product ?
          <template #footer>

            <Button label="Cancel" @click="showDeleteDialog = false" icon="pi pi-times" class="p-button-warning" />
            <Button label="Confirm" @click="deleteData()" icon="pi pi-check"
              class="p-button-danger  btn-confirm-delete-dialog" />
          </template>
        </Dialog>
      </div>
    </form>
    <form id="frm_action" v-if="is_change_product == 1" :key="keyProductLoad">
      <div class="p-formgrid">
        <div class="col-6">
          <!-- <div class="p-card-field">
            <label class="p-col-fixed txt-right txt-required">Product name</label>
            <div class="p-col">
              <InputText maxlength="100" id="product_name" type="text" class="" v-model="obj_data_product.name"
                placeholder="Input" />
            </div>
          </div> -->
          <div class="p-card-field">
            <label class="p-col-fixed txt-right txt-required">Price</label>
            <div class="p-col">
              <div class="p-inputgroup">
                <!--              <span class="p-inputgroup-addon">$</span>-->
                <InputNumber :key="price_product" id="product_price" placeholder="Price"
                  v-model="obj_data_product.price" mode="decimal" :minFractionDigits="2" />
                <span class="p-inputgroup-addon">EUR</span>
              </div>
            </div>
          </div>

          <div class="p-card-field">
            <label class="p-col-fixed txt-right txt-required">Images</label>
            <div class="p-col">
              <div class="cus-text">Recommend: 1.000x1.000px Maximum:10 MB</div>
              <div class="cus-text">Accepted file:JPG, JPEG, PNG, GIF.</div>
              <input class="p-inputtext p-component" accept="image/*" name="images[]" id="images_service" type="file"
                @change="onFileSelectFileProduct" multiple /><!--@change="onFileSelectLogo"-->
              <input class="input_tmp_validator" id="list_file">
              <div class="cus-text style_list_image">
                <div class="box-image" v-for="(item_p, index_p) in list_file_product" :key="index_p">
                  <img class="style_img" :src="item_p.objectURL" />
                  <div class="button-clear" @click="onFileClearProduct(item_p, index_p)"><img
                      src="/assets/images/icon/cancel_black_24dp.png" /></div>
                </div>
                <!--              <span :key="comFilename">{{ obj_data.filename }}</span>-->
              </div>

              <!--            <div class="select-button" style="background-color: rgb(227, 237, 252);height: max-content;text-align: justify;justify-items: center;display: flex;padding: 0.55rem;margin: -0.429rem;">
                <span style="margin: auto;">Tải lên</span>
              </div>-->
            </div>
          </div>
        </div>

        <!-- <div class="col-12">
          <div class="p-card-field" v-for="(item_lp, index_lp) in listLangProduct" :key="index_lp">
            <label class="p-col-fixed txt-right txt-required">Description
              ({{ item_lp.iso_code.toString().toUpperCase() }})</label>
            <div class="p-col">
              <input class="input_tmp_validator" :id="'validator_content_' + item_lp.iso_code">
              <CkditorCore :contentSet="item_lp.content"
                @newdata="handleDataCkditorProduct($event, index_lp, 'content')">
              </CkditorCore>
            </div>
          </div>
        </div> -->


        <div v-for="(item, index) in listLangProduct" :key="item.id">
          <!-- Field for Service Name and Description -->
          <div class="col-6" v-if="item.iso_code === 'dk'">
            <div class="p-card-field">
              <label class="p-col-fixed txt-right txt-required">Product name
 ({{ item.iso_code.toUpperCase() }})</label>
              <div class="p-col">
                <InputText maxlength="100" :id="'name_product_' + item.iso_code" type="text" v-model="item.name_product"
                  placeholder="Input" />
              </div>
            </div>
          </div>

          <div class="col-12" v-if="item.iso_code === 'dk'">
            <div class="p-card-field">
              <label class="p-col-fixed txt-right txt-required">Description ({{ item.iso_code.toUpperCase() }})</label>
              <div class="p-col">
                <input class="input_tmp_validator" :id="'validator_content_product_' + item.iso_code">
                <CkditorCore :contentSet="item.content" @newdata="handleDataCkditorProduct($event, index, 'description')" />
              </div>
            </div>
          </div>

          <!-- Checkbox to enable English fields -->
          <div class="col-6" v-if="item.iso_code === 'dk' && index === 0">
            <div class="p-card-field div_check">
              <div class="p-col checkbox">
                <Checkbox v-model="obj_data_product.is_use_en" :binary="true" />
                <label class="p-col-fixed txt-right text">Use English</label>
              </div>
            </div>
          </div>

          <!-- English Section -->
          <div class="col-6" v-if="item.iso_code === 'en'">
            <div class="p-card-field">
              <label class="p-col-fixed txt-right txt-required">Product name
 ({{ item.iso_code.toUpperCase() }})</label>
              <div class="p-col">
                <InputText :disabled="!obj_data_product.is_use_en" maxlength="100" :id="'name_product_' + item.iso_code" type="text"
                  v-model="item.name_product" placeholder="Input" />
              </div>
            </div>
          </div>

          <div class="col-12" v-if="item.iso_code === 'en'">
            <div class="p-card-field">
              <label class="p-col-fixed txt-right txt-required">Description ({{ item.iso_code.toUpperCase() }})</label>
              <div class="p-col">
                <!-- input class="input_tmp_validator" :id="'validator_content_' + item_ll.iso_code"> -->
                <input class="input_tmp_validator" :id="'validator_content_product_' + item.iso_code">
                <CkditorCore :disabledCK="!obj_data_product.is_use_en" :contentSet="item.content"
                  @newdata="handleDataCkditorProduct($event, index, 'description')" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="p-card-field">
            <label class="p-col-fixed txt-right">Order</label>
            <div class="p-col">
              <InputNumber v-model="obj_data_product.ordinal">
              </InputNumber>
            </div>
          </div>
          <div class="p-card-field div_check">
            <div class="p-col checkbox">
              <Checkbox v-model="obj_data_product.active" :binary="true" />
              <label class="p-col-fixed txt-right text">Active</label>
            </div>
          </div>

        </div>
        <!--        <div class="col-12">
          <div class="p-card-field">
            <div class="p-col">
              <BaseDatatable
                  v-if="dataModelProduct"
                  :key="componentKeyProduct"
                  :tableData="listDataProduct"
                  :model="dataModelProduct"

                  :actions="getRowActions()"

                  v-on:action-click="onActionClicked"
              ></BaseDatatable>
            </div>
          </div>
        </div>-->
        <!--        <div class="col-12">
          <div class="p-card-field">
            <label class="p-col-fixed txt-right style_title_group ">MANDATORY</label>
            <div class="p-col">
            </div>
          </div>
          <div class="p-card-field">
            <div class="p-col">
              <BaseDatatableMadatory
                  v-if="dataModelMadatory"
                  :key="componentKeyMadatory"
                  :tableData="listDataMadatory"
                  :model="dataModelMadatory"
                  v-on:action-click="onActionClicked"
                  @onDataChange = "DataOnChangeMadatory"
              ></BaseDatatableMadatory>
            </div>
          </div>
        </div>-->
        <!--        <div class="col-6">

          <div class="p-card-field div_check">
            <div class="p-col checkbox">
              <Checkbox  v-model="obj_data.need_assign"  :binary="true"/>
              <label class="p-col-fixed txt-right text">Need to assign</label>
            </div>
          </div>

          <div class="p-card-field div_check">
            <div class="p-col checkbox">
              <Checkbox  v-model="obj_data.time_to_finish"  :binary="true"/>
              <label class="p-col-fixed txt-right text">Time to be finish</label>
            </div>
          </div>



          <div class="p-card-field div_check">
            <div class="p-col checkbox">
              <Checkbox  v-model="obj_data.sold_out"  :binary="true"/>
              <label class="p-col-fixed txt-right text">Sold out</label>
            </div>
          </div>


        </div>-->
        <Toolbar class="fixed-bottom-toolbar div-button">
          <template slot="right">
            <Button :label="$constants.TEXT_BUTTON.BACK" @click="backToListProduct()" class="p-button-outlined"
              id="back" />
            <Button v-if="mode == 'edit'" :label="'Save'" @click="saveDataProduct()" class="main-style-button" />
            <!--        :label="$constants.TEXT_BUTTON.ADD"    -->
            <Button v-else :label="'Save'" @click="saveDataProduct()" class="main-style-button" />
          </template>
        </Toolbar>
        <!--        <Dialog header="Delete" :visible.sync="showDeleteDialog" :contentStyle="{ overflow: 'visible' }" :modal="true">
          Are you want to delete this category ?
          <template #footer>

            <Button label="Cancel" @click="showDeleteDialog = false" icon="pi pi-times" class="p-button-warning" />
            <Button label="Confirm" @click="deleteData()" icon="pi pi-check"
                    class="p-button-danger  btn-confirm-delete-dialog" />
          </template>
        </Dialog>-->
      </div>
    </form>
  </div>
</template>
<script>
import moment from "moment-timezone";
import DataServices from '@/core/DataServices'
import CkditorCore from '../CkditorCore';
import BaseDatatable from './list_product'
//import BaseDatatableMadatory from './list_madatory'
export default {
  components: {
    CkditorCore,
    BaseDatatable,
    //BaseDatatableMadatory
  },
  props: {
    mode: String,
    model: Object,
    users: Array
  },
  data() {
    return {
      dropdown_action:1,
      price_product:1,

      map_list_lang: 1,

      key_type_id: 1,
      is_change_product: 0,
      keyProductLoad: 1,
      title_action:'',

      //mapServicePriceProduct:{},
      dataModelProduct: null,
      componentKeyProduct: 1,
      listDataProduct: [],

      componentKeyService: 1,

      dataModelMadatory: null,
      componentKeyMadatory: 1,
      listDataMadatory: [],

      deleteId: null,
      deleteName: null,
      showDeleteDialog: false,

      mapServicePriceProduct: {},
      /* dataModelProduct:null,
       componentKeyProduct:1,
       listDataProduct:[],*/

      cke_key: 1,
      listLang: [],
      listLangProduct: [],
      listLangProductTmp: [],
      content_en: '',
      obj_data_product: {},
      obj_data: {
        filename: "",
        prefix_phone: "",
      },
      comFilename: 1,
      list_phone_code: [
        {
          prefix_phone: "+84",
        },
        {
          prefix_phone: "+45",
        }
      ],
      dataBookingRound: [],
      selProperty: null,
      mapPropertyType: {},
      listPropertyType: [],
      listPropertyTypeAll: [],
      listDocsCate: [],
      listDocsCateAll: [],
      listCountry: [],
      listCountryAll: [],
      listState: [],
      listStateAll: [],
      listUser: [],
      listUserAll: [],
      from_to: null,
      address: null,
      start_booking_date: null,
      end_booking_date: null,
      partArr: [],
      time_zone_local: moment.tz.guess(true),
      componentKey: 0,
      list_file: [],
      list_file_product: [],
      list_id_file_delete_product: [],
      maxFileSize: 2097152 * 50, //100MB  2MB   /*104857600, // 100MB*/
      list_part: [
        {
          "uid": null,
          //"uid": "ee053528-31bb-4822-a620-c5664ecd1cd7",
          "part": "1",
          "order_booking": 1,
          "color": null
        },
        {
          "uid": null,
          //"uid": "79ed9b2a-ebfb-46d7-87d4-982e8cdb8f65",
          "part": "2",
          "order_booking": 2,
          "color": null
        },
        {
          "uid": null,
          //"uid": "321674aa-9161-4193-8422-5bd26a3224c2",
          "part": "3",
          "order_booking": 3,
          "color": null
        },
        {
          "uid": null,
          //"uid":  "75629674-e721-493c-81b0-8ba014ae2aa0",
          "part": "4",
          "order_booking": 4,
          "color": null
        },
        {
          "uid": null,
          //"uid": "d9d3a852-1932-43b6-a2b8-b3ac0e7257cf",
          "part": "5",
          "order_booking": 5,
          "color": null
        }
      ],
      list_madatory: [
        {
          "type_of_use_name": 'Property owner',
          "type_of_use": 1,
          "auto_add_to_cart": false,
          "user_remove_it": false,
          "day_of_stay_more_than": 0,
          "index": 0,
        },
        {
          "type_of_use_name": 'Five2one owner',
          "type_of_use": 2,
          "auto_add_to_cart": false,
          "user_remove_it": false,
          "day_of_stay_more_than": 0,
          "index": 1,
        },
        {
          "type_of_use_name": 'Borrower',
          "type_of_use": 3,
          "auto_add_to_cart": false,
          "user_remove_it": false,
          "day_of_stay_more_than": 0,
          "index": 2,
        },
        {
          "type_of_use_name": 'External renter',
          "type_of_use": 4,
          "auto_add_to_cart": false,
          "user_remove_it": false,
          "day_of_stay_more_than": 0,
          "index": 3,
        }
      ],
      index_edit: null,
    }
  },
  watch: {
    /* async "selProperty"() {
       if (!this.$commonFuction.isEmpty(this.selProperty)) {
         this.address = this.selProperty?.address;
         this.partArr = this.selProperty?.part;
         if (this.mode == 'add') {
           var arr = [];
           this.partArr.map(m => {
             arr.push({ord: m.order_booking, id: m.id});
           })
           arr.map(m=> {
             var tmp = [];
             for (let i =0; i < arr.length; i++) {
               let a = m.ord - i + arr.length;
               if (a > arr.length) {
                 a = a - arr.length;
               }
               tmp.push({ordinal: a, part_id: m.id, round: (i+1)});
             }
             this.dataBookingRound.push(tmp);
           })
         }
         this.componentKey++;
       }
     },
     "start_booking_date"() {
       if (this.partArr.length) {
         let days = 14 * this.partArr.length;
         this.end_booking_date = moment.tz(this.start_booking_date, this.time_zone_local).add(days, 'days').format("LL");
       }
     }*/
  },
  computed: {
    title_service() {
      var text = '';
      if (this.is_change_product==0){
        if (this.mode =='edit'){
          text='Edit'
        }else {
          text='Create'
        }
      }else {
        if (!this.$commonFuction.isEmpty(this.obj_data_product.id)) {
          text='Edit product'
        }else {
          text='Add product'
        }
      }
      return text;
    },
  },
  async mounted() {
    this.getListPropertyType();
    //this.getListCountry();
    //this.getListState();
    //this.getListUser();
    /*
    await this.getListDocsCate();*/
    console.log("this.model:", this.model)




    var dataLang = await this.$CoreService.getListLang({});
    var list_lang = dataLang.list
    this.map_list_lang = dataLang.map_list

    // this.listLangProduct = this.$commonFuction.convertJsonObject(list_lang);
    this.listLangProductTmp = this.$commonFuction.convertJsonObject(list_lang);
    if (this.model !== undefined && Object.keys(this.model).length > 0) {
      console.log("66666666666666666")
      this.obj_data =this.$commonFuction.convertJsonObject( this.model);
      if (!this.$commonFuction.isEmpty(this.model?.list_file_edit)) {
        var list_file_edit = this.model?.list_file_edit
        for (let i = 0; i < list_file_edit.length; i++) {
          var item_file = list_file_edit[i]
          var obj = { id: item_file.id, 'name': '', 'file': '', 'objectURL': item_file.url, type: 'old_edit' }//type:'old_edit' nếu gặp type này ko update avatar
          this.list_file.push(obj)
        }
      }
      var type_of_property_arr = this.model?.type_of_property_arr ?? [];
      if (type_of_property_arr.length > 0) {
        this.obj_data.type_id = type_of_property_arr[0].type_id
        console.log("this.obj_data.type_id:", this.obj_data.type_id)
      }

      var list_part = this.model?.part ?? []
      for (let g = 0; g < list_part.length; g++) {
        var item_part = list_part[g];
        var order_booking = item_part?.order_booking - 1
        var uid_part = item_part?.uid
        this.list_part[order_booking]["uid"] = uid_part;
      }

      /*
            this.obj_data.filename = this.model?.item_avatar?.file_name ?? ''
            if (!this.$commonFuction.isEmpty(this.model?.item_avatar)) {
              let url = this.model?.item_avatar?.url
              let obj = {'name': this.obj_data.filename, 'file': '', 'objectURL': url, type: 'old_edit'}//type:'old_edit' nếu gặp type này ko update avatar
              this.list_file.push(obj)
            }
            */
      if (!this.$commonFuction.isEmpty(this.obj_data.available_from)) {
        this.obj_data.available_from = moment.tz(this.obj_data.available_from, this.time_zone_local).format("LL");
      }
      if (!this.$commonFuction.isEmpty(this.obj_data.available_to)) {
        this.obj_data.available_to = moment.tz(this.obj_data.available_to, this.time_zone_local).format("LL");
      }

      var lang_ser_arr = this.model?.lang_arr ?? [];
      var mapLS = {};
      for (let h = 0; h < lang_ser_arr.length; h++) {
        var itemLS = lang_ser_arr[h];
        mapLS[itemLS.id_lang] = itemLS;
      }
      console.log("list_langlist_langlist_langlist_lang",list_lang);
      
      for (let i = 0; i < list_lang.length; i++) {
        var itemL = list_lang[i];
        var id_l = itemL.id
        list_lang[i].content = (!this.$commonFuction.isEmpty(mapLS[id_l])) ? mapLS[id_l].description : ''
        list_lang[i].service_name = (!this.$commonFuction.isEmpty(mapLS[id_l])) ? mapLS[id_l].name : '';

      }
      this.listLang = list_lang

      var where_key = {
        deleted: { _eq: false },
        service_id: { _eq: this.model.id },
        // booking: {id: {_is_null: true} },
      }
      var data_list_product = await this.$CoreService.getListProduct(where_key);
      var list_product = this.$commonFuction.convertJsonObject(data_list_product.list)
      console.log("list_productlist_product:", list_product)
      //xử lý type
      //var type_of_property_arr = this.model?.type_of_property_arr ?? [];
      var list_product_convert = [];
      for (let g = 0; g < list_product.length; g++) {
        var itemDataProduct = list_product[g];
        console.log("itemDataProduct:", itemDataProduct)
        var lang_arr_p = itemDataProduct?.lang_arr ?? [];
        var list_file_product = itemDataProduct?.list_file_product ?? [];
        //var lang_arr_p = itemDataProduct?.lang_arr ?? [];
        console.log("lang_arr_plang_arr_p:", lang_arr_p)
        for (let kj = 0; kj < lang_arr_p.length; kj++) {
          var itemLangP = lang_arr_p[kj];
          var id_lang_P = itemLangP?.id_lang ?? null;
          lang_arr_p[kj].content = lang_arr_p[kj].description;
          console.log('ffffffffffffffffffffffffff',lang_arr_p[kj].description);
          
          lang_arr_p[kj].id = lang_arr_p[kj].id_lang;
          lang_arr_p[kj].name_product = lang_arr_p[kj].name;
          if (!this.$commonFuction.isEmpty(this.map_list_lang[id_lang_P])) {
            lang_arr_p[kj].iso_code = this.map_list_lang[id_lang_P].iso_code;
          }
        }
        console.log("list_file_product:da:", list_file_product)
        var list_convert_file = [];
        for (let jj = 0; jj < list_file_product.length; jj++) {
          var itemFile = list_file_product[jj]
          if (itemFile.type == this.$constants.TYPE_IMAGE.PRODUCT_AVATAR) {
            itemDataProduct.avatar = itemFile?.url ?? null
          }
          itemFile["objectURL"] = itemFile?.url ?? null;
          itemFile["type"] = 'old_edit';
          list_convert_file.push(itemFile)
        }

        itemDataProduct["list_file_product"] = list_convert_file;
        itemDataProduct["listLangProduct"] = lang_arr_p;
        itemDataProduct["description"] = lang_arr_p[0].content;
        list_product_convert.push(itemDataProduct)
      }
      this.listDataProduct = list_product_convert;
      console.log("this.listDataProduct:", this.listDataProduct)
      this.loadTableTypeProduct();

      //xứ lý mandatory
      var mandatory_arr = this.model?.mandatory_arr ?? [];
      var mapMan = {};
      for (let k = 0; k < mandatory_arr.length; k++) {
        var itemMan = mandatory_arr[k];
        mapMan[itemMan.type_user] = itemMan;
      }
      console.log("mapManmapMan:", mapMan)
      console.log("mandatory_arr:", mandatory_arr)
      for (let m = 0; m < this.list_madatory.length; m++) {
        var itemM = this.list_madatory[m];
        var type_of_use = itemM.type_of_use
        //console.log("type_of_use:",type_of_use)
        //console.log("type_of_use:",type_of_use)
        this.list_madatory[m].auto_add_to_cart = (!this.$commonFuction.isEmpty(mapMan[type_of_use])) ? mapMan[type_of_use].auto_add_to_cart : false;
        this.list_madatory[m].user_remove_it = (!this.$commonFuction.isEmpty(mapMan[type_of_use])) ? mapMan[type_of_use].user_remove_it : false;
        this.list_madatory[m].day_of_stay_more_than = (!this.$commonFuction.isEmpty(mapMan[type_of_use])) ? mapMan[type_of_use].day_of_stay_more_than : false;
      }
      
      this.dataModelMadatory = DataServices.getModel('service_madatory');
      this.listDataMadatory = this.list_madatory
      console.log("this.listDataMadatory:", this.listDataMadatory)
      this.dataModelMadatory.processData(this.list_madatory)
      this.componentKeyMadatory++;
      this.componentKey++;
      this.componentKeyService++;
      this.dropdown_action++;
    } else {
      for (let i = 0; i < list_lang.length; i++) {
        list_lang[i].content = '';
        list_lang[i].service_name = '';

      }
      this.listLang = list_lang
      console.log("listLanglistLanglength:", this.listLang.length)
      //thêm mới
      this.obj_data = {
        /*type_id: '27191cac-7d56-4231-bc50-ee39befb8b4a',
        name: 'service for car tax',
        before_arrival_date: 1,
        available_from: moment.tz().format("LL"),
        available_to: moment.tz().format("LL"),*/
        active: true,
        type_of_price: '1',
        ordinal: 1,

      };
      //gán data
      //this.listDataProduct = [ { "id": "3ba3f00e-d926-437a-bde6-0ca41fcdcb2c", "type_name": "type2", "price_key": 2342, "stt": 1, "index": 0, "type_name_key": "type2", "price_key_tmp": 2342 } ];
      //this.loadTableTypeProduct()

      this.dataModelMadatory = DataServices.getModel('service_madatory');
      this.listDataMadatory = this.list_madatory
      this.dataModelMadatory.processData(this.list_madatory)
      this.componentKeyMadatory++;

      this.dataModelProduct = DataServices.getModel('full_fill_product');
      console.log('this.dataModelProduct:', this.dataModelProduct)
      this.componentKeyProduct++;
      this.componentKey++;
      this.componentKeyService++;
      this.dropdown_action++;
    }
  },
  methods: {
    async changePropertyType() {
      this.dropdown_action++;
    },
    DataOnChangeMadatory(data, f) {
      console.log("f.keyf.key:", f.key)
      console.log("datadatadatadata:", data)
      var index = data.index;
      this.listDataMadatory[index] = data;
      /*if(f.key == 'active'){

      }*/
    },
    async deleteData() {
      this.showDeleteDialog = false;
      var list_data = []
      for (let i = 0; i < this.listDataProduct.length; i++) {
        var item_data = this.listDataProduct[i];
        if (this.deleteId != item_data.id) {
          list_data.push(item_data);
        }
      }
      this.listDataProduct = list_data;
      this.loadTableTypeProduct()
    },
    async loadTableTypeProduct() {
      var stt = 0;
      var listDataProduct = this.listDataProduct;
      this.mapServicePriceProduct = {};
      for (let i = 0; i < listDataProduct.length; i++) {
        stt++;
        var itemData = listDataProduct[i];
        var id = itemData?.id ?? null;
        listDataProduct[i].stt = stt;
        listDataProduct[i].index = i;
        this.mapServicePriceProduct[id] = itemData;
      }
      this.listDataProduct = listDataProduct
      // console.log(" this.listDataProduct:", this.listDataProduct)
      this.dataModelProduct = DataServices.getModel('full_fill_product');
      //this.dataModelProduct.processData(listDataProduct)
      this.componentKeyProduct++;
    },
    async save_item_list() {
      if (this.$commonFuction.isEmpty(this.obj_data.type_id)) {
        alert("Please select Service type of property!");
        return false;
      }
      var type_id = this.obj_data.type_id ?? null
      if (this.$commonFuction.isEmpty(this.obj_data.price_select)) {
        alert("Please enter price!");
        return false;
      }

      console.log("this.obj_data.type_id:", this.obj_data.type_id)
      console.log("this.obj_data.price_select:", this.obj_data.price_select)
      console.log("this.obj_data.mapPropertyType:", this.mapPropertyType)
      console.log("type_id:", type_id)
      var itemType = this.mapPropertyType[type_id]
      console.log("itemTypeitemType:", itemType)
      var itemObject = {
        // stt:1,
        id: itemType?.id ?? null,
        type_name: itemType?.name ?? null,
        price_key: this.obj_data.price_select,
      }
      if (!this.$commonFuction.isEmpty(this.index_edit)) {
        for (let i = 0; i < this.listDataProduct.length; i++) {
          var item_data = this.listDataProduct[i];
          var index = item_data.index
          if (type_id == item_data.id && index != this.index_edit) {
            alert("Service type of property already exists!");
            return false;
          }
        }
        this.listDataProduct[this.index_edit] = itemObject;
      } else {
        if (!this.$commonFuction.isEmpty(this.mapServicePriceProduct[type_id])) {
          alert("Service type of property already exists!");
          return false;
        }
        this.listDataProduct.push(itemObject);
      }

      //var listDataProduct = this.listDataProduct;
      this.obj_data.type_id = null;
      this.obj_data.price_select = null;
      this.index_edit = null;
      this.loadTableTypeProduct()
    },
    async onActionClicked(payload) {
      var action = payload.action
      var data = null
      if (payload.data) {
        data = payload.data
      }
      var pageTransaction = (new Date()).getTime();
      console.log("action.nameaction.name:", action.name)
      switch (action.name) {
        case 'view':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            this.$router.push({ path: action.path + data.data[action.key] })
          } else {
            this.editId = data.data[action.key]
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'edit':
          console.log("datadatadata:", data)
          /*this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            console.log("payload.lazyParams:111"+pageTransaction,payload.lazyParams);
            localStorage.setItem(pageTransaction.toString(), JSON.stringify(payload.lazyParams));
            this.$router.push({ path: action.path + data.data[action.key],query: { page_transaction: pageTransaction.toString() }  })
          } else  {
            this.editId = data.data[action.key]
            this.displayDialog = true
            this.editDialogKey++
          }*/
          /*this.index_edit =data.data.index
          this.obj_data.type_id =data.data.id
          this.obj_data.price_select =data.data.price_key
          this.key_type_id++;*/
          //this.obj_data_product = data.data
          this.list_file_product = data.data.list_file_product
          this.listLangProduct = data.data.listLangProduct
          console.log("ffffffffffffffffff", data.data);
          


          var key_not = ['list_file_product'];
          var data_edit =data.data
          var obj_data_product1 ={}
          for (let key in data_edit) {
            // if (data_edit.hasOwnProperty(key)) {
            // if (data_edit.hasOwnProperty(key)) { // Check if the property is directly on the object
            // console.log(key + ': ' + person[key]);
            if ( key_not.indexOf(key) == -1 ){
              obj_data_product1[key] = data_edit[key];
              //  }
            }
          }
          this.obj_data_product = this.$commonFuction.convertJsonObject(obj_data_product1);
          this.is_change_product = 1;
          this.keyProductLoad++;
          break
        case 'delete':
          localStorage.setItem(pageTransaction.toString(), JSON.stringify(payload.lazyParams));
          this.page_transaction = pageTransaction;
          this.deleteId = data.data[action.key]
          console.log("this.deleteId:", this.deleteId)
          this.deleteName = data.data.name
          this.showDeleteDialog = true
          break
        case 'add':
          //var dataLang = await this.$CoreService.getListLang({});
          //var list_lang_product = dataLang.list
          //this.clearSaveProduct();
          this.list_file_product = [];
          this.list_id_file_delete_product = [];
          this.listLangProduct = [];
          var obj_data_product ={
            ordinal:1,
            active:true,
          }
          this.obj_data_product=obj_data_product
          var list_lang_product = this.$commonFuction.convertJsonObject(this.listLangProductTmp);
          console.log("list_lang_productlist_lang_product:", list_lang_product)
          for (let j = 0; j < list_lang_product.length; j++) {
            list_lang_product[j].content = '';
          }
          this.listLangProduct = list_lang_product
          console.log("  this.listLangProduct:", this.listLangProduct)
          this.is_change_product = 1;
          this.price_product++;
          this.keyProductLoad++;
          /*this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            this.$router.push({ path: action.path })
          } else  {
            this.displayDialog = true
            this.editDialogKey++
          }*/
          break
        case 'custom':
          await action.handler(data.data, this)
          this.componentKey++
          break
      }
    },
    getRowActions() {
      var actions = {}
      var that = this;
      //if (this.role.add) {
      actions.header = { name: 'add', icon: 'plus', path: `list-${this.modelName}/add` };
      //}
      // actions.header = { name: 'add', icon: 'plus'};
      actions.rows = function (rowData) {
        var buttons = {}
        //buttons.view = { name: 'view', icon: 'eye', key: 'id', path: `list-${that.modelName}/view/`};
        //if (that.role.edit) {
        buttons.edit = { name: 'edit', icon: 'pencil', key: 'id', path: `list-${that.modelName}/edit/` };
        //}
        // buttons.edit = { name: 'edit', icon: 'pencil', key: 'id', path: `${that.modelName}/edit/`}
        //if (that.role.delete) {
        buttons.delete = { name: 'delete', icon: 'trash', key: 'id' }
        //}
        if (that.dataModelProduct.actions) {
          var modelaction = that.dataModelProduct.actions(rowData)
          Object.assign(buttons, modelaction);
        }
        return buttons
      }
      return actions
    },
    handleDataCkditor: function (e, index, key) {
      var dataContent = (e.length) ? e[0] : '';
      if (key == 'content') {
        //this.content = dataContent;
        this.listLang[index].content = dataContent;
      }
    }
    ,
    handleDataCkditorProduct: function (e, index, key) {
      var dataContent = (e.length) ? e[0] : '';
      if (key == 'description') {
        //this.content = dataContent;
        console.log("listLangProductlistLangProductlistLangProduct",this.listLangProduct);
        
        this.listLangProduct[index].content = dataContent;
      }
    },
    async onFileSelectFile(event) {
      var that = this;
      let files = event.target.files || event.dataTransfer.files;
      console.log("filesfiles:", files)
      console.log("files.length:", files.length)
      if (files.length > 0) {
        let acceptTags = ['jpeg', 'jpg', 'gif', 'png', 'JPEG', 'JPG', 'PNG', 'GIF', 'svg', 'SVG']
        console.log("files.length1111111:", files.length)
        for (let i = 0; i < files.length; i++) {
          console.log("files.333333333333:", files.length)
          if (!acceptTags.includes(files[i].name.split('.').pop())) {
            alert("Only post formats are supported: jpeg, jpg, gif, png, svg");
            return;
          }
          //  document.getElementById('file_name').innerText = files[i].name;
          console.log("files[i].namefiles[i].name:", files[i].name)
          that.obj_data.filename = files[i].name;
          this.comFilename++;
        }
        var arrTemp = [], arrFiles = [];
        for (let file of files) {
          console.log('file.sizefile.sizefile.size:', file.size)
          if (file.size <= this.maxFileSize) {
            file.source = file;
            file.objectURL = window.URL.createObjectURL(file);
            console.log("asdasdasfile:", file)
            arrTemp.push(file);
          }
          if (file.size > this.maxFileSize) arrFiles.push(file);
        }
        if (arrFiles.length > 0) {
          alert("Your image is larger 100MB");
          that.obj_data.filename = "";
          return;
        } else {
          // if (this.icon_service.length > 0 && this.icon_service[0].file === '') {
          //   this.imagesDeletes.push(this.icon_service[0])
          // }
          console.log("arrTemparrTemp:", arrTemp)
          //this.list_file = [];
          this.list_file = this.list_file.concat.apply(this.list_file, arrTemp);
        }
      }
    },
    async onFileSelectFileProduct(event) {
      var that = this;
      let files = event.target.files || event.dataTransfer.files;
      console.log("filesfiles:", files)
      console.log("files.length:", files.length)
      if (files.length > 0) {
        let acceptTags = ['jpeg', 'jpg', 'gif', 'png', 'JPEG', 'JPG', 'PNG', 'GIF', 'svg', 'SVG']
        console.log("files.length1111111:", files.length)
        for (let i = 0; i < files.length; i++) {
          console.log("files.333333333333:", files.length)
          if (!acceptTags.includes(files[i].name.split('.').pop())) {
            alert("Only post formats are supported: jpeg, jpg, gif, png, svg");
            return;
          }
          //  document.getElementById('file_name').innerText = files[i].name;
          console.log("files[i].namefiles[i].name:", files[i].name)
          that.obj_data.filename = files[i].name;
          this.comFilename++;
        }
        var arrTemp = [], arrFiles = [];
        for (let file of files) {
          console.log('file.sizefile.sizefile.size:', file.size)
          if (file.size <= this.maxFileSize) {
            file.source = file;
            file.objectURL = window.URL.createObjectURL(file);
            console.log("asdasdasfile:", file)
            arrTemp.push(file);
          }
          if (file.size > this.maxFileSize) arrFiles.push(file);
        }
        if (arrFiles.length > 0) {
          alert("Your image is larger 100MB");
          that.obj_data.filename = "";
          return;
        } else {
          // if (this.icon_service.length > 0 && this.icon_service[0].file === '') {
          //   this.imagesDeletes.push(this.icon_service[0])
          // }
          console.log("arrTemparrTemp:", arrTemp)
          //this.list_file_product = [];
          this.list_file_product = this.list_file_product.concat.apply(this.list_file_product, arrTemp);
        }
      }
    },
    isImage(file) {
      return /^image\//.test(file.type);
    },
    updateMatrix(data) {
      console.log(data);
      this.dataBookingRound = [...data];
      // this.componentKey++;
    },
    async toCalendar() {
      this.$router.push(`/admin/list-booking?view=calendar&propery_id=${this.selProperty?.id}`);
    },
    async getListPropertyType() {
      var where_key = {
        deleted: { _eq: false },
        // booking: {id: {_is_null: true} },
      }
      var list = await this.$CoreService.getListPropertyType(where_key);
      this.listPropertyType = list.list;
      this.listPropertyTypeAll = list.list;
      this.mapPropertyType = list.map_data;
    },
    async SearchPropertyType(search, loading) {
      console.log(loading);
      if (search !== '' && search !== null) {
        var where_key = {
          deleted: { _eq: false },
        };
        if (!this.$commonFuction.isEmpty(search)) {
          where_key.name = {
            _iregex: this.$commonFuction.search(search),
          };
        }
        var list = await this.$CoreService.getListPropertyType(where_key);
        this.listPropertyType = list.list;
        //console.log("this.listProperty:",this.listProperty)
      } else {
        // cosnole.log("dasdasd")
        this.listPropertyType = this.listPropertyTypeAll
      }
    },
    async getListCountry() {
      var where_key = {
        deleted: { _eq: false },
        // booking: {id: {_is_null: true} },
      }
      var list = await this.$CoreService.getListCountry(where_key);
      this.listCountry = list.list;
      this.listCountryAll = list.list;
    },
    async SearchCountry(search, loading) {
      console.log(loading);
      if (search !== '' && search !== null) {
        var where_key = {
          deleted: { _eq: false },
        };
        if (!this.$commonFuction.isEmpty(search)) {
          where_key.name = {
            _iregex: this.$commonFuction.search(search),
          };
        }
        var list = await this.$CoreService.getListCountry(where_key);
        this.listCountry = list.list;
        //console.log("this.listProperty:",this.listProperty)
      } else {
        // cosnole.log("dasdasd")
        this.listCountry = this.listCountryAll
      }
    },
    async getListState() {
      var where_key = {
        deleted: { _eq: false },
        // booking: {id: {_is_null: true} },
      }
      var list = await this.$CoreService.getListState(where_key);
      this.listState = list.list;
      this.listStateAll = list.list;
    },
    async SearchState(search, loading) {
      console.log(loading);
      if (search !== '' && search !== null) {
        var where_key = {
          deleted: { _eq: false },
        };
        if (!this.$commonFuction.isEmpty(search)) {
          where_key.name = {
            _iregex: this.$commonFuction.search(search),
          };
        }
        var list = await this.$CoreService.getListState(where_key);
        this.listState = list.list;
        //console.log("this.listProperty:",this.listProperty)
      } else {
        // cosnole.log("dasdasd")
        this.listState = this.listStateAll
      }
    },
    async getListUser() {
      var where_key = {
        deleted: { _eq: false },
        role: { _eq: this.$constants.ROLE_ACCOUNT.OWNER },
        // booking: {id: {_is_null: true} },
      }
      var list = await this.$CoreService.getListUser(where_key);
      this.listUser = list.list;
      this.listUserAll = list.list;
    },
    async SearchUser(search, loading) {
      console.log(loading);
      if (search !== '' && search !== null) {
        var where_key = {
          deleted: { _eq: false },
        };
        if (!this.$commonFuction.isEmpty(search)) {
          where_key.fullname = {
            _iregex: this.$commonFuction.search(search),
          };
        }
        var list = await this.$CoreService.getListUser(where_key);
        this.listUser = list.list;
        //console.log("this.listProperty:",this.listProperty)
      } else {
        // cosnole.log("dasdasd")
        this.listUser = this.listUserAll
      }
    },

    async getListDocsCate() {
      var where_key = {
        deleted: { _eq: false },
        // booking: {id: {_is_null: true} },
      }
      var list = await this.$CoreService.getListDocsCate(where_key);
      this.listDocsCate = list.list;
      this.listDocsCateAll = list.list;
    },

    async SearchDocsCate(search, loading) {
      console.log(loading);
      if (search !== '' && search !== null) {
        var where_key = {
          deleted: { _eq: false },
        };
        if (!this.$commonFuction.isEmpty(search)) {
          where_key.name = {
            _iregex: this.$commonFuction.search(search),
          };
        }
        var list = await this.$CoreService.getListDocsCate(where_key);
        this.listDocsCate = list.list;
        //console.log("this.listProperty:",this.listProperty)
      } else {
        // cosnole.log("dasdasd")
        this.listDocsCate = this.listDocsCateAll
      }
    },

    backToList() {
      this.$emit('back');
    },
    backToListProduct() {
      this.$commonFuction.is_loading(true);
      var that = this;
      setTimeout(function () {
        //Gọi API refeshtoken
        that.$commonFuction.is_loading(false);
        that.is_change_product = 0;
      }, 1000);
    },
    async validateData() {
      this.validationErrors = {};

      let type_id_add = document.getElementById("type_id");
      if (this.$commonFuction.isEmptyObject(this.obj_data.type_id)) {
        type_id_add.setCustomValidity(this.$constants.MSG_TEXT.SELECT_INVALID_MSG);
      } else {
        type_id_add.setCustomValidity("");
      }

      // let name_add = document.getElementById("name");
      // if (this.$commonFuction.isEmpty(this.obj_data.name)) {
      //   name_add.setCustomValidity(this.$constants.MSG_TEXT.INPUT_INVALID_MSG);
      // } else {
      //   name_add.setCustomValidity("");
      // }

      let before_arrival_date = document.getElementById("before_arrival_date");
      if (!this.$commonFuction.isEmpty(this.obj_data.before_arrival_date)) {
        if (parseFloat(this.obj_data.before_arrival_date) < 0){
          before_arrival_date.setCustomValidity(this.$constants.MSG_TEXT.INT_MIN_VALIDATE);
        }else {
          before_arrival_date.setCustomValidity("");
        }
      }else {
        before_arrival_date.setCustomValidity("");
      }

      let available_from_add = document.getElementById("available_from");
      var val_from = "";
      if (this.$commonFuction.isEmpty(this.obj_data.available_from)) {
        //available_from_add.setCustomValidity(this.$constants.MSG_TEXT.SELECT_INVALID_MSG);
        val_from = this.$constants.MSG_TEXT.SELECT_INVALID_MSG;
      }
      if (!this.$commonFuction.isEmpty(this.obj_data.available_from) && !this.$commonFuction.isEmpty(this.obj_data.available_to)) {
        var from_check = moment(this.obj_data.available_from).format("YYYYMMDD");
        var to_check = moment(this.obj_data.available_to).format("YYYYMMDD");
        console.log("from_check:",from_check)
        console.log("to_check:",to_check)
        if (parseFloat(from_check) >  parseFloat(to_check) ){
          val_from = "Please re-select the Available for use from - to time";
        }

      }
      if (!this.$commonFuction.isEmpty(val_from)) {
        available_from_add.setCustomValidity(val_from);
      }else {
        available_from_add.setCustomValidity("");
      }

      /*let available_to_add = document.getElementById("available_to");
      if (this.$commonFuction.isEmpty(this.obj_data.available_to)){
        available_to_add.setCustomValidity(this.$constants.MSG_TEXT.SELECT_INVALID_MSG);
      }else {
        available_to_add.setCustomValidity("");
      }*/

      /*let name_add = document.getElementById("name");
      if (this.$commonFuction.isEmpty(this.obj_data.name)) {
        name_add.setCustomValidity(this.$constants.MSG_TEXT.INPUT_INVALID_MSG);
      } else {
        name_add.setCustomValidity("");
      }*/
      var list_lang = this.listLang
      for (let i = 0; i < list_lang.length; i++) {
        var itemData = list_lang[i];
        if (itemData.iso_code == 'dk') {
          let service_name = document.getElementById("name_" + itemData.iso_code);
          if (this.$commonFuction.isEmpty(itemData.service_name)) {
            service_name.setCustomValidity(this.$constants.MSG_TEXT.INPUT_INVALID_MSG);
          } else {
            service_name.setCustomValidity("");
          }
          var iso_err = itemData.iso_code
          let content = document.getElementById("validator_content_" + iso_err);
          if (this.$commonFuction.isEmpty(itemData.content)) {
            content.setCustomValidity(this.$constants.MSG_TEXT.INPUT_INVALID_MSG);
          } else {
            content.setCustomValidity("");
          }

        }

      }

      /*if (this.obj_data.type_of_price == '3'){

        let call_to_action_price = document.getElementById("call_to_action_price");
        if (this.$commonFuction.isEmpty(this.obj_data.call_to_action_price)){
          call_to_action_price.setCustomValidity(this.$constants.MSG_TEXT.INPUT_INVALID_MSG);
        }else {
          call_to_action_price.setCustomValidity("");
        }
      }
*/

      let list_file = document.getElementById("list_file");
      if (this.list_file == 0) {
        list_file.setCustomValidity(this.$constants.MSG_TEXT.FILE_INVALID_MSG);
      } else {
        list_file.setCustomValidity("");
      }

      var inpObj = document.getElementById("frm_action");
      if (!inpObj.checkValidity()) {
        console.log('test=====================');
        inpObj.reportValidity();
        return true;
      }
    },
    async validateDataProduct() {

      if (this.$commonFuction.isEmpty(this.obj_data_product.price)) {
        alert('Please enter price');
        return true;
      }else {
        if (parseFloat(this.obj_data_product.price) < 0){
          alert('Please enter price that cannot be negative');
          return true;
        }
      }
      if (this.list_file_product == 0) {
        alert('Please upload at least one photo');
        return true;
      }

      var list_lang = this.listLangProduct
      for (let i = 0; i < list_lang.length; i++) {
        var itemData = list_lang[i];
        
        
        if (itemData.iso_code == 'dk') {
          console.log("itemDataitemDataitemDataitemDataitemData", itemData);
          document.getElementById("name_product_" + itemData.iso_code);
          if (this.$commonFuction.isEmpty(itemData.name_product)) {
            alert('Please enter product name');
            return true;
          } 
          var iso_err = itemData.iso_code
          document.getElementById("validator_content_product_" + iso_err);
          if (this.$commonFuction.isEmpty(itemData.content)) {
            alert('Please enter description');
            return true;
          } 

        }
        
      }
    },
    async checkCode(code) {
      // if(this.email !== null && this.email !== ''){
      var where = {
        code: { '_eq': code },
        deleted: { '_eq': false },
        // type: {'_eq' : 'user'}
      }
      if (this.model !== undefined) where = Object.assign(where, { id: { '_neq': this.model.id } })
      var { data } = await this.$apollo.query({
        query: DataServices.getList('property', { "fields": "id, name,code" }),
        variables: {
          where_key: where
        },
        fetchPolicy: "network-only"
      })
      // console.log(data)
      return data && data[Object.keys(data)].length > 0
      //}
    },
    async saveData() {
      this.$commonFuction.is_loading(true);
      if (await this.validateData()) {
        this.$commonFuction.is_loading(false);
        return
      }
      if (this.listDataProduct.length==0){
        alert('Please add at least one product')
        this.$commonFuction.is_loading(false);
        return
      }


      //console.log("this.from_to", this.from_to);
      //console.log("partArr", this.dataBookingRound);
      var params = this.obj_data
      params["list_file"] = this.list_file
      params["list_product"] = this.listDataProduct
      params["list_madatory"] = this.listDataMadatory
      params["list_lang"] = this.listLang
      params["list_id_file_delete"] = this.list_id_file_delete
      console.log('saveDatasaveDatasaveDatasaveData', params)
      this.$emit('save', params)
    },
    async saveDataProduct() {
      var that = this;

      if (await this.validateDataProduct()) {
        return
      }
      this.$commonFuction.is_loading(true);
      //console.log("this.from_to", this.from_to);
      //console.log("partArr", this.dataBookingRound);
      var params = this.obj_data_product
      console.log("paramsparams:", params)
      var id = params?.id ?? null;


      var data_name = '';
      var description = '';
      var list_lang = this.listLangProduct ?? [];
      list_lang.forEach((lang) => {
        if (lang.id_lang === 1) {
          data_name = lang.name_product;
          description = lang.content;
        }
      });

      list_lang.forEach((lang) => {
        if (!params.is_use_en && lang.id_lang === 2) {
          lang.name_product = data_name;
          lang.content = description;
        }
      });

      // push list_lang vao listLangProduct
      this.listLangProduct = list_lang;
      var itemObject = {
        // stt:1,
        list_id_file_delete_product: this.list_id_file_delete_product,
        avatar: this.list_file_product[0].objectURL,
        list_file_product: this.list_file_product,
        name: this.listLangProduct[0].name_product ?? null,
        description: this.listLangProduct[0].content,
        listLangProduct: this.listLangProduct,
        price: params?.price ?? null,
        ordinal: params?.ordinal ?? null,
        active: params?.active ?? null,
        is_use_en: params?.is_use_en ?? null,
      }

      if (!this.$commonFuction.isEmpty(id)) {
        itemObject["id"] = id;
        this.listDataProduct[params?.index] = itemObject;
      } else {
        itemObject["id"] = moment().valueOf() + "";
        itemObject["is_new"] = 1;//sp này do thêm mới chứ ko từ edit dat trong db
        this.listDataProduct.push(itemObject)
      }

      this.clearSaveProduct();

      this.loadTableTypeProduct()
      setTimeout(function () {
        //Gọi API refeshtoken
        that.$commonFuction.is_loading(false);
        that.is_change_product = 0;
      }, 1000);

      /*params["list_file"] = this.list_file
      params["list_type"] = this.listDataProduct
      params["list_madatory"] = this.listDataMadatory
      params["list_lang"] = this.listLang
      params["list_id_file_delete"] = this.list_id_file_delete
      console.log('saveData', params)
      this.$emit('save', params)*/
    },
    clearSaveProduct() {
      this.obj_data_product.id = null;
      this.obj_data_product.name = '';
      this.obj_data_product.price = null;
      this.list_file_product = [];
      this.list_id_file_delete_product = [];
      this.listLangProduct = [];
      this.obj_data_product.ordinal = 1;
      this.obj_data_product.active = true;
      this.obj_data_product.is_use_en = false;
    },
    onFileClear(item, index) {
      console.log('itemitem:', item)
      var type = item?.type ?? null
      if (type == 'old_edit') {
        this.list_id_file_delete.push(item.id);
      }
      this.list_file.splice(index, 1);
    },
    onFileClearProduct(item, index) {
      console.log('itemitem:', item)
      var type = item?.type ?? null
      if (type == 'old_edit') {
        this.list_id_file_delete_product.push(item.id);
      }
      this.list_file_product.splice(index, 1);
    },
  },
}
</script>

<style lang="scss">
.p-multiselect {
  width: 450px;
}

.p-multiselect-label:not(.p-placeholder) {
  padding-top: .25rem;
  padding-bottom: .25rem;

}

.category-item-value {
  padding: .25rem .5rem;
  border-radius: 3px;
  display: inline-flex;
  margin-right: .2rem;
  background-color: var(--primary-color);
  color: var(--primary-color-text);
}

.p-multiselect-label-container {
  height: 40px;
}

.p-inputtext {
  margin: 0px 0px 0px 4px;
}

//style form add-edit
@media screen and (min-width: 1001px) {
  .input_distance {
    width: 728px !important;
  }
}

label.p-col-fixed txt-right {
  margin-bottom: 0px !important;
}

div.p-card-field {
  padding: 0px !important;
}

.p-col-fixed {
  padding: 0px !important;
  margin: 0px 0px 0px 9px !important;
}

.input_text {
  width: 760px !important;
  height: 48px !important;
}

.txt-right {
  font-weight: bold;
}

.multiselect {
  width: 760px !important;
}

.layout-main {
  background: #00000029;
}

.vs__clear {
  display: none;
}

.input_width {
  width: 760px !important;
  height: 48px !important;
}

#distance {
  width: 1px !important;
  height: 48px !important;
  border-left: 0px;
  border-top: 0px;
  border: 1px solid #C8C8C8;
  border-radius: 0px;
  //border-top: none!important;
  //border: 1px solid rgba(60, 60, 60, 0.26);;
}

.manager-title {
  font-size: 24px !important;
}

.input-dif {
  margin-left: 5px !important;
  border-top: 0px;
}

.p-inputgroup-addon {
  height: 48px;
}

.txt-right {
  font-weight: bold;
}

.p-col-fixed {
  padding: 0px !important;
  //margin:0px 0px 0px 10px !important;
}

label.p-col-fixed txt-right {
  margin-bottom: 0px !important;
}

div.p-card-field {
  padding: 0px !important;
}

.margin {
  margin-bottom: 12px !important;
}

.border_color {
  border: 1px solid #C8C8C8;
}

.text {
  font-weight: normal !important;
  margin-left: 15px !important;
}

.div_check {
  margin-top: 12px !important;
}


//responsive
@media screen and (max-width: 600px) {
  .input_width {
    width: 100% !important;
  }

  .div-button {
    padding: 8px !important;
  }

  #distance {
    text-align: left !important;
    padding: 6.8px;
    width: 100% !important;
  }

  .p-inputgroup-addon {
    margin-right: 12px;
  }

}

@media screen and (max-width: 1000px) {
  .input_width {
    width: 100% !important;
  }

  .div-button {
    padding: 8px !important;
  }

  .p-inputgroup-addon {
    margin-right: 12px;
  }

  #distance {
    text-align: left !important;
    padding: 6.8px;
  }
}

.p-button-outlined {
  .p-button-label {
    color: #007ad9 !important;
  }
}

.cus-text {
  margin-left: 2px;
  margin-top: 4px;
}

.style-box-phone {
  border: 1px solid #a6a6a6;
  width: 100%;
  display: flex;

  .p-dropdown {
    width: 100px;
    border: 0px;
    box-shadow: none !important;
  }

  .p-focus {
    border: 0px;
    box-shadow: none;
  }

  .p-dropdown:focus {
    border: 0px;
    box-shadow: none;
  }

  input {
    width: calc(100% - 100px);
    margin-left: 0px;
    border: 0px;
  }

  .p-inputtext:enabled:focus {
    border: 0px;
    box-shadow: none;
  }
}

.style_list_image {
  display: flex;
  flex-wrap: wrap;
  /* Cho phép các phần tử xuống dòng */
  gap: 10px;

  /* Khoảng cách giữa các phần tử */
  .box-image {
    width: 100px;
    height: 100px;
    margin-top: 8px;
    margin-bottom: 8px;
    position: relative;

    .style_img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border: 1px solid #D0D4D9;
      border-radius: 10px;
    }

    .button-clear {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 1px;
      right: 8px;
    }
  }
}

.style_box_room {
  display: flex;
}

.style_box_lat_log {
  display: flex;

  .box_lat_log {
    width: 50%;
  }
}

.style_title_group {
  font: normal normal bold 20px/27px Segoe UI;
  letter-spacing: 0px;
  color: #666666;
  text-transform: uppercase;
}

.style_box_price {
  padding-left: 35px !important;
}

.style_rad_button {
  label {
    margin-bottom: 0px;
    margin-left: 15px;
  }
}

.style_btn_add_type {
  width: 60px;
}
</style>
