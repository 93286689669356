import BaseModel from '../core/BaseModel'
//import GroupModel from "@/models/GroupModel";
//import gql from 'graphql-tag'
export default class AdminModel extends BaseModel {

    constructor() {
        super({ 
                name: "view_admin",
                tableName: 'view_admin',
                label: "Admin user",
                display_key: 'fullname', 
                default_order: { key: 'updated_at', direction: 'asc' } }, 
            {
            stt: {
                name: "stt",
                label: "No.",
                type: "Computed",
                computed: (data, index) => {
                    data.stt = index + 1;
                },
                // display: { list: true, add: false, edit: false }
            },
            id: { 
                name: 'id', 
                type: "Uuid", 
                label: 'ID', 
                primaryKey: true,
                display: false
            },
                fullname: {
                    name: 'fullname',
                    type: "String",
                    label: 'Fullname',
                    width: 15,
                    filter: {type: 'input', match: 'contains'},
                    // display: false
                },
                account: {
                    name: 'account',
                    type: "String",
                    label: 'Username',
                    sortable: false,
                    width: 10,
                    filter: {type: 'input', match: 'contains'},
                },
                total_property_manage: {
                    name: 'total_property_manage',
                    type: "String",
                    label: 'Property No.',
                    sortable: false,
                    //width: 10,
                    filter: {type: 'input', match: 'contains'},
                },
                updated_by_key: {
                    name: "updated_by_key",
                    label: "Updated by",
                    type: "Computed",
                    filter: {type: 'input', match: 'contains'},
                    computed: (data) => {
                        data.updated_by_key ='' ;
                    },
                },
                updated_at : {
                    name: 'updated_at',
                    type: "Datetime",
                    label: 'Updated date',
                    filter: { type: 'DateRange', match: 'contains'}
                },
                detail_get: {
                    refs:  ' obj_updated_by { id fullname } arr_role_admin_property{uid property_id obj_property{ id name } }   ',
                    display: false
                },
        },)
    }

}
