<template>
  <form id="frm_action">
    <div class="p-formgrid">
      <Toolbar class="fixed-bottom-toolbar div-button css-button-top-right">
        <template slot="right">
          <Button label="Back" @click="backToList()" id="back" class="main-style-button" />
        </template>
      </Toolbar>
      <div class="col-6 p-0">
        <div class="p-card-field">
          <label class="p-col-fixed txt-right txt-required">Property ID</label>
          <div class="p-col">
            <v-select :disabled="mode == 'edit'" :clearable="false" title="Property" class="" @search="SearchProperty"
              placeholder="Choose" v-model="selProperty" :options="listProperty" label="name">
              <span slot="no-options" @click="$refs.select.open = false">
                Empty data
              </span>
            </v-select>
            <input class="input_tmp_validator" id="property">
          </div>
        </div>

        <div class="p-card-field" v-if="selProperty">
          <label class="p-col-fixed txt-right">Property</label>
          <div class="p-col">
            <InputText type="text" class="" v-model="selProperty.name" placeholder="Input" :disabled="true" />
          </div>
        </div>



        <div class="p-card-field">
          <label class="p-col-fixed txt-right">Time</label>
          <div class="p-col">
            <Calendar :manualInput="false" id="time24" v-model="time" dateFormat="M dd, yy" :showIcon="true"
              :showTime="true" placeholder="Input" />
          </div>
        </div>
        <div class="p-card-field">
          <label class="p-col-fixed txt-right txt-required">Title (DK)</label>
          <div class="p-col">
            <InputText maxlength="100" type="text" class="" v-model="title" placeholder="Input" />
            <input class="input_tmp_validator" id="title">
          </div>
        </div>
      </div>

      <div class="col-12 p-0">
        <div class="p-card-field">
          <label class="p-col-fixed txt-right txt-required">Description (DK)</label>
          <div class="p-col">
            <CkditorCore :key="`en-${componentKey}`" :contentSet.sync="this.description_dn"
              @newdata="handleDataCkditor($event,'description_dn')"></CkditorCore>
            <input class="input_tmp_validator" id="description_dn">
          </div>
        </div>
      </div>
      <div class="col-6 p-0" >
          <div class="p-card-field div_check">
            <div class="p-col checkbox">
              <Checkbox v-model="is_use_en" :binary="true" />
              <label class="p-col-fixed txt-right text">Use English</label>
            </div>
          </div>
        </div>
      <div class="col-12 p-0">
        <div class="col-6 p-0">
          <div class="p-card-field">
            <label class="p-col-fixed txt-right txt-required">Title (EN)</label>
            <div class="p-col">
              <InputText :disabled="!is_use_en" maxlength="100" type="text" class="" v-model="title_en" placeholder="Input" />
              <input class="input_tmp_validator" id="title_en">
            </div>
          </div>
        </div>
      
        <div class="p-card-field">
          <label class="p-col-fixed txt-right txt-required">Description (EN)</label>
          <div class="p-col">
            <CkditorCore :disabledCK="!is_use_en" :key="`dn-${componentKey}`" :contentSet.sync="this.description_en"
              @newdata="handleDataCkditor($event, 'description_en')"></CkditorCore>
            <input class="input_tmp_validator" id="description_en">
          </div>
        </div>
      </div>
      <Toolbar class="fixed-bottom-toolbar div-button">
        <template slot="right">
          <Button label="Delete" v-if="mode == 'edit'" @click="showDelete = true" class="p-button-secondary" />
          <Button :label="$constants.TEXT_BUTTON.BACK" @click="backToList()" class="p-button-outlined" id="back" />
          <Button v-if="mode == 'edit'" :label="$constants.TEXT_BUTTON.EDIT" @click="saveData()"
            class="main-style-button" />
          <Button v-else :label="$constants.TEXT_BUTTON.ADD" @click="saveData()" class="main-style-button" />
        </template>
      </Toolbar>
      <Dialog header="Delete" :visible.sync="showDelete" :contentStyle="{ overflow: 'visible' }" :modal="true">
        Are you want to delete this appointment ?
        <template #footer>

          <Button label="Cancel" @click="showDelete = false" icon="pi pi-times" class="p-button-warning" />
          <Button label="Confirm" @click="deleteData()" icon="pi pi-check"
            class="p-button-danger  btn-confirm-delete-dialog" />
        </template>
      </Dialog>
    </div>
  </form>
</template>
<script>
import DataServices from '@/core/DataServices';
import CkditorCore from '@/views/admin/CkditorCore';
import moment from "moment-timezone";
export default {
  components: {
    CkditorCore
  },
  props: {
    mode: String,
    model: Object,
    users: Array
  },
  data() {
    return {
      selProperty: null,
      listProperty: [],
      listPropertyAll: [],
      mapProperty: {},

      title: null,
      title_en: null,
      description_dn: "",
      description_en: "",
      time: null,
      time_zone_local : moment.tz.guess(true),
      date: null,
      showDelete: false,
      componentKey: 0,
      is_use_en: false
    }
  },
  watch: {
  },
  async mounted() {
    await this.getListProperty();
    if (this.$route?.query && this.$route.query?.date) {
      this.date = this.$route.query?.date;
      this.time = new Date(moment(this.$route.query?.date).format("YYYY-MM-DD HH:mm:ss"));
    }
    if (this.$route?.query && this.$route.query?.property_id) {
      console.log("this.$route.query",this.$route.query.property_id);
      this.selProperty = this.listProperty.find(e => e.id == this.$route.query.property_id);
    }
    if (this.model !== undefined && Object.keys(this.model).length > 0) {
      console.log("this.model",this.model)
      this.selProperty = {id : this.model.property.id, name : this.model.property.name, code: this.model.property.code, address: this.model.property.address, part: this.model.property.part};
      this.title = this.model.title;
      this.title_en = this.model.title_en;
      this.description_dn = this.model.description_dn;
      this.description_en = this.model.description_en;
      this.is_use_en = this.model.is_use_en;
      this.date = new Date(moment(this.model.date).utc().format("YYYY-MM-DD HH:mm:ss"));
      this.time = new Date(moment(this.model.date).utc().format("YYYY-MM-DD HH:mm:ss"));
      this.componentKey++;
    }
  },
  methods: {
    async deleteData() {
      let variables = {
        'data_update': { deleted: true },
        'where_key': {
          'id': { '_eq': this.model.id }
        }
      }
      await this.$apollo.mutate({
        mutation: DataServices.updateData('appoinment'),
        variables: variables
      }).then(() => {
        this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Delete data successfully', life: 3000 })
        this.showDelete = false;
        this.backToList();
      });
    },
    handleDataCkditor: function(e,key) {
      console.log('jhsakdjhasjk', e);
      var dataContent = (e.length) ? e[0]:'';
      this.$set(this, key, dataContent);
    },
    async getListProperty(){
      var where_key = {
        deleted : {_eq : false},
      }
      let {data} = await this.$apollo.query({
        query: DataServices.getList('property',{"fields" : `id text_group_property code name address booking{id} part {id part order_booking user {id fullname email}}`}),
        variables: {
          where_key: where_key,
          orderBy:[{created_at : 'desc'}]
        },
        fetchPolicy: "network-only"
      })
      var list = data[Object.keys(data)];
      var list_property = [];
      // console.log("list===",list);
      var mapProperty = {};
      for (let i = 0; i < list.length; i++) {
        const e = list[i];
          list_property.push({id : e.id, name :e.text_group_property, code: e.code, address: e.address, part: e.part});
      mapProperty[e.id] = e;
      }
      this.mapProperty = mapProperty;
      this.listProperty = list_property;
      this.listPropertyAll = list_property;
    },
    
    async SearchProperty(search, loading){
      console.log(loading);
      if(search !== '' && search !== null){
        var where_key = {
          deleted : {_eq : false},
           _or: {
            text_group_property: {_iregex: this.$commonFuction.search(search)},
          }
        };
        let {data} = await this.$apollo.query({
          query: DataServices.getList('property',{"fields" : `id code text_group_property name address booking {id} part {id part order_booking user {id fullname email}}`}),
          variables: {
            where_key: where_key,
            orderBy:[{created_at : 'desc'}]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_property = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
            list_property.push({id : e.id, name :e.text_group_property, code: e.code, address: e.address, part: e.part});
        }
        this.listProperty = list_property;
      }else{
        this.listProperty = this.listPropertyAll
      }
    },
    backToList() {
      this.$emit('back');
    },
    async validateData() {
      this.validationErrors = {};
      let property_add = document.getElementById("property");
      if (this.$commonFuction.isEmptyObject(this.selProperty)){
        property_add.setCustomValidity("Please choose property!");
      }else {
        property_add.setCustomValidity("");
      }
      let title_add = document.getElementById("title");
      if (this.$commonFuction.isEmpty(this.title)){
        title_add.setCustomValidity("Please input title!");
      }else {
        title_add.setCustomValidity("");
      }
      


      let description_dn_add = document.getElementById("description_dn");
      if (this.$commonFuction.isEmpty(this.description_dn)){
        description_dn_add.setCustomValidity("Please input description (DN)!");
      }else {
        description_dn_add.setCustomValidity("");
      }


      var inpObj = document.getElementById("frm_action");
      if (!inpObj.checkValidity()){
        console.log('test=====================');
        inpObj.reportValidity();
        return true;
      }
    },

    async saveData() {
      this.$commonFuction.is_loading(true);
      if (await this.validateData()) {
        this.$commonFuction.is_loading(false);
        return
      }
      console.log("this", this);
      var property_name = this.mapProperty[this.selProperty.id].name ?? '';
      var params = {
        property_name: property_name,
        property_id: this.selProperty?.id,
        date: this.time ? moment(this.time) : moment(this.date).format("YYYY-MM-DD"),
        description_dn: this.description_dn,
        description_en: this.description_en,
        is_use_en: this.is_use_en,
        title: this.title,
        title_en: this.title_en,
      }
      console.log('saveData', params)
      this.$emit('save', params)
    },
  },
}
</script>

<style lang="scss">
.p-multiselect {
  width: 450px;
}
.p-multiselect-label:not(.p-placeholder) {
  padding-top: .25rem;
  padding-bottom: .25rem;

}
.category-item-value {
  padding: .25rem .5rem;
  border-radius: 3px;
  display: inline-flex;
  margin-right: .2rem;
  background-color: var(--primary-color);
  color: var(--primary-color-text);
}
.p-multiselect-label-container{
  height: 40px;
}
.p-inputtext{
  margin:  0px 0px 0px 4px;
}

//style form add-edit
@media screen and (min-width: 1001px) {
  .input_distance {
    width: 728px !important;
  }
}
label.p-col-fixed txt-right{
  margin-bottom: 0px!important;
}
div.p-card-field{
  padding: 0px!important;
}
.p-col-fixed {
  padding: 0px !important;
  margin:0px 0px 0px 9px !important;
}
.input_text{
  width: 760px!important;
  height: 48px!important;
}
.txt-right{
  font-weight: bold;
}

.multiselect{
  width: 760px!important;
}
.layout-main{
  background: #00000029;
}

.vs__clear{
  display: none;
}
.input_width{
  width: 760px!important;
  height: 48px!important;
}
#distance{
  width: 1px!important;
  height: 48px!important;
  border-left: 0px;
  border-top: 0px;
  border: 1px solid #C8C8C8;
  border-radius: 0px;
  //border-top: none!important;
  //border: 1px solid rgba(60, 60, 60, 0.26);;
}
.manager-title{
  font-size: 24px!important;
}
.input-dif{
  margin-left: 5px!important;
  border-top: 0px;
}
.p-inputgroup-addon{
  height: 48px ;
}
.txt-right{
  font-weight: bold;
}
.p-col-fixed {
  padding: 0px !important;
  //margin:0px 0px 0px 10px !important;
}
label.p-col-fixed txt-right{
  margin-bottom: 0px!important;
}
div.p-card-field{
  padding: 0px!important;
}
.margin{
  margin-bottom: 12px!important;
}

.border_color{
  border: 1px solid #C8C8C8;
}
.text{
  font-weight: normal!important;
  margin-left: 15px!important;
}
.div_check{
  margin-top: 12px!important;
}


//responsive
@media screen and (max-width: 600px) {
  .input_width{
    width: 100%!important;
  }
  .div-button{
    padding: 8px!important;
  }
  #distance{
    text-align: left!important;
    padding: 6.8px;
    width: 100%!important;
  }
  .p-inputgroup-addon{
    margin-right: 12px;
  }

}

@media screen and (max-width: 1000px) {
  .input_width{
    width: 100%!important;
  }
  .div-button{
    padding: 8px!important;
  }
  .p-inputgroup-addon{
    margin-right: 12px;
  }
  #distance{
    text-align: left!important;
    padding: 6.8px;
  }
}
.p-button-outlined {
  .p-button-label {
    color: #007ad9 !important;
  }
}
</style>
