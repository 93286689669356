<template>
  <form id="frm_action">
    <div class="p-formgrid">
      <Toolbar class="fixed-bottom-toolbar div-button css-button-top-right">
        <template slot="right">
          <Button
              label="Back"
              @click="backToList()"
              id="back"
              class="main-style-button"
          />
        </template>
      </Toolbar>
      <div class="col-6">
        <div class="p-card-field">
          <label class="p-col-fixed txt-right">Property</label>
          <div class="p-col">
            <InputText type="text" class="" v-model="property_name" :disabled="true" placeholder="Input"/>
          </div>
        </div>

        <div class="p-card-field">
          <label class="p-col-fixed txt-right">Owner</label>
          <div class="p-col">
            <InputText type="text" class="" v-model="owner_name" :disabled="true" placeholder="Input"/>
          </div>
        </div>
        <div class="p-card-field">
          <label class="p-col-fixed txt-right">Week</label>
          <div class="p-col">
            <InputText type="text" class="" v-model="week" :disabled="true" placeholder="Input"/>
          </div>
        </div>
      </div>
      <div class="col-12 pl-4">
        <div class="p-card-field histories-box">
          <Timeline :value="histories" class="pl-4 pr-4 pt-3 pb-3">
            <template #marker="slotProps">
              <div class="p-timeline-event-marker" :class="`${slotProps.index == 0 ? 'marker-active' : ''}`"></div>
            </template>
            <template #content="slotProps">
              <div :class="`${slotProps.index == 0 ? 'status-active' : ''}`">
                <b>{{$constants.VISIT_STATUS.filter(f => f.value == slotProps.item.status)[0].label}}</b>
              </div>
              <div class="note-create-date">
                {{getFilterDateLocal(slotProps.item.created_at)}} - {{slotProps.item.create_user ? slotProps.item.create_user.fullname : ''}}
              </div>
              <!-- {{slotProps}} -->
            </template>
          </Timeline>
        </div>
      </div>
      <div class="col-6">
        <div class="p-card-field" v-if="status != 'ST001'">
          <div class="row">
            <div class="col-6">
              <label class="p-col-fixed txt-right">Arrival time (planning)</label>
              <div class="p-col">
                <Calendar 
                  :showOnFocus="false" 
                  dateFormat="MM dd, yy"
                  :disabled="status != 'ST002'"
                  :showIcon="true" 
                  appendTo="body"
                  :showClear="true" 
                  :manualInput="false"
                  v-model="arrival" placeholder="Choose"
                />
              </div>
            </div>
              
            <div class="col-6">
              <label class="p-col-fixed txt-right">Checked in</label>
              <div class="p-col">
                <Calendar 
                  :showOnFocus="false" 
                  dateFormat="MM dd, yy" 
                  id="checkin"
                  :showIcon="true" 
                  appendTo="body"
                  :showClear="true" 
                  :manualInput="false"
                  :disabled="true"
                  v-model="checkin"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="p-card-field" v-if="status != 'ST001'">
          <div class="row">
            <div class="col-6">
              <label class="p-col-fixed txt-right">Departure time (planning)</label>
              <div class="p-col">
                <Calendar 
                  :showOnFocus="false" 
                  dateFormat="MM dd, yy"
                  :disabled="status != 'ST002'"
                  :showIcon="true" 
                  appendTo="body"
                  :showClear="true" 
                  :manualInput="false"
                  v-model="departure" placeholder="Choose"
                />
              </div>
            </div>
              
            <div class="col-6">
              <label class="p-col-fixed txt-right">Checked out</label>
              <div class="p-col">
                <Calendar 
                  :showOnFocus="false" 
                  dateFormat="MM dd, yy" 
                  id="checkout"
                  :showIcon="true" 
                  appendTo="body"
                  :showClear="true" 
                  :manualInput="false"
                  :disabled="true"
                  v-model="checkout"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="p-card-field" v-if="status != 'ST001' && !is_owner">
          <h4>CONTACT INFORMATION</h4>
        </div>
        <div class="p-card-field" v-if="status != 'ST001' && !is_owner">
          <label class="p-col-fixed txt-right">Name</label>
          <div class="p-col">
            <InputText type="text" class="" v-model="contact_name" :disabled="true" placeholder="Input"/>
          </div>
        </div>
        <div class="p-card-field" v-if="status != 'ST001' && !is_owner">
          <label class="p-col-fixed txt-right">Email</label>
          <div class="p-col">
            <InputText type="text" class="" v-model="contact_email" :disabled="true" placeholder="Input"/>
          </div>
        </div>
        <div class="p-card-field" v-if="status != 'ST001' && !is_owner">
          <label class="p-col-fixed txt-right">Phone number</label>
          <div class="p-col">
            <InputText type="text" class="" v-model="phone_number" :disabled="true" placeholder="Input"/>
          </div>
        </div>
        <div class="p-card-field" v-if="status != 'ST001'">
          <h4>WHO STAY {{ is_owner ? `(${people.length})` : '' }}</h4>
        </div>
        <div class="p-card-field" v-if="status != 'ST001'">
          <div v-if="is_owner"><i>The owner will stay.</i></div>
          <div v-else>
            {{ `${getAdult} adults - ${getChild} child (under 11 years old)` }}
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="col-12 p-0" v-if="status != 'ST001' && !is_owner && uid != owner_id">
        <DataTable :value="people" :key="peopleKey">
          <Column field="no" header="No." :headerStyle="{width: '5em'}">
            <template #body="slotProps">
              {{slotProps.index+1}}
            </template>
          </Column>
          <Column field="fullname" header="Fullname"></Column>
          <Column field="personal_id" header="ID/ Passport"></Column>
          <Column field="child" header="Under 11">
            <template #body="slotProps">
                <div style="text-align: center;">
                  <i class="fa fa-solid fa-check" style="color: #00973D;" v-if="slotProps.data.is_child"/>
                
                  <!-- <i class="fa fa-solid fa-times" style="color: #E95D57;" v-else/> -->
                </div>
              </template>
          </Column>
          <Column field="images" header="Images" :headerStyle="{width: '15em'}">
            <template #body="slotProps">
              <div class="d-flex">
                <img style="width: 100px;height: 100px;margin: 0 2px;" v-for="(item, index) in slotProps.data.images" :key="`image-${index}`" :src="item.objectURL" />
              </div>
            </template>
          </Column>
          <Column field="update_name" header="Updated by">
            <template #body="slotProps">
              <span v-if="slotProps.data.update_user">{{slotProps.data.update_user.fullname}}</span>
            </template>
          </Column>
          <Column field="updated_at" header="Updated date">
            <template #body="slotProps">
              {{getFilterDate(slotProps.data.updated_at)}}
            </template>
          </Column>
          <Column headerClass="txt-center" bodyClass="txt-center" v-if="status != 'ST007'">
            <template #header>
              <Button icon="pi pi-plus" class="p-button-success" @click="addPeople()"/>
            </template>
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="p-button-edit" @click="editPeople(slotProps.index)" />
              <Button icon="pi pi-trash" class="p-button-danger ml-2" @click="delPeople(slotProps.index)" />
            </template>
          </Column>
          <template #empty>
              <div class="txt-center">No records found.</div>
          </template>
        </DataTable>
        
      </div>
        
        
        <div class="p-card-field" v-if="status != 'ST001'">
          <h4>SERVICE ({{service.length}})</h4>
        </div>
      </div>
      <div class="col-12" v-if="status != 'ST001'">
        <DataTable :key="loadService" :value="service">
          <Column field="no" header="No." :headerStyle="{width: '5em'}">
            <template #body="slotProps">
              {{slotProps.index+1}}
            </template>
          </Column>
          <Column field="code" header="Order ID/ Request ID">
            <template #body="slotProps">
              <a class="txt-link" @click="goToPath(slotProps.data.type, slotProps.data.id)">{{slotProps.data.code}}</a>
            </template>
          </Column>
          <Column field="service_name" header="Service name"></Column>
          <Column field="status" header="Status"></Column>
          <Column field="qty" header="Quantities">
            <template #body="slotProps">
              {{slotProps.data.qty.toLocaleString("en-US")}}
            </template>
          </Column>
          <Column field="total" header="Total">
            <template #body="slotProps">
              {{slotProps.data.total.toLocaleString("en-US", {
                style: "currency",
                currency: "EUR"
              })}}
            </template>
          </Column>
          <Column field="create_date" header="Created date"></Column>
          <template #empty>
              <div class="txt-center">No records found.</div>
          </template>
        </DataTable>
        
      </div>
      <Toolbar class="fixed-bottom-toolbar div-button">
        <template slot="left">
          <Button
              v-if="status == 'ST002'"
              label="Cancel appointment"
              @click="cancelAppointment()"
              class="p-button-secondary"
          />
        </template>
        <template slot="right">
          <Button
              :label="$constants.TEXT_BUTTON.BACK"
              @click="backToList()"
              class="p-button-outlined"
              id="back"
          />
          <Button
              v-if="mode == 'edit'"
              :label="$constants.TEXT_BUTTON.EDIT"
              @click="saveData()"
              class="main-style-button"
          />
          <Button
              v-else
              :label="$constants.TEXT_BUTTON.ADD"
              @click="saveData()"
              class="main-style-button"
          />
        </template>
      </Toolbar>
      
      <Dialog header="Are you sure you want to cancel this appointment?" :visible.sync="showCancelAppointment" :style="{width: '50vw'}" >
        <div>This appointment will be canceled, and any pre-booked services associated with it will also be canceled.</div>
        <div>Pre-paid service fees (if any) will be refunded outside the system.</div>
        <template #footer>
            <Button label="Cancel" icon="pi pi-times" class="p-button-secondary" @click="showCancelAppointment = false"/>
            <Button label="Confirm" icon="pi pi-check" @click="confirmCancelAppointment"/>
        </template>
      </Dialog>
      
      <Dialog header="Who stay" :visible.sync="showPopupAdd" :style="{width: '50vw'}" >
        <form id="frm_action_add">
            <div v-if="editPeopleData">
                <div>
                  <label class="p-col-fixed txt-right txt-required">Fullname</label>
                  <div class="p-col">
                      <InputText id="people_fullname" type="text" class="" v-model="editPeopleData.fullname" placeholder="Input"/>
                  </div>
                </div>
                
                <div>
                  <label class="p-col-fixed txt-right txt-required">ID/ passport</label>
                  <div class="p-col">
                      <InputText id="people_personal_id" type="text" class="" v-model="editPeopleData.personal_id" placeholder="Input"/>
                  </div>
                </div>
                <div>
                  <div class="pl-2 d-flex align-items-center">
                    <Checkbox id="is_child" v-model="editPeopleData.is_child" :binary="true" />
                    <label class="m-0 ml-3" for="is_child">Children (under 11 years old)</label>
                  </div>
                </div>
                <div>
                  <label class="p-col-fixed txt-right txt-required">Images</label>
                  <div class="p-col">
                    <div  class="cus-text">Recommend: 1.000x1.000px Maximum: 2 MB</div>
                    <div class="cus-text">Accepted file: JPG, JPEG, PNG, GIF.</div>
                    <input class="p-inputtext p-component"  accept="image/*" name="images[]" id="people_images" type="file" @change="onFileSelectFile" multiple/>
                    <input class="input_tmp_validator" id="list_file">
                    <div class="cus-text style_list_image" :key="updateImg">
                      <div class="box-image" v-for="(item_p, index_p) in editPeopleData.images" :key="index_p">
                        <img class="style_img" :src="item_p.objectURL"/>
                        <div class="button-clear" @click="onFileClearProduct(item_p,index_p)"><img src="/assets/images/icon/cancel_black_24dp.png"/></div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            
        </form>
        <template #footer>
            <Button label="Cancel" icon="pi pi-times" class="p-button-secondary" @click="showPopupAdd = false"/>
            <Button label="Confirm" icon="pi pi-check" @click="confirmPopupAdd"/>
        </template>
    </Dialog>
    </div>
  </form>
</template>
<script>
import DataServices from '@/core/DataServices';
import moment from "moment-timezone";
export default {
  components: {
  },
  props: {
    mode: String,
    model: Object,
    users: Array
  },
  data() {
    return {
      loadService: 0,
      property_name: null,
      owner_name: null,
      checkin: null,
      checkout: null,
      histories: null,
      is_owner:false,
      service: [],
      arrival: null,
      departure: null,
      people: [],
      week: null,
      uid: null,
      owner_id: null,
      status: null,
      child: 0,
      adult: 0,
      time_zone_local : moment.tz.guess(true),
      phone_number: null,
      contact_name: null,
      contact_email: null,
      showCancelAppointment:false,
      maxFileSize: 2097152, /*2MB*/
      editPeopleData: null,
      showPopupAdd: false,
      peopleKey: 0,
      updateImg: 0,
      componentKey: 0
    }
  },
  watch: {
  },
  async mounted() {
    if (this.model !== undefined && Object.keys(this.model).length > 0) {
      console.log("this.model",this.model)
      this.property_name = this.model?.property?.name;
      this.owner_name = this.model?.owner?.fullname;
      this.status = this.model?.status;
      this.is_owner = this.model?.is_owner;
      this.uid = this.model?.uid;
      this.owner_id = this.model?.owner_id;
      this.histories = this.model.histories.sort((a, b) => {
        const nameA = a.created_at;
        const nameB = b.created_at;
        if (nameA > nameB) {
          return -1;
        }
        if (nameA < nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      })
      this.week = `Week ${this.model.week_number} (${moment.tz(this.model.visit_start, this.time_zone_local).format("LL")} - ${moment.tz(this.model.visit_end, this.time_zone_local).format("LL")})`;
      this.checkin = this.model?.check_in_time ? this.getFilterDate(this.model.check_in_time) : null;
      this.checkout = this.model?.check_out_time ? this.getFilterDate(this.model.check_out_time) : null;
      this.arrival = this.model?.arrival ? this.getFilterDate(this.model.arrival) : null;
      this.departure = this.model?.departure ? this.getFilterDate(this.model.departure) : null;
      var service = [];
      this.model.orders.map(m => {
        if (m?.items) {
          m.items.map(n => {
            service.push({
              id: n.id,
              code: m.code,
              type: 'service',
              service_name: n.service.name,
              status: this.$constants.SERVICE_STATUS.filter(f => f.value == n.status)[0]?.label,
              qty: n.qty,
              total: n.qty * n.price,
              create_date: this.getFilterDate(n.created_at)
            })
          })
        }
      })
      this.model.requests.map(m => {
        if (m?.items) {
          m.items.map(n => {
            service.push({
              id: n.id,
              code: m.code,
              type: 'request',
              service_name: n.obj_service.name,
              status: this.$constants.REQUEST_STATUS.filter(f => f.value == n.payment_status)[0]?.label,
              qty: n.qty,
              total: n.qty * n.price,
              create_date: this.getFilterDate(n.created_at)
            })
          })
        }
      })
      this.service = service;
      this.phone_number = `${this.model?.contact_prefix_phone ?? ""} ${this.model?.contact_phone ?? ""}`;
      this.contact_name = this.model.contact_name;
      this.contact_email = this.model.contact_email;
      this.people = this.model.peoples;
      this.peopleKey++;
      this.componentKey++;
      this.loadService++;
    }
  },
  computed: {
    getAdult() {
      if (this.model?.user?.role != 'renter') {
        return this.model?.adults || 0;
      }
      return this.people.length > 0 ? this.people.filter(f => !f.is_child).length : 0;
    },
    getChild() {
      if (this.model?.user?.role != 'renter') {
        return this.model?.children || 0;
      }
      return this.people.length > 0 ? this.people.filter(f => f.is_child).length : 0;
    },
  },
  methods: {
    cancelAppointment() {
      this.showCancelAppointment = true;
    },
    confirmCancelAppointment() {
      if (this.status == 'ST002') {
        if (this.service.length == 0) {
          this.showCancelAppointment = false;
          this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Cancel service successfully', life: 3000 })
          return;
        }
        this.service.map(async (m, i) => {
          let data_upd = m?.type == 'request' ? {
            payment_status: 'ST003',
            updated_at: new Date(),
            updated_by: this.$store.getters.user,
          } : {
            status: 'ST003',
            updated_at: new Date(),
            updated_by: this.$store.getters.user,
          }
          let where_k = m?.type == 'request' ? {
            'id': {'_eq': m.id},
            'payment_status': {'_eq': 'ST001'},
          } :
          {
            'id': {'_eq': m.id},
            'status': {'_eq': 'ST001'},
          }
          let variables = {
            'data_update': data_upd,
            'where_key': where_k
          };
          await this.$apollo.mutate({
            mutation: DataServices.updateData(m?.type == 'request' ? 'visit_need_to_request_item' : 'visit_order_item'),
            variables: variables
          }).then(async () => {
            if (this.service.length == (i+1)) {
              setTimeout(() => {
                this.$emit("updateData");
                this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Cancel service successfully', life: 3000 })
              }, 200);
            }
          })
        })
      } else {
        this.showCancelAppointment = false;
      }
    },
    addPeople() {
      this.editPeopleData = {
        fullname: null,
        images: [],
        personal_id: null,
        is_child: false,
        updated_at: new Date(),
        updated_by: this.$store.getters.user
      }
      this.showPopupAdd = true;
    },
    confirmPopupAdd() {
      let people_fullname_add = document.getElementById("people_fullname");
      if (this.$commonFuction.isEmptyObject(this.editPeopleData.fullname)){
        people_fullname_add.setCustomValidity("Please enter the full name!");
      }else {
        people_fullname_add.setCustomValidity("");
      }
      
      let people_personal_id_add = document.getElementById("people_personal_id");
      if (this.$commonFuction.isEmptyObject(this.editPeopleData.personal_id)){
        people_personal_id_add.setCustomValidity("Please enter ther personal ID!");
      }else {
        people_personal_id_add.setCustomValidity("");
      }
      
      let people_images_add = document.getElementById("people_images");
      if (!this.editPeopleData?.images || this.editPeopleData.images.length == 0){
        people_images_add.setCustomValidity("Please upload a personal ID photo!");
      }else if(this.editPeopleData.images.length != 2){
        people_images_add.setCustomValidity("Please upload maxium 2 photo!");
      }else {
        people_images_add.setCustomValidity("");
      }
      var inpObj = document.getElementById("frm_action_add");
      if (!inpObj.checkValidity()){
        inpObj.reportValidity();
        return true;
      }
      this.editPeopleData.updated_by = this.$store.getters.user;
      this.editPeopleData.updated_at = new Date();
      if (this.editPeopleData?.index) {
        this.people[this.editPeopleData.index] = this.editPeopleData;
      }
      if (this.editPeopleData.type != 'edit') {
        this.people.push(this.editPeopleData);
      }
      this.editPeopleData = null;
      this.showPopupAdd = false;
      this.peopleKey++;
    },
    delPeople(index) {
      this.people.splice(index, 1);
    },
    editPeople(index) {
      this.people[index].type = 'edit';
      this.people[index].index = index;
      this.editPeopleData = this.people[index];
      this.showPopupAdd = true;
    },
    goToPath(type, id) {
      var path = `/admin/list-request-order/edit/${id}`;
      if (type == 'service') {
        path = `/admin/list-service-order/view/${id}`;
      }
      console.clear()
      console.log("path", path);
      this.$router.push({path: path});
    },
    getFilterDate(date) {
        return moment(date).utc().format("LL HH:mm");
    },
    getFilterDateLocal(date) {
        return moment.tz(date, this.time_zone_local).format("LL HH:mm");
    },
    backToList() {
      this.$emit('back');
    },
    async validateData() {
      this.validationErrors = {};
      var inpObj = document.getElementById("frm_action");
      if (!inpObj.checkValidity()){
        console.log('test=====================');
        inpObj.reportValidity();
        return true;
      }
    },

    async saveData() {
      this.$commonFuction.is_loading(true);
      if (await this.validateData()) {
        this.$commonFuction.is_loading(false);
        return
      }
      var params = {
        arrival: this.arrival,
        departure: this.departure,
        people: this.people
      }
      console.log('saveData', params)
      this.$emit('save', params)
    },
    
    async onFileSelectFile(event) {
      // var that = this;
      let files = event.target.files || event.dataTransfer.files;
      if(files.length > 0) {
        let acceptTags = ['jpeg', 'jpg', 'gif', 'png', 'JPEG', 'JPG', 'PNG', 'GIF', 'svg', 'SVG']
        for (let i = 0; i < files.length; i++) {
          if (!acceptTags.includes(files[i].name.split('.').pop())) {
            alert("Only post formats are supported: jpeg, jpg, gif, png, svg");
            return;
          }
          // that.obj_data.filename =files[i].name;
          // this.comFilename++;
        }
        var arrTemp = [], arrFiles = [];
        for (let file of files) {
          if (file.size <= this.maxFileSize) {
            file.source = file;
            file.objectURL = window.URL.createObjectURL(file);
            arrTemp.push(file);
          }
          if (file.size > this.maxFileSize) arrFiles.push(file);
        }
        if (arrFiles.length > 0) {
          alert("Your image is larger 2MB");
          // that.obj_data.filename = "";
          return;
        }
        // else {
        //   // this.editPeopleData.images = [];
        // }
        this.editPeopleData.images = this.editPeopleData.images.concat.apply(this.editPeopleData.images, arrTemp);
        console.log("editPeopleData.images", this.editPeopleData.images);
        this.updateImg++;
      }
    },
    onFileClearProduct(item,index) {
      console.log('itemitem:',item)
      this.editPeopleData.images.splice(index, 1);
      console.log("editPeopleData.images", this.editPeopleData.images);
      this.updateImg++;
    },
    isImage(file) {
      return /^image\//.test(file.type);
    },
  },
}
</script>

<style lang="scss">
.p-multiselect {
  width: 450px;
}
.p-multiselect-label:not(.p-placeholder) {
  padding-top: .25rem;
  padding-bottom: .25rem;

}
.category-item-value {
  padding: .25rem .5rem;
  border-radius: 3px;
  display: inline-flex;
  margin-right: .2rem;
  background-color: var(--primary-color);
  color: var(--primary-color-text);
}
.p-multiselect-label-container{
  height: 40px;
}
.p-inputtext{
  margin:  0px 0px 0px 4px;
}

//style form add-edit
@media screen and (min-width: 1001px) {
  .input_distance {
    width: 728px !important;
  }
}
label.p-col-fixed txt-right{
  margin-bottom: 0px!important;
}
div.p-card-field{
  padding: 0px!important;
}
.p-col-fixed {
  padding: 0px !important;
  margin:0px 0px 0px 9px !important;
}
.input_text{
  width: 760px!important;
  height: 48px!important;
}
.txt-right{
  font-weight: bold;
}

.multiselect{
  width: 760px!important;
}
.layout-main{
  background: #00000029;
}

.vs__clear{
  display: none;
}
.input_width{
  width: 760px!important;
  height: 48px!important;
}
#distance{
  width: 1px!important;
  height: 48px!important;
  border-left: 0px;
  border-top: 0px;
  border: 1px solid #C8C8C8;
  border-radius: 0px;
  //border-top: none!important;
  //border: 1px solid rgba(60, 60, 60, 0.26);;
}
.manager-title{
  font-size: 24px!important;
}
.input-dif{
  margin-left: 5px!important;
  border-top: 0px;
}
.p-inputgroup-addon{
  height: 48px ;
}
.txt-right{
  font-weight: bold;
}
.p-col-fixed {
  padding: 0px !important;
  //margin:0px 0px 0px 10px !important;
}
label.p-col-fixed txt-right{
  margin-bottom: 0px!important;
}
div.p-card-field{
  padding: 0px!important;
}
.margin{
  margin-bottom: 12px!important;
}

.border_color{
  border: 1px solid #C8C8C8;
}
.text{
  font-weight: normal!important;
  margin-left: 15px!important;
}
.div_check{
  margin-top: 12px!important;
}


//responsive
@media screen and (max-width: 600px) {
  .input_width{
    width: 100%!important;
  }
  .div-button{
    padding: 8px!important;
  }
  #distance{
    text-align: left!important;
    padding: 6.8px;
    width: 100%!important;
  }
  .p-inputgroup-addon{
    margin-right: 12px;
  }

}

@media screen and (max-width: 1000px) {
  .input_width{
    width: 100%!important;
  }
  .div-button{
    padding: 8px!important;
  }
  .p-inputgroup-addon{
    margin-right: 12px;
  }
  #distance{
    text-align: left!important;
    padding: 6.8px;
  }
}
.p-button-outlined {
  .p-button-label {
    color: #007ad9 !important;
  }
}
.p-timeline-event-opposite {
  display: none;
}
.p-timeline-event-separator {
  position: relative;
}
.p-timeline-event-separator:after {
  position: absolute;
  content: "a";
  color: transparent;
  height: 100%;
  width: 2px;
  background-color: #D0D4D9;
  border-radius: 5px;
  right: 0;
  z-index: 0;
  left: 0;
  margin: auto;
}
.p-timeline-event-marker {
  position:relative;
  z-index: 1;
  background-color: #D0D4D9 !important;
  border: 2px solid #fff !important;
}
.p-timeline-event-content * {
  color: #666666,
}
.p-timeline-event-content .status-active * {
  color: #1D76BC !important;
}
.p-timeline-event-marker.marker-active{
  background-color: #fff !important;
  border: 2px solid #1D76BC !important;
  box-shadow: 0px 0px 5px 1px #1D76BC !important;
  outline: 3px solid #7ec6fea1 !important;
}
.histories-box {
    border: 1px solid #D0D4D9;
    border-radius: 2px;
}

.style_list_image {
  display: flex;
  flex-wrap: wrap; /* Cho phép các phần tử xuống dòng */
  gap: 10px; /* Khoảng cách giữa các phần tử */
  .box-image {
    width: 100px;
    height: 100px;
    margin-top: 8px;
    margin-bottom: 8px;
    position: relative;
    .style_img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border: 1px solid #D0D4D9;
      border-radius: 10px;
    }
    .button-clear{
      cursor: pointer;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 1px;
      right: 8px;
    }

  }
}
</style>
