import BaseModel from '../core/BaseModel'
//import UserModel from '@/models/UserModel'
export default class RecommendationCategoryModel extends BaseModel {

    constructor() {
        super({
                name: "recommendation_category",
                tableName: 'recommendation_category',
                label: "Recommendation category",
                display_key: 'name'
            },
            {
                stt: {
                    name: "stt",
                    label: "No.",
                    type: "Computed",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                },
                id: {
                    name: 'id',
                    type: "Uuid",
                    label: 'ID',
                    primaryKey: true,
                    display: false
                },
                name: {
                    name: 'name',
                    type: "String",
                    label: 'Category name',
                    //width: 35,
                    filter: {type: 'input', match: 'contains'},
                },
                ordinal: {
                    name: 'ordinal',
                    type: "String",
                    label: 'Order'
                },
                /*active: {
                    name: "active",
                    label: "Hiển thị",
                    type: "Checkbox"
                },*/

                active: {
                    name: "active",
                    label: "Active",
                    type: "Selection",
                    custom: 'SelectionText',
                    options: [
                        {value: 'true', label: 'Yes', style: 'color:  #000000;'},
                        {value: 'false', label: 'No',style: 'color:  #000000;'}
                    ],
                    filter: {
                        type: 'select',
                        optionLabel: 'label',
                        optionKey: 'value',
                        match: 'equals'
                    },
                    class:"center ",
                },
                updated_by_key: {
                    name: "updated_by_key",
                    label: "Updated by",
                    type: "Computed",
                    computed: (data) => {
                        data.updated_by_key ='' ;
                    },
                },
                updated_at : {
                    name: 'updated_at',
                    type: "Datetime",
                    label: 'Updated date',
                    width: 15,

                },
                is_use_en: {
                    name: 'is_use_en',
                    type: "Boolean",
                    label: 'Use English',
                    display: false
                },
                detail_get: {
                    refs:  ' obj_updated_by { id fullname }   lang_arr {id id_lang description recommendation_category_id name }',
                    display: false
                },
            })
    }

}