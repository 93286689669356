<template>
  <form id="frm_action">
    <div class="p-formgrid">

      <div class="col-12">
        <div class="p-card-field">
          <label class="p-col-fixed txt-right style_title_group ">MESSAGE: AUTOMATIC REMINDER CONTENT</label>
          <div class="p-col">
          </div>
        </div>
      </div>

      <div class="col-6">
        <div class="p-card-field">
          <label class="p-col-fixed txt-right txt-required">Reminder for users when it's time for their booking (DK) </label>
          <div class="p-col">
            <InputText id="reminder_for_users" v-model="obj_data.reminder_for_users"
              placeholder="Now, it's your turn to select 2 weeks of 1st round" />
          </div>
        </div>

        <div class="p-card-field">
          <label class="p-col-fixed txt-right txt-required">Ask user to verify arrival date (DK) </label>
          <div class="p-col">
            <InputText id="ask_user_to_verify" v-model="obj_data.ask_user_to_verify"
              placeholder="Hi, in next X2 day you will come back to 2nd Home, please confirm your using" />
          </div>
        </div>

        <div class="p-card-field">
          <label class="p-col-fixed txt-right txt-required">Welcome message for scheduled arrival date (DK) </label>
          <div class="p-col">
            <InputText id="welcome_message" v-model="obj_data.welcome_message"
              placeholder="Welcome to your home, everything is ok for you?" />
          </div>
        </div>

          <div class="p-card-field div_check">
            <div class="p-col checkbox">
              <Checkbox v-model="obj_data.is_use_en" :binary="true" />
              <label class="p-col-fixed txt-right text">Use English</label>
            </div>
          </div>
<div class="p-card-field">
          <label class="p-col-fixed txt-right txt-required">Reminder for users when it's time for their booking (EN) </label>
          <div class="p-col">
            <InputText :disabled="obj_data.is_use_en==true ? false:true" id="reminder_for_users" v-model="obj_data.reminder_for_users_en"
               />
          </div>
        </div>

        <div class="p-card-field">
          <label class="p-col-fixed txt-right txt-required">Ask user to verify arrival date (EN) </label>
          <div class="p-col">
            <InputText :disabled="obj_data.is_use_en==true ? false:true" id="ask_user_to_verify" v-model="obj_data.ask_user_to_verify_en"
             />
          </div>
        </div>

        <div class="p-card-field">
          <label class="p-col-fixed txt-right txt-required">Welcome message for scheduled arrival date (EN) </label>
          <div class="p-col">
            <InputText :disabled="obj_data.is_use_en==true ? false:true" id="welcome_message" v-model="obj_data.welcome_message_en"
              />
          </div>
        </div>



        <div class="p-card-field">
          <label class="p-col-fixed txt-right style_title_group">DATE & TIME</label>
          <div class="p-col">
          </div>
        </div>

        <div class="p-card-field">
          <label class="p-col-fixed txt-right">Booking duration</label>
          <div class="p-col">
            <div class="p-inputgroup">
              <InputText id="booking_duration" v-model="obj_data.booking_duration" placeholder="" type="number"
                min="0" />
              <span class="p-inputgroup-addon">days</span>
            </div>
          </div>
        </div>

        <div class="p-card-field">
          <label class="p-col-fixed txt-right">Number of days before the upcoming week that the system sends a
            confirmation request </label>
          <div class="p-col">
            <div class="p-inputgroup">
              <InputText id="number_of_days" v-model="obj_data.number_of_days" placeholder="" type="number" min="0" />
              <span class="p-inputgroup-addon">days</span>
            </div>
          </div>
        </div>

        <div class="p-card-field">
          <label class="p-col-fixed txt-right">Automated notification for exceeding arrival grace period </label>
          <div class="p-col">
            <div class="p-inputgroup">
              <InputText id="automated_notification" v-model="obj_data.automated_notification" placeholder=""
                type="number" min="0" />
              <span class="p-inputgroup-addon">hours</span>
            </div>
          </div>
        </div>

        <div class="p-card-field">
          <label class="p-col-fixed txt-right">Swap Cancellation Grace Period for user (after accepting) </label>
          <div class="p-col">
            <div class="p-inputgroup">
              <InputText id="swap_cancellation" v-model="obj_data.swap_cancellation" placeholder="" type="number"
                min="0" />
              <span class="p-inputgroup-addon">hours</span>
            </div>
          </div>
        </div>

        <div class="p-card-field">
          <label class="p-col-fixed txt-right style_title_group">CONTACT INFORMATION</label>
          <div class="p-col">
          </div>
        </div>

        <div class="p-card-field">
          <label class="p-col-fixed txt-right txt-required">Contact name for renting </label>
          <div class="p-col">
            <InputText id="contact_name" v-model="obj_data.contact_name" placeholder="Input" />
          </div>
        </div>

        <div class="p-card-field">
          <label class="p-col-fixed txt-right txt-required">Phone number/ Hotline</label>
          <div class="p-col">
            <div class="style-box-phone">
              <Dropdown v-model="obj_data.prefix_phone" :options="list_phone_code" optionLabel="prefix_phone"
                optionValue="prefix_phone" />
              <InputText type="number" id="phone" class="" v-model="obj_data.phone" placeholder="Input" />
            </div>

          </div>
        </div>

        <div class="p-card-field">
          <label class="p-col-fixed txt-right txt-required">Email </label>
          <div class="p-col">
            <InputText id="email" type="email" v-model="obj_data.email" placeholder="mail@example.com" />
          </div>
        </div>

        <div class="p-card-field">
          <label class="p-col-fixed txt-right">WhatsApp</label>
          <div class="p-col">
            <div class="style-box-phone">
              <Dropdown id="whatsapp" v-model="obj_data.what_app_prefix_phone" :options="list_whatsapp_code"
                optionLabel="prefix_phone_whatsapp" optionValue="prefix_phone_whatsapp" />
              <InputText type="number" id="phone_whatsapp" v-model="obj_data.what_app_phone" placeholder="Input" />
            </div>
          </div>
        </div>
      </div>

      <Toolbar class="fixed-bottom-toolbar div-button">
        <template slot="right">
          <Button v-if="this.actionButton.status" label="Cancel" @click="backToList()" class="p-button-outlined" id="back" />
          <Button v-if="this.actionButton.status" label="Save" @click="saveData()" class="main-style-button" />
        </template>
      </Toolbar>

      <Dialog header="Delete" :visible.sync="showDeleteDialog" :contentStyle="{ overflow: 'visible' }" :modal="true">
        Are you sure you want to delete?
        <template #footer>
          <Button label="Delete" @click="deleteData()" icon="pi pi-check"
            class="p-button-danger  btn-confirm-delete-dialog" />
          <Button label="Cancel" @click="showDeleteDialog = false" icon="pi pi-times" class="p-button-warning" />
        </template>
      </Dialog>
    </div>
  </form>
</template>
<script>
import DataServices from '@/core/DataServices'
export default {
  components: {

  },
  props: {
    mode: String,
    model: Object,
    users: Array,
    actionButton: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      key_type_id: 1,
      dataModelMadatory: null,
      componentKeyMadatory: 1,
      listDataMadatory: [],
      deleteId: null,
      deleteName: null,
      showDeleteDialog: false,
      mapServicePrice: {},
      dataModelType: null,
      componentKeyType: 1,
      listDataType: [],
      cke_key: 1,
      listLang: [],
      content_en: '',
      obj_data: {
        reminder_for_users: "",
        ask_user_to_verify: "",
        welcome_message: "",
        booking_duration: null,
        number_of_days: null,
        automated_notification: null,
        swap_cancellation: null,
        contact_name: "",
        prefix_phone: "",
        phone: "",
        email: "",
        what_app_prefix_phone: "",
        what_app_phone: "",
        is_use_en: false,
      },
      list_phone_code: [
        { prefix_phone: "+45" },
        { prefix_phone: "+34" },

      ],
      list_whatsapp_code: [
        { prefix_phone_whatsapp: "+45" },
        { prefix_phone_whatsapp: "+34" },

      ],

    }
  },
  watch: {
 "obj_data.is_use_en": function () {
      //newVal, oldVal
      if(this.obj_data.is_use_en == false){
        this.obj_data.reminder_for_users_en = "";
        this.obj_data.ask_user_to_verify_en = "";
        this.obj_data.welcome_message_en = "";
      }
    },
  },
  async mounted() {
    await this.getSettings();
    console.log("Status of actionButton in child component:", this.actionButton.status);
  },
  methods: {
    async getSettings() {
      var where_key = {
        deleted: { _eq: false },
      }
      let { data } = await this.$apollo.query({
        query: DataServices.getList('setting', { "fields": `id name prefix_phone value updated_at updated_by deleted key phone email what_app_prefix_phone what_app_phone` }),
        variables: {
          where_key: where_key,
        },
        fetchPolicy: "network-only"
      });

      let settings = data.setting;

      settings.forEach(setting => {
        switch (setting.key) {
          case 'KEY_REMINDER_FOR_USERS':
            this.obj_data.reminder_for_users = setting.value;
            break;
          case 'KEY_ASK_USER_TO_VERIFY':
            this.obj_data.ask_user_to_verify = setting.value;
            break;
          case 'KEY_WELCOME_MASSAGE':
            this.obj_data.welcome_message = setting.value;
            break;
          case 'KEY_REMINDER_FOR_USERS_EN':
            this.obj_data.reminder_for_users_en = setting.value;
            break;
          case 'KEY_ASK_USER_TO_VERIFY_EN':
            this.obj_data.ask_user_to_verify_en = setting.value;
            break;
          case 'KEY_WELCOME_MASSAGE_EN':
            this.obj_data.welcome_message_en = setting.value;
            break;
             case 'KEY_USE_LANG_EN':
              console.log('setting.value1111', setting.value);
              if(parseInt(setting.value) == 1){
                  this.$set(this.obj_data, 'is_use_en', true);
              }else{
               this.$set(this.obj_data, 'is_use_en', false);
              }
              console.log('obj_data.is_use_en', this.obj_data.is_use_en); 
            break;

          case 'KEY_BOOKING_DURATION':
            this.obj_data.booking_duration = setting.value;
            break;
          case 'KEY_NUMBER_OF_DAYS':
            this.obj_data.number_of_days = setting.value;
            break;
          case 'KEY_AUTOMATED_NOTIFICATION':
            this.obj_data.automated_notification = setting.value;
            break;
          case 'KEY_SWAP_CANCELLATION':
            this.obj_data.swap_cancellation = setting.value;
            break;
          case 'KEY_CONTACT_PHONE_EMAIL_WHATSAPP':
            this.obj_data.contact_name = setting.name;
            this.obj_data.prefix_phone = setting.prefix_phone;
            this.obj_data.phone = setting.phone;
            this.obj_data.email = setting.email;
            this.obj_data.what_app_prefix_phone = setting.what_app_prefix_phone;
            this.obj_data.what_app_phone = setting.what_app_phone;
            break;
        }
      });
      console.log('datasetting', settings);
    },
    async validateData() {
      this.validationErrors = {};
      const fieldsToValidate = [
        { id: "reminder_for_users", value: this.obj_data.reminder_for_users },
        { id: "ask_user_to_verify", value: this.obj_data.ask_user_to_verify },
        { id: "welcome_message", value: this.obj_data.welcome_message },
        { id: "contact_name", value: this.obj_data.contact_name },
      ];

      for (let i = 0; i < fieldsToValidate.length; i++) {
        let field = fieldsToValidate[i];
        let inputElement = document.getElementById(field.id);

        if (inputElement && (inputElement.tagName === "INPUT" || inputElement.tagName === "TEXTAREA" || inputElement.tagName === "SELECT")) {
          if (this.$commonFuction.isEmptyObject(field.value)) {
            inputElement.setCustomValidity(this.$constants.MSG_TEXT.INPUT_INVALID_MSG);
          } else {
            inputElement.setCustomValidity("");
          }
        }
      }

      // Kiểm tra số điện thoại và WhatsApp với các quy tắc cụ thể
      await this.validatePhoneNumber('phone', this.obj_data.phone);
      await this.validatePhoneNumber('phone_whatsapp', this.obj_data.what_app_phone);

      // Kiểm tra tính hợp lệ của toàn bộ form
      let inpObj = document.getElementById("frm_action");
      if (!inpObj.checkValidity()) {
        inpObj.reportValidity();
        return true;
      }
      return false;
    },
    async validatePhoneNumber(fieldId, phoneValue) {
      let phoneInput = document.getElementById(fieldId);

      if (this.$commonFuction.isEmptyObject(phoneValue)) {
        // Nếu không có giá trị thì không thực hiện kiểm tra độ dài
        phoneInput.setCustomValidity('');
      } else {
        // Nếu có giá trị thì kiểm tra độ dài và ký tự
        if (/[^0-9]/.test(phoneValue)) {
          phoneInput.setCustomValidity('Không cho phép nhập chữ hoặc ký tự đặc biệt');
        } else if (phoneValue.length < 8) {
          phoneInput.setCustomValidity('Tối thiểu 8 ký tự');
        } else if (phoneValue.length > 15) {
          phoneInput.setCustomValidity('Tối đa 15 ký tự');
        } else {
          phoneInput.setCustomValidity(''); // Độ dài hợp lệ
        }
      }

      phoneInput.reportValidity();
    },
    async saveData() {
      this.$commonFuction.is_loading(true);
      if (await this.validateData()) {
        this.$commonFuction.is_loading(false);
        return
      }
      var params = this.obj_data

      console.log('saveData', params)

      this.$emit('save', params)
    },
  },
}
</script>

<style lang="scss">
.p-multiselect {
  width: 450px;
}

.p-multiselect-label:not(.p-placeholder) {
  padding-top: .25rem;
  padding-bottom: .25rem;

}

.category-item-value {
  padding: .25rem .5rem;
  border-radius: 3px;
  display: inline-flex;
  margin-right: .2rem;
  background-color: var(--primary-color);
  color: var(--primary-color-text);
}

.p-multiselect-label-container {
  height: 40px;
}

.p-inputtext {
  margin: 0px 0px 0px 4px;
  border-radius: 7px;
}

//style form add-edit
@media screen and (min-width: 1001px) {
  .input_distance {
    width: 728px !important;
  }
}

label.p-col-fixed txt-right {
  margin-bottom: 0px !important;
}

div.p-card-field {
  padding: 0px !important;
}

.p-col-fixed {
  padding: 0px !important;
  margin: 0px 0px 0px 9px !important;
}

.input_text {
  width: 760px !important;
  height: 48px !important;
}

.txt-right {
  font-weight: bold;
}

.multiselect {
  width: 760px !important;
}

.layout-main {
  background: #00000029;
}

.vs__clear {
  display: none;
}

.input_width {
  width: 760px !important;
  height: 48px !important;
}

#distance {
  width: 1px !important;
  height: 48px !important;
  border-left: 0px;
  border-top: 0px;
  border: 1px solid #C8C8C8;
  border-radius: 0px;
  //border-top: none!important;
  //border: 1px solid rgba(60, 60, 60, 0.26);;
}

.manager-title {
  font-size: 24px !important;
}

.input-dif {
  margin-left: 5px !important;
  border-top: 0px;
}

.p-inputgroup-addon {
  height: 48px;
  width: 50px;
}

.txt-right {
  font-weight: bold;
}

.p-col-fixed {
  padding: 0px !important;
  //margin:0px 0px 0px 10px !important;
}

label.p-col-fixed txt-right {
  margin-bottom: 0px !important;
}

div.p-card-field {
  padding: 0px !important;
}

.margin {
  margin-bottom: 12px !important;
}

.border_color {
  border: 1px solid #C8C8C8;
}

.text {
  font-weight: normal !important;
  margin-left: 15px !important;
}

.div_check {
  margin-top: 12px !important;
}


//responsive
@media screen and (max-width: 600px) {
  .input_width {
    width: 100% !important;
  }

  .div-button {
    padding: 8px !important;
  }

  #distance {
    text-align: left !important;
    padding: 6.8px;
    width: 100% !important;
  }

  .p-inputgroup-addon {
    margin-right: 12px;
  }

}

@media screen and (max-width: 1000px) {
  .input_width {
    width: 100% !important;
  }

  .div-button {
    padding: 8px !important;
  }

  .p-inputgroup-addon {
    margin-right: 12px;
  }

  #distance {
    text-align: left !important;
    padding: 6.8px;
  }
}

.p-button-outlined {
  .p-button-label {
    color: #007ad9 !important;
  }
}

.cus-text {
  margin-left: 2px;
  margin-top: 4px;
}

.style-box-phone {
  border: 1px solid #a6a6a6;
  width: 100%;
  display: flex;

  .p-dropdown {
    width: 100px;
    border: 0px;
    box-shadow: none !important;
  }

  .p-focus {
    border: 0px;
    box-shadow: none;
  }

  .p-dropdown:focus {
    border: 0px;
    box-shadow: none;
  }

  input {
    width: calc(100% - 100px);
    margin-left: 0px;
    border: 0px;
  }

  .p-inputtext:enabled:focus {
    border: 0px;
    box-shadow: none;
  }
}

.style_list_image {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  .box-image {
    width: 100px;
    height: 100px;
    margin-top: 8px;
    margin-bottom: 8px;
    position: relative;

    .style_img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border: 1px solid #D0D4D9;
      border-radius: 10px;
    }

    .button-clear {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 1px;
      right: 8px;
    }
  }
}

.style_box_room {
  display: flex;
}

.style_box_lat_log {
  display: flex;

  .box_lat_log {
    width: 50%;
  }
}

.style_title_group {
  padding: 10px 0 0 0 !important;
  font: normal normal bold 20px/27px Segoe UI;
  letter-spacing: 0px;
  color: #666666;
  text-transform: uppercase;
}

.style_box_price {
  padding-left: 35px !important;
}

.style_rad_button {
  label {
    margin-bottom: 0px;
    margin-left: 15px;
  }
}

.style_btn_add_type {
  width: 60px;
}



.custom-height {
  height: 5rem;
  padding-bottom: 2rem;
  /* Tạo khoảng cách giữa nội dung và phần trên */
  line-height: 1.5rem;
  /* Điều chỉnh độ cao dòng của nội dung */
}
</style>
