<template>
  <!--stateStorage="session"
    stateKey="dt-state-demo-session"-->
  <DataTable
    :value="listDataService"
    :lazy="true"
    class="p-datatable-responsive list-datatable-visit"
    selectionMode="single"
    data-key="id"
    @page="onPage($event)"
    :paginator="pagination"
    :totalRecords="totalRecords"
    :rows="paginationRows"
    :loading="loading"
    :first="firstRecordIndex"
    :scrollable="(scrollHeight !== '' ? true : false) "
    :scrollHeight="scrollHeight"
    :autoLayout="true"
    @row-select="onRowSelect"
    filterMatchMode="startsWith"
    :rowsPerPageOptions="$constants.LIST_OPTION_COMMON.PER_PAGE_OPTIONS"
    :currentPageReportTemplate=" $constants.LIST_OPTION_COMMON.TEXT_TOTAL_ITEM+' {totalRecords}'"
    :paginatorTemplate="$constants.LIST_OPTION_COMMON.PAGINATOR_TEMPLATE"
  >
    <Column
      v-for="c in model.getDisplayFields()"
      :key="c.key"
      :field="getField(c)"
      :header="getLabel(c)"
      :ref="getField(c)"
      :filterMatchMode="getFilterMatch(c)"
      :sortable="getSortable(c)"
      :bodyClass="(c.class ? 'txt-' + c.class : '')"
      :bodyStyle="getBodyStyle(c)"
      :headerStyle="getHeaderStyle(c)"
      :headerClass="(c.class ? 'txt-' + c.class : '')"
    >
      <template #body="slotProps">
        <!--{{c}}-->
        <img
          v-if="getType(c) == 'Image'"
          :src="getData(c, slotProps.data)"
          style="width: 40px; heigth: 40px"
        />
        <div
          v-else-if="c.type == 'Text'"
          v-html="getData(c, slotProps.data)"
          :style="(c.style ? c.style : '')"
        ></div>
        <div
          v-else-if="getType(c) == 'Custom' || getType(c) == 'Icon'"
          v-html="getData(c, slotProps.data)"
          :style="(c.style ? c.style : '')"
        ></div>
        <div
          v-else-if="getType(c) == 'CustomUrl' && view_club ==true"
          v-html="callbackCustomUrl(c, slotProps.data)"
          @click="redirectListClub(slotProps.data, $event)"
          :style="(c.style ? c.style : '')"
        ></div>
        <div
          v-else-if="getType(c) == 'CustomUrl'"
          v-html="callbackCustomUrl(c, slotProps.data)"
          @click="redirectListClub(slotProps.data, $event)"
          :style="(c.style ? c.style : '')"
        ></div>

        <Checkbox
          v-else-if="getType(c) == 'Checkbox'"
          v-model="slotProps.data[slotProps.column.field]"
          :binary="true"
          @change="onInputChange($event, slotProps.data, c)"
        />
        <div v-else>
          <div v-if="c.custom">
            <Avatar v-if="c.custom == 'image'" :image="getData(c, slotProps.data)" size="xlarge"  />
            <div v-if="c.custom == 'SelectionText'"  v-html="callbackDataSelect( c ,slotProps.data)"></div>
          </div>
          <span v-else v-html="getData(c, slotProps.data)" :class="getCustomClass(c, slotProps.data[c.key], slotProps.data)"></span>
        </div>


      </template>
      <template v-if="c.filter" #filter>
        <InputText
          v-if="c.filter.type == 'input'"
          type="text"
          v-model="filters[getField(c)]"
          class="p-column-filter"
          :placeholder="$CoreService.getFilterPlaceHolder(c)"
          @keyup="onFilter($event,c.filter.type,c.key)"
        />
        <Dropdown
          :key="countDropdown"
          v-if="c.filter.type == 'select'"
          v-model="filters[getField(c)]"
          :options="getFilterOptions(c.key)"
          :optionLabel="c.filter.optionLabel"
          :optionValue="c.filter.optionKey"
          :placeholder="$CoreService.getFilterPlaceHolder(c)"
          class="p-column-filter"
          :showClear="true"
          appendTo="body"

          @change="onFilter($event,c.filter.type,c.key)"
        >
          <template #option="slotProps">
            <div class="p-clearfix">
              <span
                :class="getCustomClass(c, slotProps.option[c.filter.optionKey], slotProps.data)"
                >{{ slotProps.option[c.filter.optionLabel] }}</span
              >
            </div>
          </template>
        </Dropdown>
        <Calendar
          v-if="c.filter.type == 'DateRange'"
          :showOnFocus="false"
          :monthNavigator="true"
          :yearNavigator="true"
          :yearRange="yearRange"
          dateFormat="MM dd, yy"
          :placeholder="$CoreService.getFilterPlaceHolder(c)"
          v-model="filters[getField(c)]"
          class="p-column-filter calendar-light-btn"
          :showIcon="true"
          selectionMode="range"
          appendTo="body"
          :showClear="true"
          :showButtonBar="true"
          @clear-click="onFilter($event,c.filter.type,c.key)"
          @date-select="onFilter($event,c.filter.type,c.key)"
          :manualInput="false"
          style="width: 200px;"
          >
          <template #footer>
            <CalendarFooter />
          </template>
        </Calendar>
          
          <Calendar
          v-if="c.filter.type == 'Date'"
          :showOnFocus="false"
          :monthNavigator="true"
          :yearNavigator="true"
          :yearRange="yearDOB"
          dateFormat="MM dd, yy"
          :placeholder="$CoreService.getFilterPlaceHolder(c)"
          v-model="filters[getField(c)]"
          class="p-column-filter calendar-light-btn"
          :showIcon="true"
          selectionMode="single"
          appendTo="body"
          :showClear="true"
          :maxDate="new Date()"
          :showButtonBar="true"
          @clear-click="onFilter($event,c.filter.type,c.key)"
          @date-select="onFilter($event,c.filter.type,c.key)"
          :manualInput="false"
          style="width: 200px;"
          />
      </template>
    </Column>
    <!-- <Column
      v-if="actions"
      headerStyle="width: 10em; text-align: center"
      bodyStyle="width: 10em; text-align: center; justify-content: center"
    >
      <template #body="slotProps">
        <span class="p-column-title">Actions</span>
        {{ slotProps.data.color }}
        <div class="style-action-click">
        <Button
            v-for="(a, k) in actions.rows(slotProps.data)"
            :key="k"
            type="button"
            :icon="getIcon(a.icon)"
            :class="getClass(a.name)"
            :disabled="getDisabled(a.disabled)"
            style="margin: 2px"
            v-on:click="actionsClicked(k, a, slotProps)"
        ></Button>
        </div>
      </template>
      <template #header="slotProps">
        <Button
          v-if="actions.header"
          type="button"
          :icon="getIcon(actions.header.icon)"
          :class="getClass(actions.header.name)"
          v-on:click="actionsClicked(actions.header.name, actions.header, slotProps)"
        ></Button>
      </template>
    </Column> -->
    <template #empty>
      {{ $t('datatable.empty') }}
    </template>
    <template #loading>
      {{ $t('datatable.loading') }}
    </template>
  </DataTable>
</template>

<script>
import moment from "moment-timezone";
//import gql from "graphql-tag";
import DataServices from "@/core/DataServices";
export default {
  props: {
    model: Object,
    tableData: Array,
    getFilterOptions: Function,
    actions: Object,
    options: Array,
    whereKeyList: Object,
    // view_club:Boolean ,
  },
  data() {
    return {
      filters: {},
      countDropdown: 0,
      pagination: true, // true cho phép phân trang
      paginationRows: this.$constants.LIST_OPTION_COMMON.PAGINATION_ROWS_DEFAULT, // số dòng trên 1 trang
      scrollHeight: '75vh', // chiều cao của trang danh sách
      loading: true,
      rowsPerPageOptions: [10, 20, 50, 100],
      first: 0,
      totalRecords: 120,
      totalRecords2: 12,
      test: null,
      listDataService:null,
      filtersCustom: [],
      lazyParams: {},
      page_transaction:null,
      firstRecordIndex:0,
      // yearRange : (new Date().getFullYear() - 100)+":"+(new Date().getFullYear() + 100),
      // yearDOB : (new Date().getFullYear() - 100)+":"+new Date().getFullYear(),
      yearRange: '',
      yearDOB: '',
      time_zone_local : moment.tz.guess(true),
      view_club : false,
    };
    
  },
  async created() {
    var year = new Date().getFullYear()
    // var yearRange = (year-10)+':'+(year+90);
    // var yearDOB = (year-100)+':'+year;
    var yearRange = year + ':' + (year + 100);
    var yearDOB = year + ':' + (year + 100);
    this.yearRange = yearRange+'';
    this.yearDOB = yearDOB+'';
    this.test = this.tableData;
    var arr_role_clubs = await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_VISIT);
    // console.log('role_clubrole_club',arr_role_clubs)
    if (arr_role_clubs.indexOf(this.$constants.ROLE.VIEW) < 0){
      this.view_club = false;
    }else{
      this.view_club = true;
    }
  },
  mounted () {
    this.scrollHeight = `${(window.innerHeight*(window.innerHeight > 900 ? 70 : 55)/100)}px`;
    this.lazyParams = {
      first: 0,
      rows: this.paginationRows,
      sortField: null,
      sortOrder: null,
      filters: this.filters
    };
    /*
    * stateStorage="session"
    stateKey="dt-state-demo-session"
    * phai them thuoc tinh nay vao table
    * */
    if (!this.$commonFuction.isEmpty(this.$route.query)){
      if (!this.$commonFuction.isEmpty(this.$route.query.page_transaction)){
        this.page_transaction = this.$route.query.page_transaction;
        this.lazyParams = (localStorage.getItem(this.page_transaction)) ? JSON.parse(localStorage.getItem(this.page_transaction)) : {};
        this.firstRecordIndex = this.lazyParams.first;
        // console.log("this.lazyParams:22222222",this.lazyParams);
        /*if (this.lazyParams){
          this.filters = (this.lazyParams.filters) ? this.lazyParams.filters : {};
        }*/
      }
    }
    this.loading = false
    this.countDropdown++;
    this.onLazyEvent();
  },
  methods: {
    onRowSelect(event) {
      this.$emit('onRowSelect', event.data);
    },
    redirectListClub(data, e) {
      let clickedElId = e.target.id
      var path = ``;
      var id = data.id;
      if (clickedElId === 'list-owner') {
        id = data?.owner?.id;
        path = `/admin/list-users/edit/${id}`;
      } else if (clickedElId === 'list-property') {
        id = data?.property?.id;
        path = `/admin/list-property/edit/${id}`;
      }
      this.$router.push({path: path, query: { page_transaction: (new Date()).getTime().toString() }});
      e.stopPropagation();
    },
    callbackCustomUrl(c,data){
      var text = ''
      switch(c.key) {
        case 'owner_name':
          text = '<a class="txt-link" id="list-owner" :data-id="'+data?.owner?.id+'">'+data?.owner?.fullname+'</a>';
          break;
        case 'property_code':
          text = '<a class="txt-link" id="list-property" :data-id="'+data?.property?.id+'">'+data?.property?.code+'</a>';
          break;
          case 'property_name':
          text = '<a class="txt-link" id="list-property" :data-id="'+data?.property?.id+'">'+data?.property?.name+'</a>';
          break;
        default:
          break;
      }
      // this.date_now
      return text;
    },
    select_date(event,col_name) {
      if (this.filters[col_name][0] !== null && this.filters[col_name][1] !== null) {
        this.$emit("filter_date", {
          data: this.filters[col_name],
          col: col_name
        });
      }
    },
    getLabel(col) {
      if (col.label) return col.label;
      else return col.name;
    },
    getType(col) {
      if (col.display?.list?.mode == "custom") {
        return "Custom";
      }
      switch (col.type) {
        case "String":
        case "Text":
        case "Computed":
          if (col.inputOptions?.mode == "Image") {
            return "Image";
          }
          if (col.inputOptions?.mode == "selection") {
            return "Select";
          }
          if (col.inputOptions?.mode == "currency") {
            return "Currency";
          }
          if(col.inputOptions?.mode == 'icon') {
            return "Icon";
          }
          return "Input";
        case "numeric":
        case "ShadowField":
        case "number":
          if (col.inputOptions?.mode == "currency") {
            return "Currency";
          } else if (col.inputOptions?.mode == "percentage") {
            return "Percentage";
          } else {
            return "Number";
          }
        case "Selection":
          return "Select";
        case "Checkbox":
          return "Checkbox";
        default:
          return col.type;
      }
    },
    cannotEdit(col) {
      if (col.edit && col.edit.edit == false) return true;
      return false;
    },
    onInputChange(value, data, f) {
      if (f.onChange)  f.onChange(data).bind(this);
      this.$emit("onDataChange", data, f);
    },
    toggle(event) {
      this.$refs.op.toggle(event);
    },
    getCustomClass(col, data, rowData) {
      if (col.displayClass) return col.displayClass(data, rowData);
      return "";
    },
    getField(col) {
      return col.key;
    },
    getHeaderStyle(col) {
      var style = "text-align:center;";
      if (col.width) style += "width: " + col.width + "em;";
      else if (col.name === "stt" || col.name === "STT") style += "width: 3em;";
      else if (col.type === "image" || col.type === "Image") style += "width: 6em;";
     else if (col.name === "people" ) style += "width: 100px !important;";
     else if (col.name === "service" ) style += "width: 100px !important;";
     else if (col.name === "report" ) style += "width: 100px !important;";
     else if (col.name === "stay_days" ) style += "width: 100px !important;";
      else style += "min-width: 10em;";
      return style;
    },
    getBodyStyle(col) {
      var style = "";
      if (col.width) style = "width: " + col.width + "em;";
      else if (col.name === "stt" || col.name === "STT") style = "text-align:center; width: 3em;";
      else if (col.type === "image" || col.type === "Image") style = "width: 6em;";
      else if (col.name === "people" ) style += "width: 100px !important;";
     else if (col.name === "service" ) style += "width: 100px !important;";
     else if (col.name === "report" ) style += "width: 100px !important;";
     else if (col.name === "stay_days" ) style += "width: 100px !important;";
      else style += "min-width: 10em;";
      return style;
    },
    getData(col, row) {
      // console.log("this.options:",this.options)
      var value = '';
      if (col.type == "Date" && row[col.key]) {
        value = moment(row[col.key]).utc().format("LL");
        // value = moment(row[col.key]).utc().format("DD/MM/yyyy");
      } else if (col.type == "Datetime" && row[col.key]) {
        value = moment(row[col.key]).utc().format("ll HH:mm");
        // value = moment(row[col.key]).utc().format("HH:mm - DD/MM/yyyy");
      } else if (col.type == "CustomDate" && row[col.key]) {
        value = moment(row[col.key]).utc().format("HH:mm - DD/MM/yyyy");
      } else if(col.type == "Hour" && row[col.key]){
        value = moment(row[col.key]).utc().format("HH:mm");
      } else if (col.type == "Selection") {
        var lists = [];
        if (col.options !== undefined) {
          lists = col.options;
        } else if (col.models !== undefined && this.options[col.key] !== undefined) {
          // console.log("col.models:",col.models);
          lists = this.options[col.key];
        }
        if (lists && lists.length) {
          var objResult = lists.filter(a => a.value == row[col.key]);
          if (objResult !== undefined && Object.keys(objResult) && Object.entries(objResult).length) {
            value = objResult[Object.keys(objResult)].label;
          }
        }
      } else {
        if (col.type == "Text" && row[col.key] && row[col.key].length > 100) {
          var result = row[col.key].substr(0, row[col.key].lastIndexOf(" ", 100)) + "...";
          value = result;
        }
        if (this.getType(col) == "Currency") {
          if (row[col.key] == null) row[col.key] = 0;
          value = this.formatCurrency(row[col.key]);
        }
        if (this.getType(col) == "Percentage") {
          if (row[col.key] == null) row[col.key] = `0%`;
          value = row[col.key] + "%";
        }
        value = row[col.key];
      }
      if (col.style) value = '<abbr style="' + col.style + '">' + value + '<abbr/>';
      else if (col.options) {
        var filtered = col.options.filter( a => a.value  === row[col.key]);
        if (Object.keys(filtered) && Object.entries(filtered).length && filtered[0].style) {
          value = '<abbr style="' + filtered[0].style + '">' + value + '<abbr/>';
        }
      }
      return value
    },
    getFilterMatch(col) {
      var filterMatch = "startWith";
      if (col.filter && col.filter.match) {
        filterMatch = col.filter.match;
      }
      return filterMatch;
    },
    getFilterPlaceHolder(col) {
      if (col.filter.placeHolder) {
        return col.filter.placeHolder;
      } else if (col.filter.type == "select") {
        return `Chọn`;
      } else if (col.filter.type == "input") {
        return `Input`;
      } else if (col.filter.type == "DateRange") {
        return `Choose`;
      } else if (col.filter.type == "Date") {
        return `Choose`;
      } else {
        return ``;
      }
    },
    getSortable(col) {
      return col.sortable && col.sortable == true ? true : false;
    },
    getIcon(iconName) {
      return `pi pi-${iconName}`;
    },
    getDisabled(disabled = false) {
      return disabled;
    },
    actionsClicked(key, action, rowData) {
      this.$emit("action-click", {
        originalEvent: event,
        action: action,
        key: key,
        data: rowData,
        lazyParams:this.lazyParams
      });
    },
    getClass(type) {
      switch (type) {
        case "add":
          return "p-button-add";
        case "delete":
          return "p-button-danger";
        case "edit":
          return "p-button-edit";
        case "adddebt":
          return "p-button-edit"
        case "add-Debt":
          return "p-button-add";
        case "editcustom":
          return "p-button-edit"
        case "addcustom":
          return "p-button-add";
      }
    },
    formatCurrency(value) {
      return value.toLocaleString("vi-VN", {
        style: "currency",
        currency: "VND"
      });
    },
    callbackDataSelect(c,data){
        var text = "";
        switch(c.key) {
            case 'updated_name':
                text = data?.update_user?.fullname;
                break;
            case 'active_html':
                if(data.active === true){
                    text += '<div style="text-align: center;"><i class="fa fa-solid fa-check" style="color: #00973D;"/></div>';
                }else{
                    text += '<div style="text-align: center;"><i class="fa fa-solid fa-times" style="color: #1D76BC;"/></div>';
                }
                break;
            case 'type_login':
                if(data.type_login == 'USERNAME'){
                    text += 'Username';
                }else if(data.type_login == 'FACEBOOK'){
                    text += 'Facebook';
                }else if(data.type_login == 'APPLE'){
                    text += 'Apple ID';
                }else if(data.type_login == 'GOOGLE'){
                    text += 'GOOGLE';
                }
                break;
            case 'name_sports':
                if(data.users_setting_sports && data.users_setting_sports.length > 0){
                    for (var i=0;i<data.users_setting_sports.length;i++){
                        let tm = data.users_setting_sports[i];
                        if(tm.sports){
                            if(text.length > 60){
                              text += ",...";
                              break;
                            }
                            if(tm.sports.sports_lang.length > 0){
                                for (let j = 0; j < tm.sports.sports_lang.length; j++) {
                                    let el = tm.sports.sports_lang[j];
                                    if(el.lang =='en'){
                                        text += (!this.$commonFuction.isEmpty(text)) ?  ", "+el.name:el.name;
                                        break;
                                    }
                                } 
                            }
                        }
                    }
                }
                break;
            default:
        }
        return text;
    },
   async dataList(lazyParams) {
    // console.log('lazyParams');
    // console.log('lazyParams:',lazyParams);
     var offset = lazyParams.first;
     var limit = lazyParams.rows;
     var gqLQueryList = DataServices.getList(this.model.tableName);
     var where = {
       deleted: {_eq: false},
       _and: [],

     }
     var listPropertyAdmin = this.$AuthenticationApp.getListProperty();
     console.log('listPropertyAdminlistPropertyAdmin',listPropertyAdmin)
     if (listPropertyAdmin!=null){
       where["property_id"]={_in:listPropertyAdmin}
     }
     var filters = lazyParams.filters;
     if (filters){
       for (const [key, value] of Object.entries(filters)) {
        //  console.log("dsadsd",value.type_input);
          if (value.type_input == 'input'){
            if (!this.$commonFuction.isEmpty(value.value)){
              if (key == 'property_code'){
                if(!where.property){
                  where.property = {};
                }
                where.property.code ={_iregex: this.$commonFuction.search(value.value)} 
              } 
              else if (key == 'property_name'){
                if(!where.property){
                  where.property = {};
                }
                where.property.name ={_iregex: this.$commonFuction.search(value.value)} 
              }  else if (key == 'who_confirmed'){
                if(!where.view_history_visit_confirm){
                  where.view_history_visit_confirm = {};
                }
                where.view_history_visit_confirm.created_by_obj ={fullname: {_iregex: this.$commonFuction.search(value.value)}};
              } 
              else if (key == 'owner_name'){
                where.owner = {fullname: {_iregex: this.$commonFuction.search(value.value)}};
              } else if (key == 'who_stay'){
                //where.user = {fullname: {_iregex: this.$commonFuction.search(value.value)}};
                //where['_or'] = [{user: {fullname: {_iregex: this.$commonFuction.search(value.value)}}},{contact_name: {_iregex: this.$commonFuction.search(value.value)}}];
              where['text_group_uid_fullname_contact_name'] = {_iregex: this.$commonFuction.search(value.value)};
              } else {
                where[key] = {_iregex: this.$commonFuction.search(value.value)};
              }
            }
          }
         else if(value.type_input == 'select'){
            // console.log("filters",filters)
            if(key == "updated_name"){
                if(value.value !== undefined && value.value !== null){
                    where['updated_by'] = {_eq: value.value}
                }
            }else{
                if(value.value !== undefined && value.value !== null){
                    where[key] = {_eq: value.value}
                }
            }
          }else if (value.type_input == 'DateRange'){
            if(value.value !== undefined && value.value !== null){
              if (key == 'visit') {
                where["_and"].push({_or: [{visit_end: {'_gte': value.value.to}, visit_start: {'_lte': value.value.from}}, {visit_start: {'_gte': value.value.from}, visit_end: {'_lte': value.value.to}}]});
              } else if (key == 'arrival') {
                where["_and"].push({_or: [{check_in_time: {'_gte': value.value.from, '_lte': value.value.to}}, {arrival: {'_gte': value.value.from,'_lte': value.value.to}}]});
              } else if (key == 'departure') {
                where["_and"].push({_or: [{check_out_time: {'_gte': value.value.from, '_lte': value.value.to}}, {departure: {'_gte': value.value.from,'_lte': value.value.to}}]});
              } else {
                where[key] = {'_gte': value.value.from,'_lte': value.value.to}
              }
            }
          } else if (value.type_input == 'Date'){
              if(value.value !== undefined && value.value !== null){
                // console.log("dsadasdas");
                if(key == "full_birthday"){
                  where[key] = {_eq: moment(value.value).tz("Asia/Ho_Chi_Minh").format("yyyy-MM-DD")}
                } else {
                  where[key] = {_eq: value.value}
                }
              }

          }
       }

     }
     var resData = await this.$apollo.mutate({
       mutation: gqLQueryList,
       variables: {
         where_key:where ,
         offset:offset,
         limit:limit,
         orderBy:{created_at: 'desc'}
       }
     });
     var listData = resData.data[this.model.tableName];

     var gqLQueryCount = DataServices.countData(this.model.tableName);
     var countRes = await this.$apollo.mutate({
       mutation: gqLQueryCount,
       variables: {
         where_key:where
       }
     });
     var total = countRes.data[this.model.tableName+'_aggregate']['aggregate']['count'];

     var dataRes = {
       listData: listData,
       total: total,
     };
    //  console.log('dataRes:',dataRes);
     return dataRes;
    },
    onLazyEvent() {
      this.lazyParams.filters = this.filtersCustom;
      // console.log('this.lazyParams:',this.lazyParams)
      this.loading = true;
      var that =this;
      this.dataList(this.lazyParams).then(async data => {
        if (data.listData.length < 1) {
          that.listDataService = data.listData;
          that.totalRecords = data.total;
          that.loading = false;
        }
        var stt = this.lazyParams.first ?? 0;
        for (var i = 0; i < data.listData.length; i++) {
          stt++;
          data.listData[i].stt = stt;
          data.listData[i].who_confirmed = data.listData[i]?.view_history_visit_confirm?.created_by_obj?.fullname || "-";
          data.listData[i].property_code = data.listData[i]?.property?.code || "-";
          data.listData[i].property_name = data.listData[i]?.property?.name || "-";
          data.listData[i].visit = data.listData[i]?.visit_start ? `Week ${data.listData[i]?.week_number} (${moment(data.listData[i]?.visit_start).utc().format("LL")} ${data.listData[i]?.visit_end ? ' - ' + moment(data.listData[i]?.visit_end).utc().format("LL") : null})` : "-";
          data.listData[i].owner_name = data.listData[i]?.owner?.fullname;
          //data.listData[i].who_stay = data.listData[i]?.is_owner || data.listData[i]?.status == 'ST001' ? data.listData[i]?.user?.fullname : data.listData[i]?.contact_name;
          data.listData[i].who_stay = data.listData[i]?.text_group_uid_fullname_contact_name;
          data.listData[i].updated_name = data.listData[i]?.update_user?.fullname;
          let stay_days = null
          let arrival = data.listData[i]?.check_in_time ? data.listData[i]?.check_in_time : data.listData[i]?.arrival;
          let departure = data.listData[i]?.check_out_time ? data.listData[i]?.check_out_time : data.listData[i]?.departure;
          if (arrival && departure) {
            let departure_tmp = moment(departure).utc();
            let arrival_tmp = moment(arrival).utc();
            let diff = departure_tmp.diff(arrival_tmp, 'days');
            stay_days = (diff+1);
          }
          data.listData[i].arrival = arrival;
          data.listData[i].departure = departure;
          let services = 0;
          if (data.listData[i]?.orders) {
            data.listData[i].orders.map(m => {
              services += m.items.filter(f => f.service?.id).length;
            });
          }
          if (data.listData[i]?.requests) {
            data.listData[i].requests.map(m => {
              services += m.items.filter(f => f.obj_service?.id).length;
            });
          }
          data.listData[i].stay_days = stay_days;
          let people = 0;
          if (data.listData[i]?.user?.role != 'renter') {
            people = Number(data.listData[i]?.children) + Number(data.listData[i]?.adults);
          } else {
            people = data.listData[i].peoples.length;
          }
          data.listData[i].people = people;
          data.listData[i].service = services;
          let reports = data.listData[i]?.reports ? data.listData[i].reports.filter(f => !f.deleted) : [];
          data.listData[i].report = reports.length;
        }
        // console.log(data.listData)
        that.listDataService = data.listData;
        that.totalRecords = data.total;
        that.loading = false;
      });
    },
    onPage(event) {
      this.lazyParams = event;
      this.onLazyEvent();
    },
    async onFilter(event,type_input,key){
      this.tableData = this.test;
      if(type_input == "DateRange"){
        if(this.filters[key] !== null && this.filters[key][1]== null){
          return false;
        }
      }
      this.filtersCustom[key] = await this.$CoreService.convertFilter(type_input,key,this.filters);
      this.lazyParams.first = 0;
      this.onLazyEvent()
    },
  }
};
</script>
<style lang="scss">
.p-paginator-bottom{padding: 0 !important;}
.p-inputtext{
  width: 100%;
}
.p-button-label{
  color : white;
}
.list-datatable-visit tbody tr {
  cursor: pointer;
}
</style>