<script>
import DataServices from '@/core/DataServices';
import FullCalendar from '@fullcalendar/vue'
import multiMonthPlugin from '@fullcalendar/multimonth'
import moment from "moment-timezone";
import Calendar from '@/components/Calendar.vue';

export default {

  components: {
    Calendar,
    FullCalendar // make the <FullCalendar> tag available
  },
  props: {
    events: Array
  },
  data: function() {
    return {
      year: new Date(),
      calendarOptions: {
        initialDate: new Date(),
        plugins: [multiMonthPlugin],
        initialView: 'multiMonthYear',
        multiMonthMinWidth: 150,
        multiMonthMaxColumns: 4,
        headerToolbar: false,
        weekNumbers: true,
        weekends: true,
        firstDay: 1,
        selectable: true,
        editable: true,
        weekText: "",
        events: [
          // {
          //   start: '2025-01-01',
          //   end: '2025-01-10',
          //   display: 'background',
          //   className: 'event-part-01'
          // }
        ]
      },
      yearRange: `${new Date().getFullYear()}:${new Date().getFullYear() + 30}`,
      time_zone_local : moment.tz.guess(true),
      listProperty: [],
      listPropertyAll: [],
      selProperty: null,
      listOwner: [],
      listOwnerAll: [],
      list_part_map: {},
      selOwner: null,
    }
  },
  watch: {
    async "year"() {
      if (this.selProperty) {
        this.$commonFuction.is_loading(true);
        this.$refs.fullCalendar.getApi().gotoDate(this.year);
      }
      await this.loadEvent();
    },
    async "selProperty"() {
      this.$commonFuction.is_loading(true);
      await this.getListOwner();
      await this.loadEvent();
      this.$refs.fullCalendar.getApi().gotoDate(this.year);
    },
    async "selOwner"() {
      this.$commonFuction.is_loading(true);
      await this.loadEvent();
    }
  },
  async created() {
    await this.getListProperty();
    if (this.$route?.query && this.$route.query?.propery_id) {
      var property_tmp = this.listPropertyAll.filter(f => f.id == this.$route.query?.propery_id);
      this.selProperty = property_tmp.length > 0 ? property_tmp[0] : null;
    }
    if (this.$route?.query && this.$route.query?.year) {
      this.year = new Date(this.$route.query?.year);
      // delete this.$route.query?.year;
    }
  },
  // mounted() {
    
  // },
  methods: {
    async loadEvent() {
      if (!this.selProperty?.id) {
        return;
      }
      var where_key = {
        deleted : {_eq : false},
        property_id: {_eq: this.selProperty?.id},
        booking_round: {week: {date_start: {_gte: `${this.year.getFullYear()}-01-01`}, date_end: {_lte: `${this.year.getFullYear()}-12-31`}}}
      }
      console.log("this.selOwner",this.selOwner);
      if (this.selOwner?.id) {
        where_key.booking_round = {...where_key.booking_round,
          uid: {_eq: this.selOwner?.id}
        }
      }
      let {data} = await this.$apollo.query({
        query: DataServices.getList('booking',{"fields" : `id booking_round {id uid part_number part {part} week{date_start date_end}  week_1{week_number date_start date_end} }`}),
        variables: {
          where_key: where_key,
          orderBy:[{created_at : 'desc'}]
        },
        fetchPolicy: "network-only"
      })
      var list = data[Object.keys(data)];
      var event = [];
      list.map(m => {
        if (m.booking_round.length > 0) {
          m.booking_round.map(r => {
            if (r?.week) {
              if (this.selOwner?.id) {
                if(r.uid == this.selOwner?.id) {
                  event.push({
                    start: moment.tz(r.week?.date_start, this.time_zone_local).format("yyyy-MM-DD"),
                    end: moment.tz(r.week?.date_end, this.time_zone_local).add(1, "days").format("yyyy-MM-DD"),
                    display: 'background',
                    className: `event-part-0${r?.part_number}`
                  });
                }
              } else {
                event.push({
                  start: moment.tz(r.week?.date_start, this.time_zone_local).format("yyyy-MM-DD"),
                  end: moment.tz(r.week?.date_end, this.time_zone_local).add(1, "days").format("yyyy-MM-DD"),
                  display: 'background',
                  className: `event-part-0${r?.part_number}`
                });
              }
            }
            //week_1
            if (r?.week_1) {
              if (this.selOwner?.id) {
                if(r.uid == this.selOwner?.id) {
                  event.push({
                    start: moment.tz(r.week_1?.date_start, this.time_zone_local).format("yyyy-MM-DD"),
                    end: moment.tz(r.week_1?.date_end, this.time_zone_local).add(1, "days").format("yyyy-MM-DD"),
                    display: 'background',
                    className: `event-part-0${r?.part_number}`
                  });
                }
              } else {
                event.push({
                  start: moment.tz(r.week_1?.date_start, this.time_zone_local).format("yyyy-MM-DD"),
                  end: moment.tz(r.week_1?.date_end, this.time_zone_local).add(1, "days").format("yyyy-MM-DD"),
                  display: 'background',
                  className: `event-part-0${r?.part_number}`
                });
              }
            }
            
            
          });
        }
      });
      console.log("event", event);
      this.calendarOptions.events = event;
      setTimeout(() => {
        this.$commonFuction.is_loading(false);
      }, 500)
    },
    async getListProperty(){
      var where_key = {
        deleted : {_eq : false},
      }
      let {data} = await this.$apollo.query({
        query: DataServices.getList('property',{"fields" : `id text_group_property code name`}),
        variables: {
          where_key: where_key,
          orderBy:[{created_at : 'desc'}]
        },
        fetchPolicy: "network-only"
      })
      var list = data[Object.keys(data)];
      var list_property = [];
      // console.log("list===",list);
      for (let i = 0; i < list.length; i++) {
        const e = list[i];
        list_property.push({id : e.id, name :e.text_group_property, code: e.code});
      }
      this.listProperty = list_property;
      this.listPropertyAll = list_property;
    },
    
    async SearchProperty(search, loading){
      console.log(loading);
      if(search !== '' && search !== null){
        var where_key = {
          deleted : {_eq : false},
          _or: {
            text_group_property: {_iregex: this.$commonFuction.search(search)},
          }
        };
        let {data} = await this.$apollo.query({
          query: DataServices.getList('property',{"fields" : `id text_group_property code name`}),
          variables: {
            where_key: where_key,
            orderBy:[{created_at : 'desc'}]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_property = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          list_property.push({id : e.id, name :e.text_group_property, code: e.code});
        }
        this.listProperty = list_property;
      }else{
        // cosnole.log("dasdasd")
        this.listProperty = this.listPropertyAll
      }
    },
      
    async getListOwner(){
      if (!this.selProperty?.id) {
        return;
      }
      var where_key = {
        property_id: {_eq: this.selProperty.id}
      }
      let {data} = await this.$apollo.query({
        query: DataServices.getList('property_part',{"fields" : `id uid user{id fullname}`}),
        variables: {
          where_key: where_key,
          orderBy:[{order_booking : 'asc'}]
        },
        fetchPolicy: "network-only"
      })
      var list = data[Object.keys(data)];
      var list_part_map = {};
      var list_owner = [];
      // console.log("list===",list);
      var checkUser = [];
      for (let i = 0; i < list.length; i++) {
        const e = list[i];
        if(!checkUser.includes(e.user.id)) {
          list_owner.push({id : e.user.id, fullname :e.user.fullname});
          checkUser.push(e.user.id);
        }
        list_part_map[i] = e;
      }
      this.list_part_map = list_part_map;
      this.$emit('list_part_map', list_part_map)
      this.listOwner = list_owner;
      this.listOwnerAll = list_owner;
    },
    
    async SearchOwner(search, loading){
      console.log(loading);
      if (!this.selProperty?.id) {
        return;
      }
      if(search !== '' && search !== null){
        var where_key = {
          property_id: {_eq: this.selProperty.id},
          _or: {
            user: {fullname: {_iregex: this.$commonFuction.search(search)}},
          }
        };
        let {data} = await this.$apollo.query({
          query: DataServices.getList('property_part',{"fields" : `id uid user{id fullname}`}),
          variables: {
            where_key: where_key,
            orderBy:[{order_booking : 'asc'}]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_owner = [];
        var checkUser = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          if(!checkUser.includes(e.user.id)) {
            list_owner.push({id : e.user.id, fullname :e.user.fullname});
            checkUser.push(e.user.id);
          }
        }
        this.listOwner = list_owner;
      }else{
        this.listOwner = this.listOwnerAll
      }
    },
  }
}
</script>

<template>
  <div class='demo-app'>
    <div class="row mb-3">
      <div class="col-3">
        <label class="p-0 txt-right txt-required">Year</label>
        <div class="col-12 p-0">
          <!-- <Calendar id="monthpicker"
            class="w-100"
            v-model="year"
            view="month"
            dateFormat="yy"
            :yearNavigator="true"
            :yearRange="yearRange"
            appendTo="body"
            :showIcon="true" /> -->
            <Calendar style="width: 100%" v-model="year" view="year" dateFormat="yy" appendTo="body" :showIcon="true"/>
          </div>
      </div>
      
      <div class="col-3">
        <label class="p-0 txt-right txt-required">Property ID</label>
        <div class="col-12 p-0">
          <v-select title="Property" class="" @search="SearchProperty" placeholder="Choose" v-model="selProperty" :options="listProperty" label="name">
            <span slot="no-options" @click="$refs.select.open = false">
              Empty data
            </span>
          </v-select>
          <input class="input_tmp_validator" id="property">
        </div>
      </div>
      
      <div class="col-3">
        <label class="p-0 txt-right">Owner</label>
        <div class="col-12 p-0">
          <v-select title="Owner" class="" @search="SearchOwner" placeholder="Choose" v-model="selOwner" :options="listOwner" label="fullname"  >
            <span slot="no-options" @click="$refs.select.open = false">
              Empty data
            </span>
          </v-select>
        </div>
      </div>
      
    </div>  
    <FullCalendar
        v-if="year && selProperty"
        class='booking-calendar'
        :options='calendarOptions'
        ref="fullCalendar"
      />
  </div>
</template>

<style>
.event-part-01 {
  background-color: #BBB2F9 !important;
}

.event-part-02 {
  background-color: #F8D8AB !important;
}

.event-part-03 {
  background-color: #BBEFA8 !important;
}

.event-part-04 {
  background-color: #A7C3FA !important;
}

.event-part-05 {
  background-color: #F3B1AF !important;
}
.booking-calendar a{
  color: #666666;
}
.fc-multimonth-month {
    border-right: 15px solid #d0e0ff;
    border-top: 15px solid #d0e0ff;
}
.fc .fc-bg-event {
  opacity: 1 !important;
  border: none !important;
}
.fc-multimonth-month:nth-child(1), .fc-multimonth-month:nth-child(2), .fc-multimonth-month:nth-child(3), .fc-multimonth-month:nth-child(4) {
  border-top: none;
}

.fc-multimonth-month:nth-child(4), .fc-multimonth-month:nth-child(8), .fc-multimonth-month:nth-child(12) {
  border-right: none;
}
.fc-multimonth-multicol {
  border: none !important;
  height: 80% !important;
}
.txt-right {
  font-weight: bold !important;
}
.booking-calendar .fc-multimonth-month {
  width: 25% !important;
}
.booking-calendar .fc-multimonth-month .fc-multimonth-header{
  width: 80% !important;
  margin: auto !important;
  transform: scale(1.25, 1) !important;
}
.booking-calendar .fc-multimonth-month .fc-multimonth-daygrid{
  width: 80% !important;
  margin: auto !important;
  border: 1px solid #ccc !important;
  transform: scale(1.25, 1) !important;
}
</style>