import BaseModel from '../core/BaseModel'
//import gql from 'graphql-tag'
export default class AppoinmentModel extends BaseModel {

    constructor() {
        super({ 
                name: "appoinment",
                tableName: 'appoinment',
                label: "Appointments", 
                default_order: { key: 'updated_at', direction: 'asc' } }, 
            {
            stt: {
                name: "stt",
                label: "No.",
                type: "Computed",
                computed: (data, index) => {
                    data.stt = index + 1;
                },
                // display: { list: true, add: false, edit: false }
            },
            id: { 
                name: 'id', 
                type: "Uuid", 
                label: 'ID', 
                primaryKey: true,
                display: false
            },
            title: { 
                name: 'title',
                display: false
            },
            title_en: { 
                name: 'title_en',
                display: false
            },


            property_id: { 
                name: 'property_id',
                display: false
            },
            date: { 
                name: 'date', 
                display: false
            },
            description_en: { 
                name: 'description_en', 
                display: false
            },
            description_dn: { 
                name: 'description_dn', 
                display: false
            },
            is_use_en: { 
                name: 'is_use_en', 
                display: false
            },
        },)
    }

}