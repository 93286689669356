<template>
  <div class="p-grid">
    <div class="p-col-12">
      <div class="card card-w-title">
        <div class="manager-title">{{ title }}</div>
        <!--{{listData}}-->
        <BaseDatatable :key="componentKey" :tableData="listData" :model="dataModel" :getFilterOptions="getFilterOptions" @onRowSelect="onRowSelect"
          :actions="getRowActions()" :whereKeyList="whereKeyList" v-on:action-click="onActionClicked"></BaseDatatable>
        <!-- <Popup
          :key="editDialogKey"
          :show="displayDialog"
          :objKey="editId"
          :objModelName="modelName"
          :mode="dialogMode"
          @onChange="onChange()"
        ></Popup> -->

        <Dialog header="Delete" :visible.sync="showDeleteDialog" :contentStyle="{ overflow: 'visible' }" :modal="true">
          Do you want to delete <strong>{{ this.deleteName }}</strong>?
          <template #footer>
            <Button label="Cancel" @click="showDeleteDialog = false" icon="pi pi-times" class="p-button-warning" />
            <Button label="Confrim" @click="deleteData()" icon="pi pi-check"
              class="p-button-danger  btn-confirm-delete-dialog" />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import DataServices from '@/core/DataServices'
import BaseDatatable from './list'
//import gql from "graphql-tag";
// import Popup from './popup'
export default {
  components: {
    BaseDatatable,
    // Popup
  },
  data() {
    return {
      filterOptions: {},
      listData: [],
      modelName: '',
      dataModel: null,
      title: '',
      componentKey: 0,
      editId: null,
      displayDialog: false,
      editDialogKey: 0,
      dialogMode: 'edit',
      deleteId: null,
      deleteName: null,
      showDeleteDialog: false,
      whereKeyList: {
        where_key: {
          /*accounts: {
            role: {_eq: "admin"},
            deleted: {_eq: false}
          },*/
          deleted: { _eq: false }
        }
      },
      page_transaction: null,
      role: {
        view: false,
        add: false,
        edit: false,
        delete: false,
      }
    }
  },
  mounted() {
    this.initParams();
  }
  ,
  async created() {
    this.modelName = this.$route.meta.model;
    this.dataModel = DataServices.getModel(this.modelName);
    //console.log('this.dataModel:',this.dataModel);
    this.title = this.dataModel.name
    this.$store.commit('setLoading', true);
    if (this.$route.params.reload) {
      window.location.reload();
    }
    var arr_role = await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_PERMISSION);
    if (arr_role.indexOf(this.$constants.ROLE.VIEW) < 0) {
      this.$router.push({ path: this.$constants.URL_ERR.FOBIDDEN });
    }
    this.role.add = (arr_role.indexOf(this.$constants.ROLE.ADD) < 0) ? false : true;
    this.role.edit = (arr_role.indexOf(this.$constants.ROLE.EDIT) < 0) ? false : true;
    this.role.delete = (arr_role.indexOf(this.$constants.ROLE.DELETE) < 0) ? false : true;
  },
  watch: {
    '$route': 'initParams'
  },
  methods: {
    onRowSelect(data) {
      if (this.role.edit) {
        this.$router.push({path: `/admin/list-permission/edit/${data.id}`, query: { page_transaction: (new Date()).getTime().toString() }});
        console.log("data", data.id);
      }
    },
    initParams() {
      this.$store.commit('setLoading', true);
      this.modelName = this.$route.meta.model;
      this.dataModel = DataServices.getModel(this.modelName);
      this.title = this.dataModel.label;
    },
    onChange() {
      this.$store.commit('setLoading', true);
      this.$apollo.queries.listData.refresh()
    },
    getFilterOptions(name) {
      var field = this.dataModel.getField(name)
      var result = []
      if (field.type == 'Selection') {
        result = field.options;
      } else {
        result = this.filterOptions[name] != null ? [...this.filterOptions[name]] : []
      }
      return result;
    },
    getRowActions() {
      var actions = {}
      var that = this;
      if (this.role.add) {
        actions.header = { name: 'add', icon: 'plus', path: `list-permission/add` };
      }
      // actions.header = { name: 'add', icon: 'plus'};
      actions.rows = function (rowData) {
        var buttons = {}
        //buttons.view = { name: 'view', icon: 'eye', key: 'id', path: `list-${that.modelName}/view/`};
        if (that.role.edit) {
          buttons.edit = { name: 'edit', icon: 'pencil', key: 'id', path: `list-permission/edit/` };
        }
        // buttons.edit = { name: 'edit', icon: 'pencil', key: 'id', path: `${that.modelName}/edit/`}
        if (that.role.delete) {
          buttons.delete = { name: 'delete', icon: 'trash', key: 'id' }
        }
        if (that.dataModel.actions) {
          var modelaction = that.dataModel.actions(rowData)
          Object.assign(buttons, modelaction);
        }
        return buttons
      }
      return actions
    },
    async onActionClicked(payload) {
      var action = payload.action
      var data = null
      if (payload.data) {
        data = payload.data
      }
      var pageTransaction = (new Date()).getTime();
      switch (action.name) {
        case 'view':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            this.$router.push({ path: action.path + data.data[action.key] })
          } else {
            this.editId = data.data[action.key]
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'edit':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            console.log("payload.lazyParams:111" + pageTransaction, payload.lazyParams);
            localStorage.setItem(pageTransaction.toString(), JSON.stringify(payload.lazyParams));
            this.$router.push({ path: action.path + data.data[action.key], query: { page_transaction: pageTransaction.toString() } })
          } else {
            this.editId = data.data[action.key]
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'delete':
          localStorage.setItem(pageTransaction.toString(), JSON.stringify(payload.lazyParams));
          this.page_transaction = pageTransaction;
          this.deleteId = data.data[action.key]
          this.deleteName = data.data.name
          this.showDeleteDialog = true
          break
        case 'add':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            this.$router.push({ path: action.path })
          } else {
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'custom':
          await action.handler(data.data, this)
          this.componentKey++
          break
      }
    },
    async deleteData() {
      this.$commonFuction.is_loading(true);
      //this.tableAction
      var that = this;
      var where_gu = { user: { active: { _eq: true }, deleted: { _eq: false } }, group_id: { _eq: this.deleteId } };
      var obj_group_user = await this.$CoreService.getObjectData('group_user', where_gu);
      if (obj_group_user) {
        alert("You can't delete! The permission group that exists in the system user");
        this.showDeleteDialog = false;
        that.$commonFuction.is_loading(false);
        return false;
      }
      var objects = {
        deleted: true
      }
      let variables = {
        'data_update': objects,
        'where_key': {
          'id': { '_eq': this.deleteId }
        }
      };
      this.$apollo.mutate({
        mutation: DataServices.updateData('group'),
        variables: variables
      }).then(async () => {
        that.showDeleteDialog = false;
        that.$commonFuction.is_loading(false);
        that.$toast.add({ severity: 'success', summary: 'Success', detail: 'Delete data successly', life: 3000 })
        that.componentKey++;
      }).catch((error) => {
        console.error('ERROR: ', error)
        that.$toast.add({
          severity: 'error',
          summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
          detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
          life: 3000
        })
        that.$commonFuction.is_loading(false);
      });
    },
  },
  /*apollo: {
    listData: {
      query() {
        this.modelName = this.$route.meta.model;
        console.log('8*************************',this.modelName);
        return DataServices.getList(this.modelName);
      },
      variables () {
        // Use vue reactive properties here
        return this.whereKeyList;
      },
      update(data) {
        console.log('this.dataModel.tableName',data);
        var listData = data[this.dataModel.tableName]
        this.title = this.dataModel.label + ' '
        this.dataModel.processData(listData)
        this.$store.commit('setLoading', false);
        this.componentKey += 1;
        console.log('data[this.dataModel.tableName]',data[this.dataModel.tableName]);
        return data[this.dataModel.tableName]
      },
      fetchPolicy: 'network-only',
      error() {
        this.$store.commit('setLoading', false);
      },
      skip() {
        return !this.modelName
      }
    }
  }*/

}
</script>
<style lang="css">
.p-calendar .p-datepicker {
  width: 280px;
  padding: 0px;
  padding-top: 5px;
}

.p-calendar .p-datepicker td,
.p-calendar .p-datepicker td a {
  padding: 0px !important;
}

.p-calendar .p-datepicker td span {
  padding: 0px !important;
  width: 30px !important;
  height: 30px !important;
}

.p-datepicker .p-datepicker-header {
  padding: 5px !important;
}
</style>