import BaseModel from '../core/BaseModel'
export default class ReportCategoryLangModel extends BaseModel {

    constructor() {
        super({
                name: "recommendation_category_lang",
                tableName: 'recommendation_category_lang',
                label: "recommendation_category_lang",
            },
            {
                stt: {
                    name: "stt",
                    label: "No.",
                    type: "Computed",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                },
                id: {
                    name: 'id',
                    type: "Uuid",
                    label: 'ID',
                    primaryKey: true,
                    display: false
                },
                id_lang: {
                    name: 'id_lang',
                    type: "String",
                    label: 'id_lang',
                },
                recommendation_category_id: {
                    name:'recommendation_category_id',
                    label:'Document category',
                   
                },
                description: {
                    name:'description',
                    label:'Property',
                },
                name : {
                    name:'name',
                    label:'Name',
                },
            })
    }

}