import BaseModel from '../core/BaseModel'
import PropertyTypeModel from '@/models/PropertyTypeModel'
export default class PropertyModel extends BaseModel {

    constructor() {
        super({
                name: "property",
                tableName: 'property',
                label: "Property management",
            display_key: 'name'
            },
            {
                stt: {
                    name: "stt",
                    label: "No.",
                    type: "Computed",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                },
                id: {
                    name: 'id',
                    type: "Uuid",
                    label: 'ID',
                    primaryKey: true,
                    display: false
                },
                avatar: {
                    name: "avatar",
                    label: "Image",
                    custom: 'image',
                    type: "Computed",
                    computed: (data, index) => {
                        data.avatar = index + 1;
                    },
                    //display: false,
                },
                type_id_key: {
                    name:'type_id_key',
                    label:'Type',
                    type: "Selection",
                    custom: 'SelectionText',
                    models: new PropertyTypeModel(),
                    filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                    class:"small-col",
                    width: 8,
                    computed: (data) => {
                        data.type_id_key = '';
                    },
                },
                code: {
                    name: 'code',
                    type: "String",
                    label: 'Property ID',
                    width: 15,
                    class:"small-col",
                    filter: {type: 'input', match: 'contains'},
                },
                name: {
                    name: 'name',
                    type: "String",
                    label: 'Property name',
                    width: 15,
                    class:"medium-col",
                    filter: {type: 'input', match: 'contains'},
                },
                country_key: {
                    name: "country_key",
                    label: "Country",
                    type: "Computed",
                   // width: 15,
                    class:"small-col",
                    filter: {type: 'input', match: 'contains'},
                    computed: (data) => {
                        data.country_key ='' ;
                    },
                },
                city_key: {
                    name: "city_key",
                    label: "City",
                    type: "Computed",
                    width: 15,
                    class:"small-col",
                    filter: {type: 'input', match: 'contains'},
                    computed: (data) => {
                        data.city_key ='' ;
                    },
                },
                own_key: {
                    name: "own_key",
                    label: "Owner",
                    type: "Computed",
                  //  width: 15,
                  width: 8,
                    class:"small-col",
                    //filter: {type: 'input', match: 'contains'},
                    computed: (data) => {
                        data.own_key ='' ;
                    },
                },
                active: {
                    name: "active",
                    label: "Active",
                    type: "Selection",  
                    custom: 'SelectionText',
                    options: [
                        {value: 'true', label: 'Yes', style: 'color:  #000000;'},
                        {value: 'false', label: 'No',style: 'color:  #000000;'}
                    ],
                    filter: {
                        type: 'select',
                        optionLabel: 'label',
                        optionKey: 'value',
                        match: 'equals'
                    },
                    width: 8,
                },
                updated_by_key: {
                    name: "updated_by_key",
                    label: "Updated by",
                    type: "Computed",
                    computed: (data) => {
                        data.updated_by_key ='' ;
                    },
                    width: 8,
                },
                updated_at : {
                    name: 'updated_at',
                    type: "Datetime",
                    label: 'Updated date',
                    width: 8,
                    //filter: { type: 'DateRange', match: 'contains'}
                },
                detail_get: {
                    refs:  'obj_updated_by { id fullname }  property_type { id name }  obj_country { id name }  obj_state { id name } part { id uid property_id part order_booking color } admin_property_list { id uid property_id }   lang_arr { id id_lang description property_id } ',
                    display: false
                },
                detail_get1: {
                    refs:  'text_group_property is_use_en address active exchange_same_property exchange_member rent_same_property_price rent_same_property thirdparty_price rent_for_thirdparty longtitude latitude state_id country_id car_garage acreage bathroom bedroom outside type_id part_aggregate { aggregate { count } } ',
                    display: false
                },

            })
    }

}