import moment from "moment-timezone";
export default {
    getConstans() {
        return {
            DEFAULT_ID_LANG: 1,
            DEFAULT_TIMEZONE: "Asia/Ho_Chi_Minh",
            DB_TIMEZONE: "UTC",
            FORMAT_DATE_TIME_CUSTOM: "HH:mm:ss DD/MM/YYYY",
            FORMAT_DATE_DISPLAY: "DD/MM/YYYY",
            FORMAT_DATE_SEVER: "YYYY-MM-DD",
            FORMAT_DATE_TIME_PRIMEVUE: "DD/MM/YYYY HH:mm:ss",
            CURRENT_TIMEZONE_CLIENT_UTC: moment().tz("UTC").format('YYYYMMDDHHmmss'),
            TYPE_GIFT_CARD: 'card',
            IS_OTP_ACTIVED: 2,
            ACTION_KEY_NOTI:{
                /*RECHARGE:"RECHARGE",//Nạp tiền
                WITHDRAWAL:"WITHDRAWAL",//yêu cầu rút
                APPOINTMENT:"APPOINTMENT",//lịch hẹn
                RECEIVING_APPOINTMENT:"RECEIVING_APPOINTMENT",//push nhận cuốc hẹn cấp cứu khẩn cấp
                CLOSE_SEARCH_APPOINTMENT:"CLOSE_SEARCH_APPOINTMENT",//Key gửi push đến client để đóng màn hình tìm kiếm chuyển sang detail
                WALLET_TRANSACTION:"WALLET_TRANSACTION",//Key gửi push đến màn hình lịch sử ví tiền
                CHANGE_STATUS_ACCOUNT:"CHANGE_STATUS_ACCOUNT",//Key gửi push duyệt và khóa tài khoản
                ORDER:"ORDER",//Key gửi push ORDER*/
                ADMIN_NOTIFICATION:"ADMIN_NOTIFICATION",
                BOOKING:"BOOKING",
                BOOK_SUCCESS:"BOOK_SUCCESS",
                ADMIN_CHOOSE_WEEK:"ADMIN_CHOOSE_WEEK",
                RENT_COMPLETE:"RENT_COMPLETE",
                RENT_REJECT:"RENT_REJECT",
                //RENT_SAME_PROPERTY:"RENT_SAME_PROPERTY",
                EXCHANGE_SUCCESS:"EXCHANGE_SUCCESS",
                EXCHANGE_REJECT:"EXCHANGE_REJECT",
                EXCHANGE_SAME_PROPERTY:"EXCHANGE_SAME_PROPERTY",
                RENT_SAME_PROPERTY:"RENT_SAME_PROPERTY",
                REPORT_DETAIL:"REPORT_DETAIL",
                NEED_TO_CONFIRM_ACCEPT:"NEED_TO_CONFIRM_ACCEPT",
                RESPONSE_EXCHANGE:"RESPONSE_EXCHANGE",
                CANCLE_AVAILABLE:"CANCLE_AVAILABLE",
                BOOKING_ROUND_DETAIL:"BOOKING_ROUND_DETAIL",
                PROPERTY_DETAIL:"PROPERTY_DETAIL",
        
            },
            MSG_ERROR: {
                INVALID_TOKEN_ERR: "INVALID_TOKEN_ERR",
                MISMATCH_PARAMS_ERR: "MISMATCH_PARAMS_ERR",
                INVALID_CURRENT_PASSWORD_ERR: "INVALID_CURRENT_PASSWORD_ERR",
                PASSWORD_NEW_NOT_SAME_PASSWORD_CURRENT_ERR: "PASSWORD_NEW_NOT_SAME_PASSWORD_CURRENT_ERR", // password mới không đucợ giống password cũ
                ACCOUNT_IS_NOT_EXISTED_ERR: "ACCOUNT_IS_NOT_EXISTED_ERR",
                ACCOUNT_IS_DEACTIVE_ERR: "ACCOUNT_IS_DEACTIVE_ERR",
            },
            MSG_TEXT: {
                TITLE_SUCCESS_DIALOG: 'Success',
                TITLE_ERR_DIALOG: 'Error',
                UPDATE_SUCCESS_DIALOG: 'Update successful',
                INPUT_INVALID_MSG: 'This field is not empty!',
                SELECT_INVALID_MSG: 'Please select',
                FILE_INVALID_MSG: 'Please select a file',
                VIDEO_PENDING_UPLOAD_MSG: 'Video is being uploaded please waiti...',
                RADIO_BUTTON_INVALID_MSG: 'Vui lòng chọn loại giao dịch',
                IMAGE_INVALID_MSG: 'Please choose an image',
                UPLOAD_IMAGE_FAILURE: 'Image upload failed. Please update again.',
                SELECT_ADDRESS_MSG: 'Vui lòng chọn địa chỉ từ danh sách gợi ý!',
                PHONE_EXIST_SYSTEM_MSG: 'The phone number already exists in the system!',
                INVALID_PHONE: 'Please enter the correct phone number',
                EMAIL_EXIST_SYSTEM_MSG: 'Email already exists in the system!',
                INVALID_EMAIL: 'Please enter the correct email',
                TEXT_EMAIL_VALIDATOR: "Please enter correct email format.",
                PASSWORD_NOT_MATCH_MSG: 'Password does not match!',
                ERR_PLEASE_TRY_AGAIN: 'Please try again',
                INT_MIN_VALIDATE: 'Enter a number that cannot be negative',
                GREATER_THAN_0_VALIDATE: 'Nhập số lớn hơn 0',
                INPUT_MIN_PERCENT_VALIDATE: 'Số không được lớn hơn 100',
                INT_MIN_PECENT_VALIDATE: 'Vui lòng nhập phí hoa hồng không được âm.',
                INT_MAX_PECENT_VALIDATE: 'Vui lòng nhập phí hoa hồng không được lớn hơn 100.',
                WALLET_NOT_ENOUGH_ERR: 'Ví tiền người chăm sóc không đủ nhận lịch hẹn.',
                INVALID_CURRENT_PASSWORD_ERR: 'The current password is incorrect, please re-enter it.',
                PASSWORD_NEW_NOT_SAME_PASSWORD_CURRENT_ERR: "The new password cannot be the same as the current password",
                ACCOUNT_IS_NOT_EXISTED_ERR: "Account does not exist",
                ACCOUNT_IS_DEACTIVE_ERR: "Account not activated",
                SESION_LOGIN_EXPIRED: "Your login session has expired. Please log in again",
            },
            LIST_TYPE_SERVICE: [{
                    value: 1,
                    label: 'Spa & Grooming',
                    style: '',
                    default: true
                },
                {
                    value: 2,
                    label: 'Cấp cứu khẩn cấp',
                    style: ''
                },
                {
                    value: 3,
                    label: 'Khám bệnh',
                    style: ''
                },
                {
                    value: 4,
                    label: 'Pet Hotel',
                    style: ''
                },
                {
                    value: 5,
                    label: 'HL thú cưng + KDT ngắn hạn',
                    style: ''
                },
            ],
            LIST_STATUS_APPOINTMENT: [{
                    value: 1,
                    label: 'Chờ xác nhận',
                    style: '',
                    default: true,
                    status_display: [1, 2, 6]
                },
                {
                    value: 2,
                    label: 'Chờ thực hiện',
                    style: '',
                    status_display: [2, 3, 5]
                },
                {
                    value: 3,
                    label: 'Đang thực hiện',
                    style: '',
                    status_display: [3, 4]
                },
                {
                    value: 4,
                    label: 'Hoàn tất',
                    style: '',
                    status_display: [4, 7]
                },
                {
                    value: 5,
                    label: 'Đã hủy',
                    style: '',
                    status_display: [5]
                },
                {
                    value: 6,
                    label: 'Từ chối',
                    style: '',
                    status_display: [6]
                },
                {
                    value: 7,
                    label: 'Đã thanh toán',
                    style: '',
                    status_display: [7]
                },
            ],
            STATUS_APPOINTMENT: {
                WAIT_CONFIRMATION: 1, //Chờ xác nhận
                WAIT_EXECUTION: 2, //Chờ thự hiện
                PROCESSING: 3, //Đang thực hiện
                COMPLETED: 4, //Hoàn Tất
                CANCELLED: 5, //Đã hủy
                REFUSE: 6, //Từ chối
                PAID: 7, //Đã thanh toán
            },
            LIST_STATUS_WITHDRAWALHISTORY: [{
                    value: 1,
                    label: 'Chờ duyệt',
                    style: '',
                    default: true
                },
                {
                    value: 2,
                    label: 'Đã duyệt',
                    style: ''
                },
                {
                    value: 3,
                    label: 'Từ chối',
                    style: ''
                },
            ],
            LIST_TYPE_WALLET: [{
                    value: 1,
                    label: 'Trả phí hoa hồng dịch vụ',
                    style: '',
                    default: true
                },
                {
                    value: 2,
                    label: 'Nhận thanh toán dịch vụ',
                    style: ''
                },
                {
                    value: 3,
                    label: 'Yêu cầu rút tiền',
                    style: ''
                },
                {
                    value: 4,
                    label: 'Nạp tiền',
                    style: ''
                },
                {
                    value: 5,
                    label: 'Hoàn phí hoa hồng dịch vụ',
                    style: ''
                },
                {
                    value: 6,
                    label: 'Hoàn tiền rút tiền',
                    style: ''
                },
                {
                    value: 7,
                    label: 'Admin thay đổi',
                    style: ''
                },
            ],
            TYPE_WALLET_TRANSACIONT: {
                PAY_FEE_COMMISSION: 1, //Trả phí hoa hồng dịch vụ
                RECEIVE_PAYMENT_SERVICE: 1, //Nhận thanh toán dịch vụ
                WITHDRAWAL_REQUEST: 3, //Yêu cầu rút tiền
                WALLET_RECHARGE: 4, //Nạp tiền
                REFUND_SERVICE_COMMISSIONS: 5, //Hoàn phí hoa hồng dịch vụ
                REFUND_WITHDRAWALS: 6, //Hoàn tiền rút tiền
                ADMIN_CHANGE: 7, //Admin thay đổi
            },
            STATUS_WITHDRAWALH_PENDING: 1,
            STATUS_WITHDRAWALH_APPROVED: 2,
            STATUS_WITHDRAWALH_REFUSE: 3,
            LIST_TYPE_PAYMENT: [{
                    value: 1,
                    label: 'Tiền mặt',
                    style: '',
                    default: true
                },
                {
                    value: 2,
                    label: 'VNPAY',
                    style: ''
                },
                {
                    value: 3,
                    label: 'MOMO',
                    style: ''
                },
            ],
            PAYMENT_METHOD: {
                CASH: 1, //tiền mặt
                VNPAY: 2, //vnpay
                MOMO: 3, //momo
            },
            TYPE_SERVICE: {
                SPA_GROOMING: 1,
                EMERGENCY: 2, //Cấp cứu khẩn cấp
                MEDICAL_EXAMINATION: 3, //Khám bệnh
                PET_HOTEL: 4,
                EDUCATION_TRAINING: 5, //Huấn luyện thú cưng + khóa đào tạo ngắn hạn
            },
            TYPE_IMAGE: {
                USER: 'USER_AVATAR', //avatar user
                BANNER: 'BANNER', //key lưu trailler video
                VIDEO_EN: 'VIDEO_EN', //key lưu url video
                INTRODUCTION: 'INTRODUCTION', //Hình ảnh giới thiệu user
                IMAGE_CLUB: 'IMAGE_CLUB', // hình của club
                LOGO_CLUB: 'LOGO_CLUB', //logo club
                VIDEO_CLUB: 'VIDEO_CLUB',
                VIDEO_IMG_CLUB: 'VIDEO_IMG_CLUB',
                VIDEO_FACILITIES: 'VIDEO_FACILITIES',
                VIDEO_FACILITIES_IMG: 'VIDEO_FACILITIES_IMG',
                IMAGE_FACILITIES: 'IMAGE_FACILITIES',
                FIRST_FRAME_VIDEO_CLUB: 'FIRST_FRAME_VIDEO_CLUB',
                SERVICE: 'ICON_SERVICE',
                NEWS: 'IMAGE_NEWS',
                PROMO: 'IMAGE_PROMO',
                MEDIA: 'IMAGE_MEDIA',
                USER_GALLERY: "USER_GALLERY",
                PROMO_POPUP: "IMAGE_PROMO_POPUP",
                TYPE_SERVICE: "IMAGE_TYPE_SERVICE_ICON",
                TYPE_SERVICE_LOGO: "IMAGE_TYPE_SERVICE",
                ICON_CLUB : 'ICON_CLUB',//logo club
                DOCUMENT : 'document',
                PROPERTY_AVATAR: "AVATAR_PROPERTY",
                PROPERTY: "IMG_PROPERTY",
                IMG_AVARTAR_SERVICE: "IMG_AVARTAR_SERVICE",//lấy ảnh đầu tiên list service làm avartar
                IMG_NORMAL_SERVICE: "IMG_NORMAL_SERVICE",//
                RECOMMENDATION : 'AVARTAR_RECOMMENDATION',
                IMG_NORMAL_RECOMMENDATION: "IMG_NORMAL_RECOMMENDATION",//
                PRODUCT_AVATAR: "AVATAR_PRODUCT",
                PRODUCT_NORMAL: "PRODUCT_NORMAL",
                IMG_PEOPLE: "IMG_PEOPLE",
                PDF_PAYMENT_HISTORY : "PDF_PAYMENT_HISTORY",
                IMG_REPORT: "IMG_REPORT",
                VIDEO_REPORT: "VIDEO_REPORT",

            },
            KEY_UPLOAD_FILE_LARGE: {
                VIDEO: 'VIDEO',
                TRAILER: 'TRAILER',
                VIDEO_EN: 'VIDEO_EN',
            },
            API: {
                TRAN_CONVERT_LANG: '/_api/tranlate/convert-lang',
                EXPORT_WITHDRAWAL: '/_api/excel/export-withdrawal',
                EXPORT_APPOINTMENT_STATISTICAL: '/_api/excel/export-appointment-statistical',
                UPDATE_STATUS_APPOINT: '/_api/appointment/update-status',
                UPDATE_STATUS_WITHDRAWAL: '/_api/withdrawal/update-status',
                UPLOAD_FILE: '_api/common/upload-s3',

                REFESH_TOKEN: '/_api/users/refesh_token_admin',
                CHANGE_PASSWORD: '/_api/users/change-password-admin',
                CHECK_TOKEN_ADMIN: '/_api/users/check-token-admin',
                LOGOUT_ADMIN: '/_api/users/logout-admin',
                CREATE_THUMBNAIL_VIDEO: '/_api/upload-chunk/createdThumbByVideo',
                TRANSFER: '/_api/stripe/transfer',
                SUBMIT_BOOKING: '/_api/booking/admin-submit',
                CHECK_BOOKING: '/_api/booking/admin-check-booking'
            },
            LANGUAGE: {
                EN: 'en',
            },
            LIST_DAY_OF_WEEK: {
                "1": 'Thứ 2',
                "2": 'Thứ 3',
                "3": 'Thứ 4',
                "4": 'Thứ 5',
                "5": 'Thứ 6',
                "6": 'Thứ 7',
                "0": 'Chủ Nhật',
            },
            FOLDER_S3: {
                USER: 'user',
                IMG_VIDEO: 'img_video',
                SERVICE_EDUCATION_TRAINNING: 'education_trainning',
                STORE: 'store',
                BANNER: 'banner',
                FEE: 'fee',
                BANKS: 'banks',
                CLUB: 'club',
                VIDEO: 'video',
                FACILITY: 'facility',
                SERVICE: 'service',
                NEWS: 'news',
                DOCUMENT: 'document',
                PROMO: 'promo',
                MEDIA: 'media',
                RECOMMENDATION : 'recommendation',
                PDF_PAYMENT_HISTORY : "PDF_PAYMENT_HISTORY",
                REPORT_MANAGER: "REPORT_MANAGER",
            },
            SETTING: {
                ATTENDANCE_ADDRESS: 'ATTENDANCE_ADDRESS',
                LATTITUDE_ADDRESS: 'LATTITUDE_ADDRESS',
                LONGITUDE_ADDRESS: 'LONGITUDE_ADDRESS',
                KM_FREE_SHIP: 'KM_FREE_SHIP',
                FEE_SHIP_NEXT: 'FEE_SHIP_NEXT',
            },
            TEXT_BUTTON: {
                ADD: 'Save',
                EDIT: 'Save',
                BACK: 'Cancel',
            },
            KEY_MENU: {
                LIST_USER: 'list_user',
                LIST_RENT: 'list_rent',
                LIST_VIEW_ADMIN: 'list_view_admin',
                LIST_PROPERTY: 'list_property',
                LIST_BOOKING: 'list_booking',
                LIST_APPOINMENTS: 'list_appoinments',
                LIST_DOCUMENT: 'list_document',
                LIST_DOCUMENT_CATEGORY: 'list_document_category',
                LIST_VISIT: 'list_visit',
                LIST_AVAILABLE: 'list_available',
                LIST_RECOMMENDATION: 'list_recommendation',
                LIST_RECOMMENDATION_CATEGORY: 'list_recommendation_category',
                LIST_SERVICE_ORDER: 'list_service_order',
                LIST_REQUEST_ORDER: 'list_request_order',
                LIST_SERVICE: 'list_service',
                LIST_FULL_FILL_SERVICE: 'list_full_fill_service',
                LIST_PAYMENT_HISTORY: 'list_payment_history',
                LIST_NOTIFICATION: 'list_notification',
                LIST_SETTINGS_CHIL: 'list_settings_chil',
                LIST_TERM_OF_USE_POLICY_CHIL: 'list_Term_of_use_Policy_chil',
                LIST_PERMISSION: 'list_permission',
                ASSIGN_USER: 'assign_user',
                LIST_PROPERTY_TYPE: 'list_property_type',

                LIST_MANAGERMENT: 'report_managerment',
                LIST_MANAGERMENT_CATEGORY: 'report_managerment_category',


                // LIST_IPRO_CODE: 'list-ipro_code',
                // LIST_HISTORY_PAYMET: 'history_payment',
                // LIST_SETTING_FEE: 'setting_fee',
                // LIST_STATE: 'list_state',
                // LIST_BANKS: 'list_banks',
                // LIST_CLUBS: 'list_clubs',
                // LIST_FACILITY: 'list_facility',
                // LIST_USER_SUBSCRIPTION: 'list_user_subscription',
                // LIST_ADMIN: 'list_admin',
                // LIST_TYPE_SERVICE: 'type_service',
                // LIST_QUESTION: 'question',
                // LIST_PROMOTION: 'promotion',
                // LIST_NEWS: 'news',
                // LIST_HOLIDAY: 'holiday',
                // LIST_CLUB: 'club',
                // LIST_CLUB_REFISTER: 'club_register',
                // POLICY: 'policy',
                // TERM: 'term',
                // CONTACT: 'contact'
            },
            TYPE_ADMIN: {
                SUPPER_ADMIN: 1,
            },
            ROLE_ACCOUNT:{
                SUPPER_ADMIN:'supper_admin',
                OWNER:'owner',
                RENTER:'renter',
            },
            ROLE: {
                VIEW: 'view',
                ADD: 'create',
                EDIT: 'edit',
                DELETE: 'delete',
            },
            URL_ERR: {
                NOT_FOUNT: '/404',
                FOBIDDEN: '/403',
                REDIRECT: '/redirect',
                LOGIN: '/auth/login',
            },
            LIST_STATUS_USER: [{
                    label: 'Chờ duyệt',
                    value: 1,
                    style: '',
                    default: true,
                    color: '#BC9300'
                },
                {
                    label: 'Đang hoạt động',
                    value: 2,
                    color: '#00973D'
                },
                {
                    label: 'Đã khóa',
                    value: 3,
                    color: '#F5564E'
                },
            ],
            LIST_TYPE_USER_CANCEL: [{
                    label: 'Chủ thú cưng',
                    value: 1,
                    style: '',
                    default: true
                },
                {
                    label: 'Người chăm sóc',
                    value: 2
                },
            ],
            LIST_TYPE_CHANGE: [{
                    label: 'Chủ thú cưng',
                    value: 1,
                    style: '',
                    default: true
                },
                {
                    label: 'Người chăm sóc',
                    value: 2
                },
                {
                    label: 'Admin',
                    value: 3
                },
            ],
            LIST_TYPE_RANK: [{
                    value: 'GOLD',
                    name: 'Vàng'
                },
                {
                    value: 'DIAMOND',
                    name: 'Kim cương'
                },
                {
                    value: 'PLATINUM',
                    name: 'Bạch kim'
                }
            ],
            LANG_NOTIFICATION: {
                VN: {
                    TITLE_ADMIN_CHANGE_WALLET: "Bạn có giao dịch mới",
                    CONTENT_ADMIN_CHANGE_WALLET: "Bạn được cộng #MONEY# vào tài khoản ví. Nội dung: #CONTENT# ",
                    CONTENT_REDUCTION_ADMIN_CHANGE_WALLET: "Bạn bị trừ #MONEY# vào tài khoản ví. Nội dung: #CONTENT# ",
                    TITLE_ADMIN_CHANGE_STATUS: "Tài khoản",
                    CONTENT_ADMIN_CHANGE_STATUS_PENDING: "Tài khoản của bạn đã bị quản trị viên thay đổi trạng thái chờ duyệt. Vui lòng liên hệ để biết thêm chi tiết.",
                    CONTENT_ADMIN_CHANGE_STATUS_APPROVE: "Tài khoản của bạn đã được phê duyệt. Bạn có thể sử dụng các tính năng của PetCare.",
                    CONTENT_ADMIN_CHANGE_STATUS_LOCK: "Tài khoản của bạn đã bị quản trị viên khóa. Vui lòng liên hệ để biết thêm chi tiết.",
                },
                EN: {
                    TITLE_ADMIN_CHANGE_WALLET: "You have a new transaction from the administrator",
                    CONTENT_ADMIN_CHANGE_WALLET: "You are added #MONEY# to your wallet account. Content: #CONTENT# ",
                    CONTENT_REDUCTION_ADMIN_CHANGE_WALLET: "You have #MONEY# deducted from your wallet account. Content: #CONTENT# ",
                    TITLE_ADMIN_CHANGE_STATUS: "Account",
                    CONTENT_ADMIN_CHANGE_STATUS_PENDING: "Your account has been changed by administrator pending approval. Please contact us for more details.",
                    CONTENT_ADMIN_CHANGE_STATUS_APPROVE: "Your account has been approved. You can use the PetCare features.",
                    CONTENT_ADMIN_CHANGE_STATUS_LOCK: "Your account has been locked by an administrator. Please contact us for more details.",
                }

            },
            TYPE_NOTI: {
                USER: "USER",
                NCS: "NCS",
                CTC: "CTC",
            },
            // ACTION_KEY_NOTI: {
            //     WITHDRAWAL: "WITHDRAWAL", //yêu cầu rút
            //     APPOINTMENT: "APPOINTMENT", //lịch hẹn
            //     RECEIVING_APPOINTMENT: "RECEIVING_APPOINTMENT", //push nhận cuốc hẹn cấp cứu khẩn cấp
            //     CLOSE_SEARCH_APPOINTMENT: "CLOSE_SEARCH_APPOINTMENT", //Key gửi push đến client để đóng màn hình tìm kiếm chuyển sang detail
            //     WALLET_TRANSACTION: "WALLET_TRANSACTION", //Key gửi push đến màn hình lịch sử ví tiền
            //     CHANGE_STATUS_ACCOUNT: "CHANGE_STATUS_ACCOUNT", //Key gửi push duyệt và khóa tài khoản
            // },
            LIST_OPTION_COMMON: {
                TEXT_TOTAL_ITEM: 'Total row:',
                PER_PAGE_OPTIONS: [10, 20, 50],
                PAGINATOR_TEMPLATE: 'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
                PAGINATION_ROWS_DEFAULT: 20,
            },
            LIST_ERROR_STRIPE: {
                account_country_invalid_address: "The country of the business address provided does not match the country of the account. Businesses must be located in the same country as the account.",
                account_error_country_change_requires_additional_steps: "Your account has already onboarded as a Connect platform. Changing your country requires additional steps. Please reach out to Stripe support for more information.",
                account_information_mismatch: "Some account information mismatches with one another. For example, some banks might require that the business_profile.name must match the account holder name.",
                account_invalid: "The account ID provided as a value for the Stripe-Account header is invalid. Check that your requests are specifying a valid account ID.",
                account_number_invalid: "The bank account number provided is invalid (e.g., missing digits). Bank account information varies from country to country. We recommend creating validations in your entry forms based on the bank account formats we provide.",
                acss_debit_session_incomplete: "The ACSS debit session is not ready to transition to complete status yet. Please try again the request later.",
                alipay_upgrade_required: "This method for creating Alipay payments is not supported anymore. Please upgrade your integration to use Sources instead.",
                amount_too_large: "The specified amount is greater than the maximum amount allowed. Use a lower amount and try again.",
                amount_too_small: "The specified amount is less than the minimum amount allowed. Use a higher amount and try again.",
                api_key_expired: "The API key provided has expired. Obtain your current API keys from the Dashboard and update your integration to use them.",
                authentication_required: "The payment requires authentication to proceed. If your customer is off session, notify your customer to return to your application and complete the payment. If you provided the error_on_requires_action parameter, then your customer should try another card that does not require authentication.",
                balance_insufficient: "The transfer or payout could not be completed because the associated account does not have a sufficient balance available. Create a new transfer or payout using an amount less than or equal to the account's does not available balance.",
                bank_account_bad_routing_numbers: "The bank account is known to not support the currency in question.",
                bank_account_declined: "The bank account provided can not be used to charge, either because it is not verified yet or it is not supported.",
                bank_account_exists: "The bank account provided already exists on the specified Customer object. If the bank account should also be attached to a different customer, include the correct customer ID when making the request again.",
                bank_account_restricted: "The customer’s account cannot be used with the payment method.",
                bank_account_unusable: "The bank account provided cannot be used. A different bank account must be used.",
                bank_account_unverified: "Your Connect platform is attempting to share an unverified bank account with a connected account.",
                bank_account_verification_failed: "The bank account cannot be verified, either because the microdeposit amounts provided do not match the actual amounts, or because verification has failed too many times.",
                billing_invalid_mandate: "The Subscription or Invoice attempted payment on a PaymentMethod without an active mandate. In order to create Subscription or Invoice payments with this PaymentMethod, it must be confirmed on-session with a PaymentIntent or SetupIntent first.",
                bitcoin_upgrade_required: "This method for creating Bitcoin payments is not supported anymore. Please upgrade your integration to use Sources instead.",
                card_decline_rate_limit_exceeded: "This card has been declined too many times. You can try to charge this card again after 24 hours. We suggest reaching out to your customer to make sure they have entered all of their information correctly and that there are no issues with their card.",
                card_declined: "The card has been declined. When a card is declined, the error returned also includes the decline_code attribute with the reason why the card was declined. Refer to our decline codes documentation to learn more.",
                cardholder_phone_number_required: "You must have a phone_number on file for Issuing Cardholders who will be creating EU cards. You cannot create EU cards without a phone_number on file for the cardholder. See the 3D Secure Documenation for more details.",
                charge_already_captured: "The charge you're attempting to capture has already been captured. Update the request with an uncaptured charge ID.",
                charge_already_refunded: "The charge you're attempting to refund has already been refunded. Update the request to use the ID of a charge that has not been refunded.",
                charge_disputed: "The charge you're attempting to refund has been charged back. Check the disputes documentation to learn how to respond to the dispute.",
                charge_exceeds_source_limit: "This charge would cause you to exceed your rolling-window processing limit for this source type. Please retry the charge later, or contact us to request a higher processing limit.",
                charge_expired_for_capture: "The charge cannot be captured as the authorization has expired. Auth and capture charges must be captured within a set number of days (7 by default).",
                charge_invalid_parameter: "One or more provided parameters was not allowed for the given operation on the Charge. Check our API reference or the returned error message to see which values were not correct for that Charge.",
                clearing_code_unsupported: "The clearing code provided is not supported.",
                country_code_invalid: "The country code provided was invalid.",
                country_unsupported: "Your platform attempted to create a custom account in a country that is not yet supported. Make sure that users can only sign up in countries supported by custom accounts.",
                coupon_expired: "The coupon provided for a subscription or order has expired. Either create a new coupon, or use an existing one that is valid.",
                customer_max_payment_methods: "The maximum number of PaymentMethods for this Customer has been reached. Either detach some PaymentMethods from this Customer or proceed with a different Customer.",
                customer_max_subscriptions: "The maximum number of subscriptions for a customer has been reached. Contact us if you are receiving this error.",
                debit_not_authorized: "The customer has notified their bank that this payment was unauthorized.",
                email_invalid: "The email address is invalid (e.g., not properly formatted). Check that the email address is properly formatted and only includes allowed characters.",
                expired_card: "The card has expired. Check the expiration date or use a different card.",
                idempotency_key_in_use: "The idempotency key provided is currently being used in another request. This occurs if your integration is making duplicate requests simultaneously.",
                incorrect_address: "The card's address is incorrect. Check the card's address or use a different card.",
                incorrect_cvc: "The card's security code is incorrect. Check the card's security code or use a different card.",
                incorrect_number: "The card number is incorrect. Check the card's number or use a different card.",
                incorrect_zip: "The card's postal code is incorrect. Check the card's postal code or use a different card.",
                instant_payouts_config_disabled: "This connected account is not eligible for Instant Payouts. Ask the platform to enable Instant Payouts.",
                instant_payouts_currency_disabled: "This connected account is not eligible for Instant Payouts in this currency. Ask the platform to enable Instant Payouts in this currency.",
                instant_payouts_limit_exceeded: "You have reached your daily processing limits for Instant Payouts.",
                instant_payouts_unsupported: "This card is not eligible for Instant Payouts. Try a debit card from a supported bank.",
                insufficient_funds: "The customer's account has insufficient funds to cover this payment.",
                intent_invalid_state: "Intent is not in the state that is required to perform the operation.",
                intent_verification_method_missing: "Intent does not have verification method specified in its PaymentMethodOptions object.",
                invalid_card_type: "The card provided as an external account is not supported for payouts. Provide a non-prepaid debit card instead.",
                invalid_characters: "This value provided to the field contains characters that are unsupported by the field.",
                invalid_charge_amount: "The specified amount is invalid. The charge amount must be a positive integer in the smallest currency unit, and not exceed the minimum or maximum amount.",
                invalid_cvc: "The card's security code is invalid. Check the card's security code or use a different card.",
                invalid_expiry_month: "The card's expiration month is incorrect. Check the expiration date or use a different card.",
                invalid_expiry_year: "The card's expiration year is incorrect. Check the expiration date or use a different card.",
                invalid_number: "The card number is invalid. Check the card details or use a different card.",
                invalid_source_usage: "The source cannot be used because it is not in the correct state (e.g., a charge request is trying to use a source with a pending, failed, or consumed source). Check the status of the source you are attempting to use.",
                invoice_no_customer_line_items: "An invoice cannot be generated for the specified customer as there are no pending invoice items. Check that the correct customer is being specified or create any necessary invoice items first.",
                invoice_no_payment_method_types: "An invoice cannot be finalized because there are no payment method types available to process the payment. Your invoice template settings or the invoice's payment_settings might be restricting which payment methods are available, or you might need to activate more payment methods in the Dashboard.",
                invoice_no_subscription_line_items: "An invoice cannot be generated for the specified subscription as there are no pending invoice items. Check that the correct subscription is being specified or create any necessary invoice items first.",
                invoice_not_editable: "The specified invoice can no longer be edited. Instead, consider creating additional invoice items that will be applied to the next invoice. You can either manually generate the next invoice or wait for it to be automatically generated at the end of the billing cycle.",
                invoice_on_behalf_of_not_editable: "You cannot update the on_behalf_of property of an invoice after the invoice has been assigned a number.",
                invoice_payment_intent_requires_action: "This payment requires additional user action before it can be completed successfully. Payment can be completed using the PaymentIntent associated with the invoice. See this page for more details.",
                invoice_upcoming_none: "There is no upcoming invoice on the specified customer to preview. Only customers with active subscriptions or pending invoice items have invoices that can be previewed.",
                livemode_mismatch: "Test and live mode API keys, requests, and objects are only available within the mode they are in.",
                lock_timeout: "This object cannot be accessed right now because another API request or Stripe process is currently accessing it. If you see this error intermittently, retry the request. If you see this error frequently and are making multiple concurrent requests to a single object, make your requests serially or at a lower rate. See the rate limit documentation for more details.",
                missing: "Both a customer and source ID have been provided, but the source has not been saved to the customer. To create a charge for a customer with a specified source, you must first save the card details.",
                no_account: "The bank account could not be located.",
                not_allowed_on_standard_account: "Transfers and payouts on behalf of a Standard connected account are not allowed.",
                out_of_inventory: "One or more line item(s) are out of stock. If more stock is available, update the inventory's orderable quantity and try again.",
                ownership_declaration_not_allowed: "Company ownership declaration is allowed only during account updates and accounts created via account tokens.",
                parameter_invalid_empty: "One or more required values were not provided. Make sure requests include all required parameters.",
                parameter_invalid_integer: "One or more of the parameters requires an integer, but the values provided were a different type. Make sure that only supported values are provided for each attribute. Refer to our API documentation to look up the type of data each attribute supports.",
                parameter_invalid_string_blank: "One or more values provided only included whitespace. Check the values in your request and update any that contain only whitespace.",
                parameter_invalid_string_empty: "One or more required string values is empty. Make sure that string values contain at least one character.",
                parameter_missing: "One or more required values are missing. Check our API documentation to see which values are required to create or modify the specified resource.",
                parameter_unknown: "The request contains one or more unexpected parameters. Remove these and try again.",
            },

            BOOKING_STATUS: {
                ST001: 'No choose',
                ST002: 'Booked',
                ST003: 'Skipped',
                ST004: 'Choosing...',
            },
            VISIT_STATUS: [
                {
                    label: 'Not plan yet',
                    value: 'ST001'
                },
                {
                    label: 'Confirmed',
                    value: 'ST002'
                },
                {
                    label: 'Not using',
                    value: 'ST003'
                },
                {
                    label: 'Rent request',
                    value: 'ST004'
                },
                {
                    label: 'Exchange request',
                    value: 'ST005'
                },
                {
                    label: 'Checked in',
                    value: 'ST006'
                },
                {
                    label: 'Checked out',
                    value: 'ST007'
                }
            ],
            SERVICE_STATUS: [
                {
                    label: 'New',
                    value: 'ST001'
                },
                {
                    label: 'Deliveried',
                    value: 'ST002'
                },
                {
                    label: 'Cancelled',
                    value: 'ST003'
                },
                {
                    label: 'Removed',
                    value: 'ST004'
                },
            ],
            REQUEST_STATUS: [
                {
                    label: 'New',
                    value: 'ST001'
                },
                {
                    label: 'Waiting',
                    value: 'ST002'
                },
                {
                    label: 'Cancelled',
                    value: 'ST003'
                },
                {
                    label: 'Paid',
                    value: 'ST004'
                },
            ],
            AVAILABLE_STATUS: {
                3: [
                    {
                        value: 'ST001',
                        label: 'Rent request'
                    },
                    {
                        value: 'ST002',
                        label: 'Rent accepted'
                    },
                    {
                        value: 'ST003',
                        label: 'Rent cancelled'
                    }
                ],
                2: [
                    {
                        value: 'ST001',
                        label: 'Exchange request'
                    },
                    {
                        value: 'ST002',
                        label: 'Exchange accepted'
                    },
                    {
                        value: 'ST003',
                        label: 'Exhange cancelled'
                    }
                ]
            }
        };
    }
}