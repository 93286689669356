import BaseModel from '../core/BaseModel'
export default class UserModel extends BaseModel {

    constructor() {
        super({
                name: "user_admin",
                tableName: 'users',
                label: "User list",
                display_key: 'fullname'
            },
            {
                stt: {
                    name: "stt",
                    label: "No. ",
                    type: "Computed",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                },
                id: {
                    name: 'id',
                    type: "Uuid",
                    label: 'ID',
                    primaryKey: true,
                    display: false
                },

                avatar: {
                    name: "avatar",
                    label: "Avatar",
                    custom: 'image',
                    type: "Computed",
                    computed: (data, index) => {
                        data.avatar = index + 1;
                    },
                    //display: false,
                },
                fullname: {
                    name: 'fullname',
                    type: "String",
                    label: 'Fullname',
                    class:"small-col-midle ",
                    width: 15,
                    filter: {type: 'input', match: 'contains'},
                },
                email: {
                    name: 'email',
                    type: "String",
                    label: 'Email',
                    class:"small-col-midle ",
                    width: 15,
                    filter: {type: 'input', match: 'contains'},
                },
                full_phone: {
                    name: 'full_phone',
                    type: "String",
                    label: 'Phone number',
                    width: 15,
                    filter: {type: 'input', match: 'contains'},
                    display: false
                },
                is_verify_otp: {
                    name: "is_verify_otp",
                    label: "Verify email",
                    type: "Selection",
                    custom: 'SelectionText',
                    options: [
                        {value: true, label: 'Verified', style: 'color:  #000000;'},
                        {value: false, label: 'Not verified',style: 'color:  #000000;'}
                    ],
                    filter: {
                        type: 'select',
                        optionLabel: 'label',
                        optionKey: 'value',
                        match: 'equals'
                    },
                    class:"center txt-small-col",
                    width: 8,
                },
                total_own_property_key: {
                    name: "total_own_property_key",
                    label: "Property No.",
                    type: "Computed",
                    filter: {type: 'input', match: 'contains'},
                    class:"center txt-small-col",
                    computed: (data) => {
                        data.total_own_property_key = '';
                    },
                },
                list_text_p: {
                    name: "list_text_p",
                    label: "Total part",
                    type: "Computed",
                    width: 30,
                    class:"large-col ",
                    filter: {type: 'input', match: 'contains'},
                    computed: (data) => {
                        data.list_text_p = '';
                    },
                },
                available_from_to: {
                    name: "available_from_to",
                    label: "Availalbe form - to",
                    type: "Computed",
                    filter: { type: 'DateRange', match: 'contains'},
                    computed: (data) => {
                        data.available_from_to = '';
                    },
                    display: false
                },
                detail_get: {
                    refs:  ' prefix_phone_id note bank_account bank address prefix_phone phone available_from available_to  obj_updated_by { id fullname } arr_part(where: {obj_property: {deleted: {_eq: false}}}, order_by: {obj_property: {created_at: desc}}) { id part order_booking obj_property { id name } } ',
                    display: false
                },
                active: {
                    name: "active",
                    label: "Active",
                    type: "Selection",
                    custom: 'SelectionText',
                    options: [
                        {value: 'true', label: 'Yes', style: 'color:  #000000;'},
                        {value: 'false', label: 'No',style: 'color:  #000000;'}
                    ],
                    filter: {
                        type: 'select',
                        optionLabel: 'label',
                        optionKey: 'value',
                        match: 'equals'
                    },
                    class:"center txt-xs-col",
                },
                /*active: {
                    name: "active",
                    label: "Trạng thái",
                    type: "Selection",
                    custom: 'SelectionText',
                    options: [
                        {value: 1, label: 'Active', style: 'color:  #000000;'},
                        {value: 2, label: 'Unactive',style: 'color:  #000000;'},
                        {value: 3, label: 'Delete',style: 'color:  #000000;'}
                    ],
                    filter: {
                        type: 'select',
                        optionLabel: 'label',
                        optionKey: 'value',
                        match: 'equals'
                    },
                    class:"center ",
                },*/
                updated_by_key: {
                    name: "updated_by_key",
                    label: "Updated by",
                    type: "Computed",
                    class:"small-col-midle ",
                    filter: {type: 'input', match: 'contains'},
                    computed: (data) => {
                        data.updated_by_key ='' ;
                    },
                    width: 8,
                },
                updated_at : {
                    name: 'updated_at',
                    type: "Datetime",
                    label: 'Updated date',
                    class:"small-col-midle ",
                    filter: { type: 'DateRange', match: 'contains'},
                    width: 8,
                },
                role: {
                    name: 'role',
                    type: "String",
                    label: 'Type',
                    display: false
                },
            })
    }

}