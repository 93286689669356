<template>
  <div class="demo-app">
    <div class="calendar-select">
      <v-select class="max-width-select" :clearable="false" title="Property" @search="SearchProperty" placeholder="Choose"
        v-model="selectedProperty" @input="filterEvents" :options="filteredProperties"
        :reduce="property_item => property_item.id" label="name">
        <span slot="no-options" @click="$refs.select.open = false">
          Empty data
        </span>
      </v-select>
      <button @click="clearSelection" class="btn btn-primary button-clear-select-appointment">clear select</button>
    </div>
    <FullCalendar class="appointment-calendar" :options="calendarOptions" ref="fullCalendar" />
  </div>
</template>
<script>
import DataServices from '@/core/DataServices';
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import moment from "moment-timezone";
export default {
  components: {
    FullCalendar
  },
  data() {
    return {
      time_zone_local: moment.tz.guess(true),
      properties: [],
      filteredProperties: [],
      selectedProperty: '',
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        dayMaxEvents: 3,
        dayMaxEventRows: 2,
        headerToolbar: {
          start: 'prev,next',
          center: 'title',
          end: ''
        },
        events: [],
        eventTimeFormat: {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false
        },
        datesSet: this.getEvent
      }
    };
  },

  methods: {
    async SearchProperty(search, loading) {
      console.log(loading);
      if (search && search.trim()) {
        const where_key = {
          deleted: { _eq: false },
          name: { _iregex: this.$commonFuction.search(search) }
        };
        const list = await this.$CoreService.getListProperty(where_key);
        this.filteredProperties = list.list || [];
      } else {
        this.filteredProperties = this.properties;
      }
    },
    async getProperty() {
      const { data } = await this.$apollo.query({
        query: DataServices.getList('property', { fields: `id name code` }),
        fetchPolicy: "network-only"
      });
      this.properties = data[Object.keys(data)];
      this.filteredProperties = this.properties;
    },
    async getEvent() {
      this.$commonFuction.is_loading(true);
      const currentDate = this.$refs.fullCalendar.getApi().getDate();
      const startOfMonth = moment(currentDate).startOf('month');
      const endOfMonth = moment(currentDate).endOf('month');
      let where_key = {
        deleted: { _eq: false },
        date: { '_gte': startOfMonth, '_lte': endOfMonth }
      };
      if (this.selectedProperty) {
        where_key.property_id = { _eq: this.selectedProperty };
      }
      const { data } = await this.$apollo.query({
        query: DataServices.getList('appoinment', {
          fields: `id title property{id code name} date description_en description_dn`
        }),
        variables: { where_key, orderBy: [{ created_at: 'desc' }] },
        fetchPolicy: "network-only"
      });
      const list = data[Object.keys(data)];
      const events = list.map(m => ({
        id: m.id,
        start: new Date(moment(m.date).utc().format("YYYY-MM-DD HH:mm:ss")),
        display: 'list-item',
        title: `[${m.property.code}] ${m.title}`,
        editable: true,
        url: `list-appoinments/events/edit/${m.id}`,
        textColor: '#1D3118'
      }));
      const diff = endOfMonth.diff(startOfMonth, 'days');
      for (let i = 1; i <= (diff + 1); i++) {
        let day = i < 10 ? `0${i}` : i;
        let addDate = moment(startOfMonth).format(`YYYY-MM-${day}`);
        events.push({
          start: addDate,
          isEnd: true,
          allDay: true,
          title: 'Add appointment',
          display: 'list-item',
          classNames: ['add-appoinments'],
          url: `list-appoinments/events/add?date=${addDate}&property_id=${this.selectedProperty}`
        });
      }
      this.calendarOptions.events = events;
      setTimeout(() => {
        this.$commonFuction.is_loading(false);
      }, 500);
    },
    filterEvents() {
      this.getEvent();
    },
    clearSelection() {
      this.selectedProperty = '';
      this.filterEvents();
    }
  },
  mounted() {
    this.getProperty();
  }
};
</script>
<style>
.toolbar {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}
.appointment-calendar .fc-daygrid-event-dot {
  border-color: #1D76BC !important;
}
.appointment-calendar .fc-daygrid-body.fc-daygrid-body-balanced,
.appointment-calendar .fc-scrollgrid-sync-table,
.appointment-calendar .fc-col-header {
  width: 100% !important;
}
.appointment-calendar .fc-event.fc-daygrid-dot-event {
  background: transparent !important;
  border: none !important;
  color: #1D3118 !important;
}
.appointment-calendar a {
  color: #666666;
}
.fc-more-popover .add-appoinments {
  display: block;
}
.add-appoinments {
  background-color: transparent !important;
  border: none !important;
  text-decoration: underline !important;
  color: #1D76BC !important;
  display: none;
}
.add-appoinments .fc-daygrid-event-dot {
  display: none;
}
.add-appoinments .fc-event-title {
  color: #1D76BC !important;
}
.fc-daygrid-day-frame:hover .fc-daygrid-day-events:not(:has(.fc-daygrid-more-link)) .add-appoinments {
  display: block;
}
.fc-prev-button,
.fc-next-button {
  background-color: #1D76BC !important;
}
.fc-popover-body,
.fc-daygrid-day-events {
  display: flex;
  flex-flow: column nowrap;
}
.fc-popover-body .fc-daygrid-event-harness:has(.add-appoinments),
.fc-daygrid-day-events .fc-daygrid-event-harness:has(.add-appoinments) {
  order: 999999999;
}
.appointment-calendar .fc-daygrid-body {
  width: 100% !important;
  height: 100% !important;
}
.appointment-calendar .fc-daygrid-body .fc-scrollgrid-sync-table {
  height: 100% !important;
}
.calendar-select {
  padding-left: 0px !important;
  display: flex;
}
.button-clear-select-appointment {
  /* margin-top: 10px;
  margin-bottom: 10px; */
  margin-left: 10px !important;
  background-color: #1D76BC;
  border: none !important;
}
.max-width-select {
  width: 30% !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;

}
</style>
