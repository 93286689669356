import BaseModel from '../core/BaseModel'

export default class NotificationsModel extends BaseModel {

    constructor() {
        super({
            tableName: 'notification', label: "Notification", display_key: 'id'
        }, {
            stt: { 
                name: "stt", 
                label: "No.",
                class: 'center', 
                computed: (data, index) => {
                    data.stt = index + 1;
                },
                width: 5,
            },
            id: { 
                name: 'id', 
                type: "Bigint", 
                label: 'ID', 
                primaryKey: true,  
                display: false
            },
            type: {
                name: "type",
                label: "Receiver",
                type: "Selection",
                width : 8,
                class:"medium-col",
                options: [
                    {value: 'ALL', label: 'All users', key : 'ALL',style: 'color:  #000000;'},
                    {value: 'ONE', label: 'Specific user', key : 'USER',style: 'color:  #000000;'},
                    {value: 'MULTIL_PROPERTY', label: 'All owner of property', key : 'USER',style: 'color:  #000000;'},
                    {value: '521_OWNERS', label: '521 owners', key : 'USER',style: 'color:  #000000;'},
                    {value: '3RD_PARTY_OWNERS', label: '3rd party owners', key : 'USER',style: 'color:  #000000;'},
                ],
                filter: {
                  type: 'select',
                  optionLabel: 'label',
                  optionKey: 'value',
                  match: 'equals'
                },
                //display:false
            },
            title : {
                name: 'title',
                type: "Text", 
                label: 'Title',
                class:"medium-col",
                filter: {type: 'input', match: 'contains'},
                computed: (data) => {
                  data.title = '';
                },
            },
            short_content : {
                name: 'short_content',
                type: "Text", 
                label: 'Short description',
                filter: {type: 'input', match: 'contains'},
                class:"large-col",
                computed: (data) => {
                  data.short_content = '';
                },
                //display:false
            },
            created_at: { 
                name: 'created_at', 
                type: "Datetime", 
                width: 15,
                label: 'Created date',
                class:"center txt-small-col-midle ",
                filter: { type: 'DateRange', match: 'contains'},
            },
            is_use_en: {
                name: 'is_use_en',
                type: "Boolean",
              
                display:false
            },
            rel_lang: {
                refs: ` screen action_key webview_url is_push is_admin active deleted uid type_id object_id created_at updated_at id_table multi_uid data app_type clubs_user key_noti multi_property_id   notification_lang { id lang title content short_content id_table id_lang notification_id short_content_noti }`,
                display: false
            },

        })
    }

}