<!--:totalRecords="totalRecords"
:lazy="true"
--><!--:lazy="true"-->
<!--@filter="onFilter($event)"-->
<!--:filters="filters"-->
<!--@filter="onFilter($event)"-->
<!--stateStorage="session"
    stateKey="dt-state-demo-session"-->
<template>
  <DataTable
    :value="listDataService"
    :lazy="true"
    @row-select="onRowSelect"
    class="p-datatable-responsive"
    selectionMode="single"
    data-key="id"
    @page="onPage($event)"
    :paginator="pagination"
    :totalRecords="totalRecords"
    :rows="paginationRows"
    :loading="loading"
    :scrollable="(scrollHeight !== '' ? true : false) "
    :scrollHeight="scrollHeight"
    :autoLayout="true"
    filterMatchMode="startsWith"
    :first="firstRecordIndex"
    :rowsPerPageOptions="$constants.LIST_OPTION_COMMON.PER_PAGE_OPTIONS"
    :currentPageReportTemplate=" $constants.LIST_OPTION_COMMON.TEXT_TOTAL_ITEM+' {totalRecords}'"
    :paginatorTemplate="$constants.LIST_OPTION_COMMON.PAGINATOR_TEMPLATE"
  >
    <Column 
      v-for="c in model.getDisplayFields()"
      :key="c.key"
      :field="getField(c)"
      :header="getLabel(c)"
      :ref="getField(c)"
      :filterMatchMode="getFilterMatch(c)"
      :sortable="getSortable(c)"
      :bodyClass="(c.class ? 'txt-' + c.class : '')"
      :bodyStyle="getHeaderStyle(c)"
      :headerStyle="getHeaderStyle(c)"
      :headerClass="(c.class ? 'txt-' + c.class : '')"
    >
      <template #body="slotProps">
        <!--{{c}}-->
        <img
          v-if="getType(c) == 'Image'"
          :src="getData(c, slotProps.data)"
          style="width: 40px; heigth: 40px"
        />
        <div
          v-else-if="c.type == 'Text'"
          v-html="getData(c, slotProps.data)"
          :style="(c.style ? c.style : '')"
        ></div>
        <div
          v-else-if="getType(c) == 'Custom' || getType(c) == 'Icon'"
          v-html="getData(c, slotProps.data)"
          :style="(c.style ? c.style : '')"
        ></div>
        <div
          v-else-if="getType(c) == 'CustomUrl'"
          v-html="callbackCustomUrl(c, slotProps.data)"
          @click="redirectList(slotProps.data, $event)"
          :style="(c.style ? c.style : '')"
        ></div>

        <Checkbox
          v-else-if="getType(c) == 'Checkbox'"
          v-model="slotProps.data[slotProps.column.field]"
          :binary="true"
          @change="onInputChange($event, slotProps.data, c)"
        />
        <div v-else>
          <div v-if="c.custom">
            <Avatar v-if="c.custom == 'image'" :image="getData(c, slotProps.data)" size="xlarge"  />
          </div>
          <span v-else v-html="getData(c, slotProps.data)" :class="getCustomClass(c, slotProps.data[c.key], slotProps.data)"></span>
        </div>

      </template>
      <template v-if="c.filter" #filter>
        <InputText
          v-if="c.filter.type == 'input'"
          type="text"
          v-model="filters[$CommonList.getFieldFilter(c)]"
          class="p-column-filter"
          :placeholder="$CoreService.getFilterPlaceHolder(c)"
          @keyup="onFilter($event,c.filter.type,c.key)"
        /><!-- :keyup="onFilter(c.filter.type,c.key)"-->
        <!--v-model="filters[getField(c)]"-->
        <Dropdown
          :key="countDropdown"
          v-if="c.filter.type == 'select'"
          v-model="filters[getField(c)]"
          :options="getFilterOptions(c.key)"
          :optionLabel="c.filter.optionLabel"
          :optionValue="c.filter.optionKey"
          :placeholder="$CoreService.getFilterPlaceHolder(c)"
          class="p-column-filter"
          :showClear="true"
          appendTo="body"
        >
          <template #option="slotProps">
            <div class="p-clearfix">
              <span
                :class="getCustomClass(c, slotProps.option[c.filter.optionKey], slotProps.data)"
                >{{ slotProps.option[c.filter.optionLabel] }}</span
              >
            </div>
          </template>
        </Dropdown>
        <Calendar 
          v-if="c.filter.type == 'DateRange'"
          :showOnFocus="false" 
          :monthNavigator="true" 
          :yearNavigator="true" 
          yearRange="2000:2030" 
          dateFormat="MM dd, yy"
          id="date_from" 
          v-model="filters[getField(c)]"
          class="p-column-filter"
          :showIcon="true" 
          selectionMode="range"
          appendTo="body"
          :showClear="true"
          :showButtonBar="true"
          @clear-click="onFilter($event,c.filter.type,c.key)"
          @date-select="onFilter($event,c.filter.type,c.key)"
          :manualInput="false"
          >
          <template #footer>
            <CalendarFooter />
          </template>
        </Calendar>
      </template>
    </Column>
    <Column
      v-if="actions"
      headerStyle="width: 10em; text-align: center"
      bodyStyle="width: 10em; text-align: center"
    >
      <template #body="slotProps">
        <span class="p-column-title">Actions</span>
        {{ slotProps.data.color }}
        <div class="style-action-click">
          <Button
              v-for="(a, k) in actions.rows(slotProps.data)"
              :key="k"
              type="button"
              :icon="getIcon(a.icon)"
              :class="getClass(a.name)"
              :disabled="getDisabled(a.disabled)"
              style="margin: 2px"
              v-on:click="actionsClicked(k, a, slotProps)"
          ></Button>
        </div>
      </template>
      <template #header="slotProps">
        <Button
          v-if="actions.header"
          type="button"
          :icon="getIcon(actions.header.icon)"
          :class="getClass(actions.header.name)"
          v-on:click="actionsClicked(actions.header.name, actions.header, slotProps)"
        ></Button>
      </template>
    </Column>
    <template #empty>
      {{ $t('datatable.empty') }}
    </template>
    <template #loading>
      {{ $t('datatable.loading') }}
    </template>
  </DataTable>
</template>

<script>
import moment from "moment-timezone";
//import gql from "graphql-tag";
import DataServices from "@/core/DataServices";
export default {
  props: {
    model: Object,
    tableData: Array,
    getFilterOptions: Function,
    actions: Object,
    options: Array,
    whereKeyList: Object,
  },
  data() {
    return {
      //filters: {},
      arr_role: [],
      countDropdown: 0,
      pagination: true, // true cho phép phân trang
      paginationRows: this.$constants.LIST_OPTION_COMMON.PAGINATION_ROWS_DEFAULT, // số dòng trên 1 trang
      scrollHeight: '75vh', // chiều cao của trang danh sách
      loading: true,
      rowsPerPageOptions: [10, 20, 50, 100],
      first: 0,
      totalRecords: 120,
      totalRecords2: 12,
      test: null,
      listDataService:null,
      filters: {},
      filtersCustom: [],
      lazyParams: {},
      page_transaction:null,
      firstRecordIndex:0
    };
  },
  async created() {
    console.log("modelmodelmodelmodelmodelmodelmodelmodelmodel:",this.model)
    console.log(this.tableData);
    this.test = this.tableData;
    //this.listDataService = await this.dataList();
  },
  async mounted () {
    this.scrollHeight = `${(window.innerHeight*(window.innerHeight > 900 ? 70 : 55)/100)}px`;
    this.arr_role = await this.$CoreService.checkPermission(this.$constants.KEY_MENU.ASSIGN_USER);
    this.lazyParams = {
      first: 0,
      rows: this.paginationRows,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    //  filtersTmp:this.filters
    };
    if (!this.$commonFuction.isEmpty(this.$route.query)){
      if (!this.$commonFuction.isEmpty(this.$route.query.page_transaction)){
        this.page_transaction = this.$route.query.page_transaction;
        this.lazyParams = (localStorage.getItem(this.page_transaction)) ? JSON.parse(localStorage.getItem(this.page_transaction)) : {};
        console.log("this.lazyParamsthis.lazyParams:"+this.page_transaction,this.lazyParams);
         this.firstRecordIndex = this.lazyParams.first;
        console.log("this.lazyParams:22222222",this.lazyParams);
       /* if (this.lazyParams){
          console.log("this.lazyParams.filters",this.lazyParams.filters);

        }*/
      }
    }
    this.loading = false
    this.countDropdown++;
    this.onLazyEvent();
  },
  methods: {
    onRowSelect(event) {
      this.$emit('onRowSelect', event.data);
    },
    redirectList(data, e) {
      let clickedElId = e.target.id
      console.log(data.id)
      if (clickedElId === 'list-permission-users') {
        var id = data.id;
        var path = `/admin/list-permission/${id}/user`;
        this.$router.push({path: path})
      }
    },
    callbackCustomUrl(c,data){
      var text = ''
      switch(c.key) {
        case 'user':
          if(data?.group_users){
            var count_club = data.group_users.length
            if (this.arr_role.indexOf(this.$constants.ROLE.VIEW) < 0) {
              text = count_club;
            } else {
              text = '<a class="txt-link" id="list-permission-users" :data-id="'+data.id+'">'+count_club+'</a>';
            }
          }else{
            text = 0;
          }
          break;
        default:
          break;
      }
      // this.date_now
      return text;
    },
    select_date(event,col_name) {
      if (this.filters[col_name][0] !== null && this.filters[col_name][1] !== null) {
        this.$emit("filter_date", {
          data: this.filters[col_name],
          col: col_name
        });
      }
    },
    getLabel(col) {
      if (col.label) return col.label;
      else return col.name;
    },
    getType(col) {
      if (col.display?.list?.mode == "custom") {
        return "Custom";
      }
      switch (col.type) {
        case "String":
        case "Text":
        case "Computed":
          if (col.inputOptions?.mode == "Image") {
            return "Image";
          }
          if (col.inputOptions?.mode == "selection") {
            return "Select";
          }
          if (col.inputOptions?.mode == "currency") {
            return "Currency";
          }
          if(col.inputOptions?.mode == 'icon') {
            return "Icon";
          }
          return "Input";
        case "numeric":
        case "ShadowField":
        case "number":
          if (col.inputOptions?.mode == "currency") {
            return "Currency";
          } else if (col.inputOptions?.mode == "percentage") {
            return "Percentage";
          } else {
            return "Number";
          }
        case "Selection":
          return "Select";
        case "Checkbox":
          return "Checkbox";
        default:
          return col.type;
      }
    },
    cannotEdit(col) {
      if (col.edit && col.edit.edit == false) return true;
      return false;
    },
    onInputChange(value, data, f) {
      if (f.onChange)  f.onChange(data).bind(this);
      this.$emit("onDataChange", data, f);
    },
    toggle(event) {
      this.$refs.op.toggle(event);
    },
    getCustomClass(col, data, rowData) {
      if (col.displayClass) return col.displayClass(data, rowData);
      return "";
    },
    getField(col) {
      return col.key;
    },
    getHeaderStyle(col) {
      var style = "";
      if (col.width) style = "width: " + col.width + "em;";
      else if (col.name === "stt" || col.name === "STT") style = "width: 3em;";
      else if (col.type === "image" || col.type === "Image") style = "width: 6em;";
      return style;
    },
    getData(col, row) {
      console.log("colcolcol:",col);
      console.log("rowrowrowrow:",row);
      var value = '';
      if (col.type == "Date" && row[col.key]) {
        // value = moment.tz(row[col.key], "Asia/Ho_Chi_Minh").format("DD/MM/yyyy");
        value = moment.tz(row[col.key], "Asia/Ho_Chi_Minh").format("LL");
      } else if (col.type == "Datetime" && row[col.key]) {
        // value = moment.tz(row[col.key], "Asia/Ho_Chi_Minh").format("HH:mm:ss DD/MM/yyyy");
        value = moment.tz(row[col.key], "Asia/Ho_Chi_Minh").format("LL HH:mm");
      } else if (col.type == "CustomDate" && row[col.key]) {
        value = moment.tz(row[col.key], "Asia/Ho_Chi_Minh").format("HH:mm - DD/MM/yyyy");
      } else if(col.type == "Hour" && row[col.key]){
        value = moment.tz(row[col.key], "Asia/Ho_Chi_Minh").format("HH:mm");
      } else if (col.type == "Selection") {
        var lists = [];
        if (col.options !== undefined) {
          lists = col.options;
        } else if (col.models !== undefined && this.options[col.key] !== undefined) {
          lists = this.options[col.key];
        }
        if (lists && lists.length) {
          var objResult = lists.filter(a => a.value == row[col.key]);
          if (objResult !== undefined && Object.keys(objResult) && Object.entries(objResult).length) {
            value = objResult[Object.keys(objResult)].label;
          }
        }
      } else {
        if (col.type == "Text" && row[col.key] && row[col.key].length > 100) {
          var result = row[col.key].substr(0, row[col.key].lastIndexOf(" ", 100)) + "...";
          value = result;
        }
        if (this.getType(col) == "Currency") {
          if (row[col.key] == null) row[col.key] = 0;
          value = this.formatCurrency(row[col.key]);
        }
        if (this.getType(col) == "Percentage") {
          if (row[col.key] == null) row[col.key] = `0%`;
          value = row[col.key] + "%";
        }
        value = row[col.key];
      }
      if (col.style) value = '<abbr style="' + col.style + '">' + value + '<abbr/>';
      else if (col.options) {
        var filtered = col.options.filter( a => a.value  === row[col.key]);
        if (Object.keys(filtered) && Object.entries(filtered).length && filtered[0].style) {
          value = '<abbr style="' + filtered[0].style + '">' + value + '<abbr/>';
        }
      } 
      return value
    },
    getFilterMatch(col) {
      var filterMatch = "startWith";
      if (col.filter && col.filter.match) {
        filterMatch = col.filter.match;
      }
      return filterMatch;
    },
    getFilterPlaceHolder(col) {
      if (col.filter.placeHolder) {
        return col.filter.placeHolder;
      } else if (col.filter.type == "select") {
        return `Select`;
      } else if (col.filter.type == "input") {
        return `Input`;
      } else {
        return `Input`;
      }
    },
    getSortable(col) {
      return col.sortable && col.sortable == true ? true : false;
    },
    getIcon(iconName) {
      return `pi pi-${iconName}`;
    },
    getDisabled(disabled = false) {
      return disabled;
    },
    actionsClicked(key, action, rowData) {
      this.$emit("action-click", {
        originalEvent: event,
        action: action,
        key: key,
        data: rowData,
        lazyParams:this.lazyParams
      });
    },
    getClass(type) {
      switch (type) {
        case "add":
          return "p-button-add";
        case "delete":
          return "p-button-danger";
        case "edit":
          return "p-button-edit";
        case "adddebt":
          return "p-button-edit"
        case "add-Debt":
          return "p-button-add";
        case "editcustom":
          return "p-button-edit"
        case "addcustom":
          return "p-button-add";
      }
    },
    formatCurrency(value) {
      return value.toLocaleString("vi-VN", {
        style: "currency",
        currency: "VND"
      });
    },
   async dataList(lazyParams) {
      console.log('gggggggggggggggggg:',this.$store.getters.user);
      console.log('lazyParams');
      console.log('lazyParams:',lazyParams);
     var offset = lazyParams.first;
     var limit = lazyParams.rows;
     var gqLQueryList = DataServices.getList(this.model.tableName);
     var where = {
       active: {_eq: true},
       deleted: {_eq: false},
     }
     var filters = lazyParams.filters;
     if (filters){
       for (const [key, value] of Object.entries(filters)) {
         if (value.type_input == 'input'){
           if (!this.$commonFuction.isEmpty(value.value)){
             if (key == 'name'){
               where.name = {_iregex: this.$commonFuction.search(value.value)};
             } else if (key == 'update_name'){
               where.update_user = {fullname: {_iregex: this.$commonFuction.search(value.value)}};
             }else {
               where[key] = {_iregex: this.$commonFuction.search(value.value)};
             }

           }
         }else if (value.type_input == 'DateRange'){
            if(value.value !== undefined && value.value !== null){
              if (key == 'arrival') {
                where.order += {visit: {arrival: {'_gte': value.value.from, '_lte': value.value.to}}}
              } else {
                where[key] = {'_gte': value.value.from,'_lte': value.value.to}
              }
            }
          }
       }

     }
     var resData = await this.$apollo.mutate({
       mutation: gqLQueryList,
       variables: {
         where_key:where ,
         offset:offset,
         limit:limit,
         orderBy:{created_at: 'desc'}
       }
     });
     var listData = resData.data[this.model.tableName];

     var gqLQueryCount = DataServices.countData(this.model.tableName);
     var countRes = await this.$apollo.mutate({
       mutation: gqLQueryCount,
       variables: {
         where_key:where
       }
     });
     var total = countRes.data[this.model.tableName+'_aggregate']['aggregate']['count'];

     var dataRes = {
       listData: listData,
       total: total,
     };
     console.log('dataRes:',dataRes);
     return dataRes;
    },
    onLazyEvent() {
      this.lazyParams.filters = this.filtersCustom;
      console.log('this.lazyParams:', this.lazyParams)
      this.loading = true;
      var that = this;
      this.dataList(this.lazyParams).then(data => {
        console.log("datadata:", data)
       // var count = 0;
        if (data.listData.length < 1) {
          that.listDataService = data.listData;
          that.totalRecords = data.total;
          that.loading = false;
        }
        var stt = this.lazyParams.first ?? 0;
        for (var i = 0; i < data.listData.length; i++) {
          stt++;
          data.listData[i].stt = stt;
          data.listData[i].user = data.listData[i]?.group_users ? data.listData[i].group_users.length : 0;
          data.listData[i].update_name = data.listData[i]?.update_user?.fullname || null;
          that.listDataService = data.listData;
          that.totalRecords = data.total;
          that.loading = false;

        }

      });
    },
    onPage(event) {
      console.log("eventeventeventevent",event);
      this.lazyParams = event;
      this.onLazyEvent();
    },
    async onFilter(event,type_input,key){
      this.tableData = this.test;
      if(type_input == "DateRange"){
        if(this.filters[key] !== null && this.filters[key][1]== null){
          return false;
        }
      }
      this.filtersCustom[key] = await this.$CoreService.convertFilter(type_input,key,this.filters);
      this.lazyParams.first = 0;
      this.onLazyEvent()
    }
  }
};
</script>
<style lang="scss">
.p-paginator-bottom{padding: 0 !important;}
</style>