import BaseModel from '../core/BaseModel'
import DocumentCategoryModel from '@/models/DocumentCategoryModel'
import PropertyModel from '@/models/PropertyModel'
export default class DocumentModel extends BaseModel {

    constructor() {
        super({
                name: "document",
                tableName: 'document',
                label: "documents",
                display_key: 'title'
            },
            {
                stt: {
                    name: "stt",
                    label: "No.",
                    type: "Computed",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                },
                id: {
                    name: 'id',
                    type: "Uuid",
                    label: 'ID',
                    primaryKey: true,
                    display: false
                },
                title: {
                    name: 'title',
                    type: "String",
                    label: 'Title',
                    width: 15,
                    class:"small-col-midle ",
                    filter: {type: 'input', match: 'contains'},
                },
                doc_category_id: {
                    name:'doc_category_id',
                    label:'Document category',
                    type: "Selection",
                    custom: 'SelectionText',
                    models: new DocumentCategoryModel(),
                    filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                    class:"center txt-small-col-midle",
                    computed: (data) => {
                        data.doc_category_id = '';
                    },
                    width: 15,
                },
                property_id: {
                    name:'property_id',
                    label:'Property',
                    type: "Selection",
                    custom: 'SelectionText',
                    models: new PropertyModel(),
                    //filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                    filter: {type: 'input', match: 'contains'},
                    class:"center txt-small-col-midle",
                    width: 15,
                    computed: (data) => {
                        data.doc_category_id = '';
                    },
                },

                detail_get: {
                    refs:  'obj_updated_by { id fullname } ordinal doc_category_id property_id obj_document_category { id name } obj_property { id name }  lang_arr {id id_lang description document_id name }',
                    display: false
                },
                /*ordinal: {
                    name: 'ordinal',
                    type: "String",
                    label: 'Order'
                },*/
                /*active: {
                    name: "active",
                    label: "Hiển thị",
                    type: "Checkbox"
                },*/

                active: {
                    name: "active",
                    label: "Active",
                    type: "Selection",
                    custom: 'SelectionText',
                    options: [
                        {value: 'true', label: 'Yes', style: 'color:  #000000;'},
                        {value: 'false', label: 'No',style: 'color:  #000000;'}
                    ],
                    filter: {
                        type: 'select',
                        optionLabel: 'label',
                        optionKey: 'value',
                        match: 'equals'
                    },
                    class:"center ",
                    width: 8,
                },

                show_only_owner: {
                    name: "show_only_owner",
                    label: "For property owner",
                    type: "Selection",
                    custom: 'SelectionText',
                    options: [
                        {value: 'true', label: 'Yes', style: 'color:  #000000;'},
                        {value: 'false', label: 'No',style: 'color:  #000000;'}
                    ],
                    filter: {
                        type: 'select',
                        optionLabel: 'label',
                        optionKey: 'value',
                        match: 'equals'
                    },
                    class:"center ",
                    width: 10,
                },
                show_member: {
                    name: "show_member",
                    label: "For 521's member ",
                    type: "Selection",
                    custom: 'SelectionText',
                    options: [
                        {value: 'true', label: 'Yes', style: 'color:  #000000;'},
                        {value: 'false', label: 'No',style: 'color:  #000000;'}
                    ],
                    filter: {
                        type: 'select',
                        optionLabel: 'label',
                        optionKey: 'value',
                        match: 'equals'
                    },
                    class:"center ",
                    width: 10,
                },
                show_rent: {
                    name: "show_rent",
                    label: "For external renter",
                    type: "Selection",
                    custom: 'SelectionText',
                    options: [
                        {value: 'true', label: 'Yes', style: 'color:  #000000;'},
                        {value: 'false', label: 'No',style: 'color:  #000000;'}
                    ],
                    filter: {
                        type: 'select',
                        optionLabel: 'label',
                        optionKey: 'value',
                        match: 'equals'
                    },
                    class:"center ",
                    width: 10,
                },
                updated_by_key: {
                    name: "updated_by_key",
                    label: "Updated by",
                    type: "Computed",
                    computed: (data) => {
                        data.updated_by_key ='' ;
                    },
                    class:"small-col-midle ",
                    width: 15,
                },
                updated_at : {
                    name: 'updated_at',
                    type: "Datetime",
                    label: 'Updated date',
                    class:"small-col-midle ",
                    width: 15,
                    //filter: { type: 'DateRange', match: 'contains'}
                },
                is_use_en: { 
                    name: 'is_use_en', 
                    display: false
                },
            })
    }

}