<template>
    <form id="frm_action_part">
      <div class="p-formgrid">
        <div class="description-box text-box">
            <div class="description-items mt-3 pr-0 w-100" v-for="(item, index) in [1,2,3,4,5]" :key="index">
                <div class="col-lg-10 row part-box pr-0">
                    <div class="col-lg-1 p-0 pr-1">
                        <div class="disable-box ordinal">{{item}}</div>
                    </div>
                    <div class="col-lg-1 p-0 pr-1">
                        <div class="color-box" :style="`background-color:${colorArr[index]}`"></div>
                    </div>
                    <div class="col-lg-10 p-0 pr-1">
                        <div class="disable-box">{{partArr[index] && partArr[index].user ? `${partArr[index].user.fullname} - ${partArr[index].user.email} (Owner ${partArr[index].part})` : null}}</div>
                    </div>
                </div>
                <div class="col-lg-2 description-gr-icon pl-0">
                    <Button icon="pi pi-arrow-up" class="p-button-secondary p-button-link" :disabled="index == 0 || !partArr[index] || mode == 'edit'" @click="moveUp(index)"/>
                    <Button icon="pi pi-arrow-down" class="p-button-secondary p-button-link" :disabled="index == (partArr.length-1) || !partArr[index] || mode == 'edit'" @click="moveDown(index)"/>
                </div>
            </div>
        </div>
      </div>
    </form>
  </template>
  
  <script>

  export default {
    name: "packageDescriptions",
    props: {
      value: Array,
      mode: String
    },
    created() {
        if (this.partArr.length > 0) {
            this.sortDescription();
        }
    },
    data() {
      return {
        partArr: this.$props.value ?? [],
        colorArr: [
            '#BBB2F9',
            '#F8D8AB',
            '#BBEFA8',
            '#A7C3FA',
            '#F3B1AF'
        ]
      };
    },
    methods: {
        moveDown(index) {
            if (index != (this.partArr.length - 1)) {
                var ordinal = this.partArr[index].order_booking;
                this.partArr[index].order_booking = this.partArr[index + 1].order_booking;
                this.partArr[index + 1].order_booking = ordinal;
                this.sortDescription();
            }
        },
        moveUp(index) {
            if (index != 0) {
                var ordinal = this.partArr[index].order_booking;
                this.partArr[index].order_booking = this.partArr[index - 1].order_booking;
                this.partArr[index - 1].order_booking = ordinal;
                this.sortDescription();
            }
        },
        sortDescription() {
            if (this.partArr.length > 0) {
                var description = this.partArr.sort((p1, p2) => p1.order_booking - p2.order_booking);
               //var description_tmp = description.map((m, i) => {
                //    var ordinal = (this.partArr.length - i);
                //    m.order_booking = ordinal;
                //   return m;
                //});
                this.partArr = description;
                this.$emit("input", this.partArr);
            }
        },
    }
  };
</script>
  
<style lang="scss">
    .description-items {
        display: flex;
        margin-left: 15px;
        margin-right: 0;
        justify-content: space-between;
    }
    .description-gr-icon {
        display: flex;
        justify-content: flex-end;
        button span{
            color: #B7B7B7;
            font-weight: bold;
        }
    }
    .description-link {
        color: #0086C4;
        cursor: pointer;
        font-style: italic;
    }
    button[disabled="disabled"] span {
        cursor: no-drop;
    }
    .p-button.p-button-link {
        background: transparent !important;
        border: transparent !important;
    }
    .part-box{
        .disable-box {
            height: 48px;
            background-color: #EEEEEE;
            border: 1px solid #D0D4D9;
            color: #666666;
            border-radius: 5px;
            padding: 0 5px;
            display: flex;
            align-items: center; 
            &.ordinal {
                justify-content: center;
            }
        }
        .color-box {
            height: 48px;
            border: 1px solid #D0D4D9;
            border-radius: 5px;
            width: 100%;
        }
    }
</style>
  