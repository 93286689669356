import BaseService from './BaseService'
import UserModel from '../models/UserModel'
import AccountsModel from '../models/AccountsModel'
// import UserUsersModel from '../models/UserUserModel'

import FileModel from "@/models/FileModel";
import RelationshipFileModel from "../models/RelationshipFileModel";

import GroupModel from '../models/GroupModel'
import GroupUserModel from '../models/GroupUserModel'

import NotificationsModel from '../models/NotificationsModel'
import NotificationsLangModel from '../models/NotificationsLangModel'
import ControllerModel from '../models/ControllerModel'
import NotificationUsersModel from '../models/NotificationUsersModel';
import BookingModel from '../models/BookingModel';
import PropertyModel from '../models/PropertyModel';
import PropertyPartModel from '../models/PropertyPartModel';
import PropertyWeekModel from '../models/PropertyWeekModel';
import BookingRoundModel from '../models/BookingRoundModel';
import AppoinmentModel from '../models/AppoinmentModel';
import VisitModel from '../models/VisitModel';
import DocumentCategoryModel from '../models/DocumentCategoryModel';
import DocumentModel from '../models/DocumentModel';
import RentModel from '../models/RentModel';
import TermOfUserPolicyModel from '../models/TermOfUserPolicyModel';
import TermOfUserLangModel from '../models/TermOfUseLang';
import PropertyTypeModel from '../models/PropertyTypeModel';
import CountryModel from '../models/CountryModel';
import StateModel from '../models/StateModel';
import ServiceModel from '../models/ServiceModel';
import LangModel from '../models/LangModel';
import ServicePropertyTypeModel from '../models/ServicePropertyTypeModel';
import ServiceMadatoryModel from '../models/ServiceMadatoryModel';
import SettingsModel from '../models/SettingsModel';
import ServiceOrderModel from '../models/ServiceOrderModel';
import ProductModel from '../models/ProductModel';
import VisitOrderModel from '../models/VisitOrderModel';
import VisitOrderItemProductModel from '../models/VisitOrderItemProductModel';
import RequestOrderModel from '../models/RequestOrderModel';
import VisitRequestModel from '../models/VisitRequestModel';
import AvailableModel from '../models/AvailableModel';

import RecommendationCategoryModel from '../models/RecommendationCategoryModel';
import RecommendationModel from '../models/RecommendationModel';
import RecommendationLangModel from "@/models/RecommendationLangModel";
import RecommendationUsedModel from "@/models/RecommendationUsedModel";
import Full_fill_serviceModel from "@/models/Full_fill_serviceModel";
import FullFillProductModel from "@/models/FullFillProductModel";
import PropertyUserModel from "@/models/PropertyUserModel";
import UserFamilyModel from "@/models/UserFamilyModel";
import VisitPeopleModel from "@/models/VisitPeopleModel";
import UserPropertypartModel from "@/models/UserPropertypartModel";
import PaymentHistoryModel from "@/models/PaymentHistoryModel";
import AdminModel from "../models/AdminModel";
import RoleAdminPropertyModel from "../models/RoleAdminPropertyModel";
import AvailableResponseModel from '../models/AvailableResponseModel';
import AvailableWeekModel from '../models/AvailableWeekModel';
import VisitHistoryModel from '../models/VisitHistoryModel';

import UserAdminModel from '../models/UserAdminModel';
import PropertyTypeListModel from '../models/PropertyTypeListModel';

import ReportCategoryModel from '../models/ReportCategoryModel';
import ReportManagement from '../models/ReportManagementModel';

import DocumentLangModel from '../models/DocumentLangModel';
import DocumentCategoryLangModel from '../models/DocumentCategoryLangModel';
import ReportCategoryLangModel from '../models/ReportCategoryLangModel';
import RecommendationCategoryLangModel from '../models/RecommendationCategoryLangModel';
import AdminPropertyModel from '../models/AdminPropertyModel';
export default {
  services: {
    'recommendation_category_lang' : {
      'model': new RecommendationCategoryLangModel(),
      'service': new BaseService(new RecommendationCategoryLangModel())
    },
    'report_category_lang': {
      'model': new ReportCategoryLangModel(),
      'service': new BaseService(new ReportCategoryLangModel())
    },
    'document_category_lang': {
      'model': new DocumentCategoryLangModel(),
      'service': new BaseService(new DocumentCategoryLangModel())
    },      
    'document_lang': {
      'model': new DocumentLangModel(),
      'service': new BaseService(new DocumentLangModel())
    },
      'visit_report': {
          'model': new ReportManagement(),
          'service': new BaseService(new ReportManagement())
      },
      'report_category': {
          'model': new ReportCategoryModel(),
          'service': new BaseService(new ReportCategoryModel())
      },
      'property_type_list': {
          'model': new PropertyTypeListModel(),
          'service': new BaseService(new PropertyTypeListModel())
      },
      'user_admin': {
          'model': new UserAdminModel(),
          'service': new BaseService(new UserAdminModel())
      },
      'role_admin_property': {
          'model': new RoleAdminPropertyModel(),
          'service': new BaseService(new RoleAdminPropertyModel())
      },
      'admin_property': {
          'model': new AdminPropertyModel(),
          'service': new BaseService(new AdminPropertyModel())
      },
      'view_admin': {
          'model': new AdminModel(),
          'service': new BaseService(new AdminModel())
      },
      'visit_order_payment': {
          'model': new PaymentHistoryModel(),
          'service': new BaseService(new PaymentHistoryModel())
      },
      'user_property_part': {
          'model': new UserPropertypartModel(),
          'service': new BaseService(new UserPropertypartModel())
      },
      'visit_people': {
          'model': new VisitPeopleModel(),
          'service': new BaseService(new VisitPeopleModel())
      },
      'user_family': {
          'model': new UserFamilyModel(),
          'service': new BaseService(new UserFamilyModel())
      },
      'property_user': {
          'model': new PropertyUserModel(),
          'service': new BaseService(new PropertyUserModel())
      },
      'full_fill_product': {
          'model': new FullFillProductModel(),
          'service': new BaseService(new FullFillProductModel())
      },
      'full_fill_service': {
          'model': new Full_fill_serviceModel(),
          'service': new BaseService(new Full_fill_serviceModel())
      },
      'recommendation_use': {
          'model': new RecommendationUsedModel(),
          'service': new BaseService(new RecommendationUsedModel())
      },
      'recommendation_lang': {
          'model': new RecommendationLangModel(),
          'service': new BaseService(new RecommendationLangModel())
      },
      'recommendation': {
          'model': new RecommendationModel(),
          'service': new BaseService(new RecommendationModel())
      },
      'recommendation_category': {
          'model': new RecommendationCategoryModel(),
          'service': new BaseService(new RecommendationCategoryModel())
      },
      'setting': {
          'model': new SettingsModel(),
          'service': new BaseService(new SettingsModel())
      },
      'service_madatory': {
          'model': new ServiceMadatoryModel(),
          'service': new BaseService(new ServiceMadatoryModel())
      },
      'service_property_type': {
          'model': new ServicePropertyTypeModel(),
          'service': new BaseService(new ServicePropertyTypeModel())
      },
      'lang': {
          'model': new LangModel(),
          'service': new BaseService(new LangModel())
      },
      'service': {
          'model': new ServiceModel(),
          'service': new BaseService(new ServiceModel())
      },
      'country': {
          'model': new CountryModel(),
          'service': new BaseService(new CountryModel())
      },
      'state': {
          'model': new StateModel(),
          'service': new BaseService(new StateModel())
      },
      'property_type': {
          'model': new PropertyTypeModel(),
          'service': new BaseService(new PropertyTypeModel())
      },
      'appoinment': {
        'service': new BaseService(new AppoinmentModel()),
        'model': new AppoinmentModel()
      },
      'visit': {
        'service': new BaseService(new VisitModel()),
        'model': new VisitModel()
      },
      'visit_order_item': {
        'service': new BaseService(new ServiceOrderModel()),
        'model': new ServiceOrderModel()
      },
    'term_of_use_lang': {
      'model': new TermOfUserLangModel(),
      'service': new BaseService(new TermOfUserLangModel())
    },
    'term_of_use': {
      'model': new TermOfUserPolicyModel(),
      'service': new BaseService(new TermOfUserPolicyModel())
    },
    'rent': {
      'model': new RentModel(),
      'service': new BaseService(new RentModel())
    },
    'document': {
      'model': new DocumentModel(),
      'service': new BaseService(new DocumentModel())
    },
    'document_category': {
      'model': new DocumentCategoryModel(),
      'service': new BaseService(new DocumentCategoryModel())
    },
    'accounts': {
      'model': new AccountsModel(),
      'service': new BaseService(new AccountsModel())
    },
    'group': {
      'model': new GroupModel(),
      'service': new BaseService(new GroupModel())
    },
    'group_user': {
      'model': new GroupUserModel(),
      'service': new BaseService(new GroupUserModel())
    },
    'users': {
      'model': new UserModel(),
      'service': new BaseService(new UserModel())
    },
    'file': {
      'model': new FileModel(),
      'service': new BaseService(new FileModel())
    },
    'relationship_file': {
      'model': new RelationshipFileModel(),
      'service': new BaseService(new RelationshipFileModel())
    },

    'notification': {
      'service': new BaseService(new NotificationsModel()),
      'model': new NotificationsModel(),
      'rels': [],
    },

    'lang_notification': {
      'service': new BaseService(new NotificationsLangModel()),
      'model': new NotificationsLangModel()
    },
    'controller': {
      'service': new BaseService(new ControllerModel()),
      'model': new ControllerModel()
    },
    'notification_users': {
      'service': new BaseService(new NotificationUsersModel()),
      'model': new NotificationUsersModel()
    },
    'booking': {
      'service': new BaseService(new BookingModel()),
      'model': new BookingModel()
    },
    'property': {
      'service': new BaseService(new PropertyModel()),
      'model': new PropertyModel()
    },
    'property_part': {
      'service': new BaseService(new PropertyPartModel()),
      'model': new PropertyPartModel()
    },
    'property_week': {
      'service': new BaseService(new PropertyWeekModel()),
      'model': new PropertyWeekModel()
    },
    'booking_round': {
      'service': new BaseService(new BookingRoundModel()),
      'model': new BookingRoundModel()
    },
    'product': {
      'service': new BaseService(new ProductModel()),
      'model': new ProductModel()
    },
    'visit_order': {
      'service': new BaseService(new VisitOrderModel()),
      'model': new VisitOrderModel()
    },
    'visit_order_item_product': {
      'service': new BaseService(new VisitOrderItemProductModel()),
      'model': new VisitOrderItemProductModel()
    },
    'visit_need_to_request': {
      'service': new BaseService(new VisitRequestModel()),
      'model': new VisitRequestModel()
    },
    'visit_need_to_request_item': {
      'service': new BaseService(new RequestOrderModel()),
      'model': new RequestOrderModel()
    },
    'available': {
      'service': new BaseService(new AvailableModel()),
      'model': new AvailableModel()
    },
    'available_reponse': {
      'service': new BaseService(new AvailableResponseModel()),
      'model': new AvailableResponseModel()
    },
    'available_week': {
      'service': new BaseService(new AvailableWeekModel()),
      'model': new AvailableWeekModel()
    },
    'visit_history': {
      'service': new BaseService(new VisitHistoryModel()),
      'model': new VisitHistoryModel()
    }
  },
  getModel(modelName) {
      console.log("model name la : "+modelName)
    return this.services[modelName].model
  },
  getList(modelName, params) {
    return this.services[modelName].service.getListQuery(params);
  },
  countData(modelName) {
    return this.services[modelName].service.countDataQuery();
  },
  updateData(modelName) {
    console.log("updateData:", modelName)
    return this.services[modelName].service.updateDataQuery();

  },
  insertData(modelName) {
    return this.services[modelName].service.insertDataQuery();
  },
  deleteData(modelName) {
    return this.services[modelName].service.deleteDataQuery();
  },
  getByMyId(modelName, params) {
    return this.services[modelName].service.getByMyIdQuery(params);
  },
};
