import BaseModel from '../core/BaseModel'
//import gql from 'graphql-tag'
export default class GroupModel extends BaseModel {

    constructor() {
        super({ 
                name: "group",
                tableName: 'group',
                label: "Permission",
                display_key: 'name',
                default_order: { key: 'ordinal', direction: 'asc' } ,
            },
            {
            stt: {
                name: "stt",
                label: "No.",
                type: "Computed",
                computed: (data, index) => {
                    data.stt = index + 1;
                },
                // display: { list: true, add: false, edit: false }
            },
            id: { 
                name: 'id', 
                type: "Bigint",
                label: 'ID', 
                primaryKey: true,
                display: false
            },
            name: {
                name: 'name',
                type: "String",
                label: 'Permission name',
                width: 15,
                filter: {type: 'input', match: 'contains'},
                // display: false
            },
            user: {
                name: 'user',
                type: "CustomUrl",
                class: 'center',
                label: 'Users',
                computed: (data) => {
                    data.user = 0;
                }
            },
            update_name : { 
                name: 'update_name', 
                type: "String",
                label: 'Updated by',
                filter: {type: 'input', match: 'contains'},
                computed: (data) => {
                    data.update_name = '';
                },
            },
            updated_at : { 
                name: 'updated_at', 
                type: "Datetime",
                width: 15,
                filter: { type: 'DateRange', match: 'contains'},
                label: 'Updated date'
            },
            update_user: {
                refs: `update_user {id fullname}`,
                display: false
            },
            group_users: {
                refs: `group_users {group_id uid}`,
                display: false
            },
            group_roles: {
                refs: `group_roles {
                        id
                        role_id
                    }`,
                display: false
            }
            
        },)
    }

}
