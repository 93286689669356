<template>
  <ckeditor :disabled="disabledCK" :editor="editor" v-model="formData.content" :config="editorConfig" @ready="prefill"></ckeditor>
</template>

<script>

import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';

import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter';

import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
import LinkImage from '@ckeditor/ckeditor5-link/src/linkimage';
import ImageInsert from '@ckeditor/ckeditor5-image/src/imageinsert';

import HtmlEmbed from '@ckeditor/ckeditor5-html-embed/src/htmlembed';

import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline';
import Highlight from '@ckeditor/ckeditor5-highlight/src/highlight';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import Font from '@ckeditor/ckeditor5-font/src/font';
import ListStyle from '@ckeditor/ckeditor5-list/src/liststyle';
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed';
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice';
import Clipboard from '@ckeditor/ckeditor5-clipboard/src/clipboard';
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties';
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import CodeBlock from '@ckeditor/ckeditor5-code-block/src/codeblock';
import SpecialCharacters from '@ckeditor/ckeditor5-special-characters/src/specialcharacters';
// A plugin that combines a basic set of special characters.
import SpecialCharactersEssentials from '@ckeditor/ckeditor5-special-characters/src/specialcharactersessentials';
import SourceEditing from '@ckeditor/ckeditor5-source-editing/src/sourceediting';

export default {
  props: {
    contentSet: String,
    disabledCK: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formData: { content:'' },
      editor: ClassicEditor,
      editorData: '',
      editorConfig: {
        plugins: [
          EssentialsPlugin,
          BoldPlugin,
          ItalicPlugin,
          LinkPlugin,
          ParagraphPlugin,
          Alignment,
          SimpleUploadAdapter,
          // CKFinder,
          Image, ImageToolbar, ImageCaption, ImageStyle, ImageResize, LinkImage,ImageInsert,
          HtmlEmbed,
          HorizontalLine,
          Highlight,
          Heading,
          Font,
          ListStyle,
          MediaEmbed,
          PasteFromOffice,
          Clipboard,
          Table, TableToolbar, TableProperties, TableCellProperties,
          BlockQuote,
          CodeBlock,
          SpecialCharacters, SpecialCharactersEssentials,
          SourceEditing
        ],

        toolbar: {
          items: [
            'sourceEditing',
            'heading',
            '|','fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor','|',
            'bulletedList', 'numberedList','blockQuote',
            'codeBlock',
            'specialCharacters','|',
            '-', // break point
            'bold',
            'italic',
            'link',
            'undo',
            'redo',
            'alignment',
           // '-', // break point
            //'ckfinder', //'uploadImage'

            'imageStyle:full',
            'imageStyle:side',
            '|',
            'imageTextAlternative',
            '|',
            'linkImage',
            //'uploadImage',
            'insertImage',
            'insertTable',
            'mediaEmbed',
            'htmlEmbed',
            'horizontalLine',
            'highlight'

            //'imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight',
          ],
          shouldNotGroupWhenFull: true
        },
        simpleUpload: {
          // The URL that the images are uploaded to.
          //uploadUrl: 'http://localhost:3456/_api/common/upload-ckeditor',
          uploadUrl: this.$ConfigEvn.base_url_api+'/_api/common/upload-s3-ckeditor',

          // Enable the XMLHttpRequest.withCredentials property.
          withCredentials: false,

          // Headers sent along with the XMLHttpRequest to the upload server.
          headers: {
            //'X-CSRF-TOKEN': 'CSRF-Token',
            //Authorization: 'asdasdasdasdasdsad'
            "Authorization": "Bearer "+this.$store.getters.token,
          }
        },
        image: {
          // Configure the available styles.
          styles: [
            'alignLeft', 'alignCenter', 'alignRight'
          ],

          // Configure the available image resize options.
          resizeOptions: [
            {
              name: 'resizeImage:original',
              label: 'Original',
              value: null
            },
            {
              name: 'resizeImage:50',
              label: '50%',
              value: '50'
            },
            {
              name: 'resizeImage:75',
              label: '75%',
              value: '75'
            }
          ],

          // You need to configure the image toolbar, too, so it shows the new style
          // buttons as well as the resize buttons.
          toolbar: [
            'imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight',
            '|',
            'resizeImage',
            '|',
            'imageTextAlternative'
          ]
        },
        fontSize: {
          options: [
            9,10,11,12,14,16,'default',18,20,22,28
          ]

        },
        table: {
          contentToolbar: [
            'tableColumn', 'tableRow', 'mergeTableCells',
            'tableProperties', 'tableCellProperties'
          ],

          tableProperties: {
            borderColors: this.getColorTable(),
            backgroundColors: this.getColorTable()
          },

          // Set the palettes for table cells.
          tableCellProperties: {
            borderColors: this.getColorTable(),
            backgroundColors: this.getColorTable()
          }
        },
        isReadOnly: this.isReadOnly

      },
    }
  },
  created() {
    console.log('disabledCKdisabledCK',this.disabledCK);
    
    // console.log("this.contentSetthis.contentSet",this.contentSet);
   // this.formData.content = this.contentSet;
  },
  watch: {
      isReadOnly(newValue) {
        this.editorConfig.isReadOnly = newValue;
      },
    formData: {
      handler: function() {
        this.$emit('newdata', [this.formData.content]);
      },
      deep: true
    },
    /*contentSet:{
      handler: function() {
        console.log("this.contentSet1111",this.contentSet);
        this.formData.content = this.contentSet;
      },
      deep: true
    }*/

  },
  // watch: {
  //   '$route': 'initParams'
  // },
  methods: {
    prefill( ) {
      this.formData.content = this.contentSet;
    },
    getColorTable(){
      const customColorPalette = [
        {
          color: 'hsl(4, 90%, 58%)',
          label: 'Red'
        },
        {
          color: 'hsl(340, 82%, 52%)',
          label: 'Pink'
        },
        {
          color: 'hsl(291, 64%, 42%)',
          label: 'Purple'
        },
        {
          color: 'hsl(262, 52%, 47%)',
          label: 'Deep Purple'
        },
        {
          color: 'hsl(231, 48%, 48%)',
          label: 'Indigo'
        },
        {
          color: 'hsl(207, 90%, 54%)',
          label: 'Blue'
        },

        // ...
      ];
      return customColorPalette;
    }

  },
}
</script>
