<template>
  <form id="frm_action">
    <div class="p-formgrid">
      <Toolbar class="fixed-bottom-toolbar div-button css-button-top-right">
        <template slot="right">
          <Button
              label="Back"
              @click="backToList()"
              id="back"
              class="main-style-button"
          />
        </template>
      </Toolbar>
      <div class="col-6 p-0">
        <div class="p-card-field p-0">
          <label class="p-col-fixed txt-right txt-required p-0">Permission name</label>
          <div class="p-col-12 pl-0">
            <InputText :disabled="true" v-model="name"  style="width: 100%!important;" id="name" type="text" class="p-col-width" placeholder="Input" />
          <!--<InputText id="firstname" type="text" />-->
          </div>
        </div>
        <div class="p-card-field p-0">
          <label class="p-col-fixed txt-right txt-required p-0 m-0">User(s)</label>
          <div class="p-col-12 row align-items-center justify-content-between pr-0">
            <div class="p-col-11">
              <v-select :multiple="true" :clearable="false" title="Admin" class="" @search="searchAdmin" placeholder="Choose" v-model="selAdmin" :options="listAdmin" label="fullname"  >
                <span slot="no-options" @click="$refs.select.open = false">
                  Empty data
                </span>
              </v-select>
              <input class="input_tmp_validator" id="users">
            </div>
            <div class="p-col-1 d-flex align-items-end p-0">
                <Button icon="pi pi-plus" class="p-button-outlined" @click="addUser()"/>
            </div>
          </div>
        </div>
        <div class="p-card-field p-0">
          <h5 class="pl-2">SELECTED USER(S):</h5>
        </div>
        <div class="p-card-field p-0">
          <div class="pl-3 row col-12" v-for="(item, index) in users" :key="`user-${index}`">
            <div class="pl-2">
              {{item.fullname}}
            </div>
            <div class="pl-2">
              <span class="pi pi-trash txt-danger" @click="delUser(index)"></span>
            </div>
          </div>
        </div>
      </div>
      <Toolbar class="fixed-bottom-toolbar div-button">
        <template slot="right">
          <Button
              :label="$constants.TEXT_BUTTON.BACK"
              @click="backToList()"
              class="p-button-outlined"
              id="back"
          />
          <Button
              v-if="mode == 'edit'"
              :label="$constants.TEXT_BUTTON.EDIT"
              @click="saveData()"
              class="main-style-button"
          />
          <Button
              v-else
              :label="$constants.TEXT_BUTTON.ADD"
              @click="saveData()"
              class="main-style-button"
          />
        </template>
      </Toolbar>
    </div>
  </form>
</template>

<script>
import DataServices from '@/core/DataServices'

export default {
  props: {
    mode: String,
    model: Object
  },
  data() {
    return {
      name: '',
      users: [],
      selAdmin: null,
      listAdmin: [],
      listAdminAll: [],
      listDel: []
    }
  },
  async mounted() {
    var group_id = this.$route.params.id;
    if (!this.$commonFuction.isEmpty(group_id)){
      var where = {group_id: {'_eq': group_id}};
      var dataGroup = await this.$CoreService.getListData('group_user',where);
      var group_user = dataGroup;
      this.name = group_user[0]?.group?.name;
      this.$emit('updateTitle', this.name);
      for (let i=0; i < group_user.length; i++) {
        let m = group_user[i];
        this.users.push({
          id: m?.user?.id,
          fullname: m?.user?.fullname
        })
      }
      await this.getListAdmin();
    }
  },
  methods: {
    addUser() {
      console.log("this?.selUser", this?.selAdmin);
      if (this?.selAdmin && this.selAdmin.length > 0) {
        this.selAdmin.map(m => {
          m.is_new = true;
          this.users.push(m);
        });
        this.selAdmin = null;
        this.getListAdmin();
      }
    },
    delUser(index) {
      if (!this.users[index]?.is_new) {
        this.listDel.push(this.users[index])
      }
      this.users.splice(index, 1);
      this.getListAdmin();
    },
    async getListAdmin(){
      var listUid = [];
      this.users.map(m => listUid.push(m?.id));
      var where_key = {
        deleted : {_eq : false},
        role: {_eq: 'admin_child'},
        id: {_nin: listUid}
      }
      let {data} = await this.$apollo.query({
        query: DataServices.getList('users',{"fields" : `id text_group_users fullname`}),
        variables: {
          where_key: where_key,
          orderBy:[{created_at : 'desc'}]
        },
        fetchPolicy: "network-only"
      })
      var list = data[Object.keys(data)];
      var list_assign = [];
      // console.log("list===",list);
      for (let i = 0; i < list.length; i++) {
        const e = list[i];
        //if (!e?.booking?.id || (this.selProperty?.id && this.selProperty?.id == e.id)) {
          list_assign.push({id : e.id, fullname :e.text_group_users});
        //}
      }
      this.listAdmin = list_assign;
      this.listAdminAll = list_assign;
    },
    
    async searchAdmin(search, loading){
      console.log(loading);
      var listUid = [];
      this.users.map(m => listUid.push(m?.id));
      if(search !== '' && search !== null){
        var where_key = {
          deleted : {_eq : false},
          role: {_eq: 'admin_child'},
          id: {_nin: listUid},
          _or: {
            text_group_users: {_iregex: this.$commonFuction.search(search)},
          }
        };
        let {data} = await this.$apollo.query({
          query: DataServices.getList('users',{"fields" : `id text_group_users fullname`}),
          variables: {
            where_key: where_key,
            orderBy:[{created_at : 'desc'}]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_assign = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          //if (!e?.booking?.id || (this.selProperty?.id && this.selProperty?.id == e.id)) {
            list_assign.push({id : e.id, fullname :e.text_group_users});
          //}
        }
        this.listAdmin = list_assign;
      }else{
        // cosnole.log("dasdasd")
        this.listAdmin = this.listAdminAll;
      }
    },
    backToList() {
      this.$emit('back');
    },
    async validateData() {
      let users_add = document.getElementById("users");
      if (this.users.length < 1){
        users_add.setCustomValidity("Please add at least 1 user!");
      }else {
        users_add.setCustomValidity("");
      }
      var inpObj = document.getElementById("frm_action");
      if (!inpObj.checkValidity()){
        console.log('test=====================');
        inpObj.reportValidity();
        return true;
      }

    },
    
    async saveData() {

     /* if(this.saving == true){
        return;
      }
      this.saving = true;*/
      // this.$commonFuction.is_loading(true);
      if (await this.validateData()) {
       // this.saving = false;
        this.$commonFuction.is_loading(false);
        return
      }
      var params = {
        users: this.users,
        listDel:this.listDel
      }
      // console.log('saveData', params)
      this.$emit('save', params);
    },
  },
  /*apollo: {
    category: {
      query() {
        return DataServices.getList('category', {"fields" : "id name"});
      },
      update(data) {
        if(data.category){
          this.categories = data.category
        }
      },
      fetchPolicy: "network-only"
    },
  }*/
}
</script>

<style lang="scss">
  .p-multiselect {
    width: 450px;
  }
  .p-multiselect-label:not(.p-placeholder) {
    padding-top: .25rem;
    padding-bottom: .25rem;
    
  }
  .category-item-value {
    padding: .25rem .5rem;
    border-radius: 3px;
    display: inline-flex;
    margin-right: .2rem;
    background-color: var(--primary-color);
    color: var(--primary-color-text);
  }
  .p-multiselect-label-container{
    height: 40px;
  }
  </style>

<style >
.box-permission{
  border: 1px solid #E2E2E2;
  border-radius: 5px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  margin-right: 0px !important;
  margin-left: 0px !important;
  margin-bottom: 16px!important;
}
.box-permission .box-left{
  padding: 24px!important;
  border-right: 1px solid #E2E2E2;
  text-align: left;
}
.box-permission .box-right{
  padding: 24px!important;
}
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #ccc;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #e95d57;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.span-label {
  display: block;
  position: relative;
  font-weight: 600;
}
.custom{
  width: 100%;
}
.cus-row{
  margin-right: 0px;
  margin-left: 0px;
}
.txt-danger{
  color: #EF4444;
  cursor: pointer;
}
.txt-right{
  font-weight: bold;
}
.p-button-outlined {
  .p-button-label {
    color: #007ad9 !important;
  }
}
</style>
