<script>
import DataServices from '@/core/DataServices';
// import FullCalendar from '@fullcalendar/vue'
// import multiMonthPlugin from '@fullcalendar/multimonth'
import Calendar from 'v-year-calendar';

import moment from "moment-timezone";

export default {

  components: {
    // FullCalendar // make the <FullCalendar> tag available
    FullCalendar: Calendar
  },
  props: {
    events: Array
  },
  data: function() {
    return {
      colorArr: [
        {color: '#BBB2F9', value: 1},
        {color: '#F8D8AB', value: 2},
        {color: '#BBEFA8', value: 3},
        {color: '#A7C3FA', value: 4},
        {color: '#F3B1AF', value: 5}
      ],
      year: new Date().getFullYear(),
      // calendarOptions: {
      //   initialDate: new Date(),
      //   plugins: [multiMonthPlugin],
      //   initialView: 'multiMonthYear',
      //   multiMonthMinWidth: 100,
      //   multiMonthMaxColumns: 1,
      //   headerToolbar: false,
      //   weekNumbers: true,
      //   weekends: true,
      //   firstDay: 1,
      //   selectable: true,
      //   editable: true,
      //   weekText: "",
      //   validRange: {},
      //   showNonCurrentDates: true,
      //   dayHeaders: false,
      //   events: [
      //     // {
      //     //   start: '2025-01-01',
      //     //   end: '2025-01-10',
      //     //   display: 'background',
      //     //   className: 'event-part-01'
      //     // }
      //   ],
      //   navLinks: true,
      //   navLinkWeekClick: function(weekStart, jsEvent) {
      //     let allWeek = document.querySelectorAll('.fc-daygrid-week-number');
      //     allWeek.forEach((m) => {
      //       m.classList.remove('active');
      //     })
      //     jsEvent.target.classList.add('active');
      //   }
      // },
      event: [],
      style: "background",
      time_zone_local : moment.tz.guess(true),
      listProperty: [],
      listPropertyAll: [],
      selProperty: null,
      listOwner: [],
      listOwnerAll: [],
      selOwner: null,
      from_to: null,
      status: null,
      min: null,
      max: null,
      listWeekChoose: [],
    }
  },
  watch: {
    async "selProperty"() {
      await this.getListOwner();
      await this.loadEvent();
    },
    async "status"() {
      await this.loadEvent();
    },
    async "selOwner"() {
      await this.loadEvent();
    }
  },
  async created() {
    await this.getListProperty();
    if (this.$route?.query && this.$route.query?.propery_id) {
      var property_tmp = this.listPropertyAll.filter(f => f.id == this.$route.query?.propery_id);
      this.selProperty = property_tmp.length > 0 ? property_tmp[0] : null;
    }
  },
  computed: {
    minDate() {
      var date = null;
      date = this.min;
      return date;
    },
    maxDate(){
      var date = null;
      date = this.max;
      return date;
    }
  },
  methods: {
    clearFilter() {

      this.$set(this, 'selProperty', null);
      this.$set(this, 'selOwner', null);
      this.$set(this, 'status', null);
      this.$set(this, 'from_to', null);
        this.loadEvent();
    },
    async onFilter() {
      this.min = null;
      this.max = null;
      if (!this?.from_to || this.from_to.length > 1) {
        if (this.from_to && this.from_to.length > 1 && !this.$commonFuction.isEmpty(this.from_to[0]) && !this.$commonFuction.isEmpty(this.from_to[1])) {
          this.min = this.from_to[0];
          this.max = this.from_to[1];
        }
        await this.loadEvent();
      }
    },
    async loadEvent() {
      this.event = [];
      if (!this.selProperty?.id) {
        return;
      }
      var where_key = {
        deleted : {_eq : false},
        property_id: {_eq: this.selProperty?.id}
      }
      if (this.selOwner?.id) {
        where_key.owner_id = {_eq: this.selOwner?.id}
      }
      if (this.status) {
        where_key.status = {_eq: this.status?.value}
      }
      if (this?.from_to && this.from_to.length > 1 && !this.$commonFuction.isEmpty(this.from_to[0]) && !this.$commonFuction.isEmpty(this.from_to[1])) {
        where_key["_and"] = {_or: [{visit_end: {'_gte': this.from_to[1]}, visit_start: {'_lte': this.from_to[0]}}, {visit_start: {'_gte': this.from_to[0]}, visit_end: {'_lte': this.from_to[1]}}]};
      }
      this.$commonFuction.is_loading(true);
      let {data} = await this.$apollo.query({
        query: DataServices.getList('visit',{"fields" : `id departure arrival check_in_time check_out_time property{id name code part{part uid}} owner_id visit_end visit_start children adults week_number user{id role fullname} owner{id fullname} update_user{id fullname} updated_at orders{id items{id service {id}}} reports{id} peoples{id} status`}),
        variables: {
          where_key: where_key,
          orderBy:[{created_at : 'desc'}]
        },
        fetchPolicy: "network-only"
      })
      var list = data[Object.keys(data)];
      var event = [];
      var yearArr = {};
      list.map(m => {
        event.push({
          startDate: new Date(moment.tz(m.visit_start, this.time_zone_local)),
          endDate: new Date(moment.tz(m.visit_end, this.time_zone_local)),
          display: 'background',
          class: `event-part-0${m.property.part.filter(f => f.uid == m.owner_id)[0]?.part}`,
          color: this.colorArr.filter(f => f.value == Number(m.property.part.filter(f => f.uid == m.owner_id)[0]?.part))[0]?.color,
          data: m
        });
        console.log(this.colorArr.filter(f => f.value == Number(m.property.part.filter(f => f.uid == m.owner_id)[0]?.part))[0]?.color);
        if (Object.hasOwn(yearArr, new Date(moment.tz(m.visit_start, this.time_zone_local)).getFullYear())) {
          yearArr[new Date(moment.tz(m.visit_start, this.time_zone_local)).getFullYear()] = 0;
        }
        yearArr[new Date(moment.tz(m.visit_start, this.time_zone_local)).getFullYear()] += 1;
      });
      var checkYear = 0;
      console.log("this.year", yearArr);
      for (const [key, value] of Object.entries(yearArr)) {
        if (checkYear <= value) {
          checkYear = value;
          this.year = key;
        }
      }
      console.log("this.year", this.year);
      console.log('event', event);
      // this.calendarOptions.events = event;
      this.event = event;
      setTimeout(() => {
        this.$commonFuction.is_loading(false);
      }, 500)
    },
    async getListProperty(){
      var where_key = {
        deleted : {_eq : false},
      }
      let {data} = await this.$apollo.query({
        query: DataServices.getList('property',{"fields" : `id text_group_property code name`}),
        variables: {
          where_key: where_key,
          orderBy:[{created_at : 'desc'}]
        },
        fetchPolicy: "network-only"
      })
      var list = data[Object.keys(data)];
      var list_property = [];
      // console.log("list===",list);
      for (let i = 0; i < list.length; i++) {
        const e = list[i];
        list_property.push({id : e.id, name :e.text_group_property, code: e.code});
      }
      this.listProperty = list_property;
      this.listPropertyAll = list_property;
    },
    
    async SearchProperty(search, loading){
      console.log(loading);
      if(search !== '' && search !== null){
        var where_key = {
          deleted : {_eq : false},
          _or: {
            text_group_property: {_iregex: this.$commonFuction.search(search)},
          }
        };
        let {data} = await this.$apollo.query({
          query: DataServices.getList('property',{"fields" : `id text_group_property code name`}),
          variables: {
            where_key: where_key,
            orderBy:[{created_at : 'desc'}]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_property = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          list_property.push({id : e.id, name :e.text_group_property, code: e.code});
        }
        this.listProperty = list_property;
      }else{
        // cosnole.log("dasdasd")
        this.listProperty = this.listPropertyAll
      }
    },
      
    async getListOwner(){
      if (!this.selProperty?.id) {
        return;
      }
      var where_key = {
        property_id: {_eq: this.selProperty.id}
      }
      let {data} = await this.$apollo.query({
        query: DataServices.getList('property_part',{"fields" : `id uid user{id fullname}`}),
        variables: {
          where_key: where_key,
          orderBy:[{order_booking : 'asc'}]
        },
        fetchPolicy: "network-only"
      })
      var list = data[Object.keys(data)];
        var list_part_map = {};
      var list_owner = [];
      // console.log("list===",list);
      var checkUser = [];
      for (let i = 0; i < list.length; i++) {
        const e = list[i];
        if(!checkUser.includes(e.user.id)) {
          list_owner.push({id : e.user.id, fullname :e.user.fullname});
          checkUser.push(e.user.id);
        }
         list_part_map[i] = e;
      }
      
      this.list_part_map = list_part_map;
      this.$emit('list_part_map', list_part_map)
      this.listOwner = list_owner;
      this.listOwnerAll = list_owner;
    },
    
    async SearchOwner(search, loading){
      console.log(loading);
      if (!this.selProperty?.id) {
        return;
      }
      if(search !== '' && search !== null){
        var where_key = {
          property_id: {_eq: this.selProperty.id},
          _or: {
            _iregex: this.$commonFuction.search(search),
          }
        };
        let {data} = await this.$apollo.query({
          query: DataServices.getList('property_part',{"fields" : `id uid user{id fullname}`}),
          variables: {
            where_key: where_key,
            orderBy:[{order_booking : 'asc'}]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_owner = [];
        var checkUser = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          if(!checkUser.includes(e.user.id)) {
            list_owner.push({id : e.user.id, fullname :e.user.fullname});
            checkUser.push(e.user.id);
          }
        }
        this.listOwner = list_owner;
      }else{
        this.listOwner = this.listOwnerAll
      }
    },
    getFilterDate(date) {
        return moment.tz(date, this.time_zone_local).format("LL");
    },
    selectRange(dataEvent) {
      this.listWeekChoose = [];
      dataEvent.events.map(d => {
        // if (d?.data?.status == 'ST001') {
        //   this.listWeekChoose = [];
        //   return;
        // }
        // console.log(d?.color);
        let m = d?.data;
        let data = [];
        data.push({
          label: 'Visit',
          value: `Week ${m.week_number} (${this.getFilterDate(m.visit_start)} - ${this.getFilterDate(m.visit_end)})`
        });
        data.push({
          label: 'Owner',
          value: m?.owner?.fullname
        });
        data.push({
          label: 'Who stay',
          value: m?.user?.fullname
        });
        data.push({
          label: 'Status',
          value: this.$constants.VISIT_STATUS.filter(f => f.value == m.status)[0]?.label
        });
        data.push({
          label: 'Arrival',
          value: m?.check_in_time ? moment(m.check_in_time).utc().format("LL HH:mm") : (m?.arrival ? moment(m.arrival).utc().format("LL HH:mm") : null)
        });
        data.push({
          label: 'Departure',
          value: m?.check_out_time ? moment(m.check_out_time).utc().format("LL HH:mm") : (m?.departure ? moment(m.departure).utc().format("LL HH:mm") : null)
        });
        let stay_days = null
        if (m?.arrival && m?.departure) {
          let departure = moment(m.departure);
          let arrival = moment(m.arrival);
          let diff = departure.diff(arrival, 'days');
          stay_days = (diff+1);
        }
        data.push({
          label: 'Day of stay in',
          value: stay_days
        });
        let people = 0;
        if (m?.user?.role != 'renter') {
          people = Number(m?.children) + Number(m?.adults);
        } else {
          people = m.peoples.length;
        }
        data.push({
          label: 'People',
          value: people
        });
        let services = 0;
          if (m?.orders?.items) {
            services = m.orders.items.filter(f => f.service?.id).length;
          }
        data.push({
          label: 'Service',
          value: services
        });
        data.push({
          label: 'Report',
          value: m.reports.length
        });
        data.push({
          label: 'Updated by',
          value: m?.update_user?.fullname
        });
        data.push({
          label: 'Updated date',
          value: moment.tz(m.update_at, this.time_zone_local).format("LL HH:mm")
        });
        this.listWeekChoose.push(data);
      })
    },
    renderEnd(data) {
      console.log('render',data);
      var that = this;
      data.calendar.element.querySelectorAll('.month-container').forEach(function (month) {
        var monthId = parseInt(month.dataset.monthId);
        month.querySelectorAll('.day-content').forEach(function (day) {
          var currentDate = new Date(that.year, monthId, parseInt(day.textContent));
          data.calendar._dataSource.forEach(function (src) {
            if (moment(currentDate).format("YYYYMMDD") >= moment(new Date(src.data.visit_start)).format("YYYYMMDD") && moment(currentDate).format("YYYYMMDD") <= moment(new Date(src.data.visit_end)).format("YYYYMMDD")) {
            //   if (src.data.status == 'ST001') {
                // day.parentNode.classList.add('txt-disabled');
            //   } else {
            //     day.parentNode.classList.remove('txt-disabled');
            //   }
              day.parentNode.classList.add('txt-active-day');
            }
            if (src?.data?.check_in_time) {
              if (moment(currentDate).format("YYYYMMDD") == moment(new Date(src.data.check_in_time)).utc().format("YYYYMMDD")) {
                day.classList.add('arrival');
              }
            } else {
              if (moment(currentDate).format("YYYYMMDD") == moment(new Date(src.data.arrival)).utc().format("YYYYMMDD")) {
                day.classList.add('arrival');
              }
            }
            if (src?.data?.check_out_time) {
              if (moment(currentDate).format("YYYYMMDD") == moment(new Date(src.data.check_out_time)).utc().format("YYYYMMDD")) {
                day.classList.add('departure');
              }
            } else {
              if (moment(currentDate).format("YYYYMMDD") == moment(new Date(src.data.departure)).utc().format("YYYYMMDD")) {
                day.classList.add('departure');
              }
            }
          })
        });
      });
    },
    clickDay(data) {
      if (data.element.classList.contains('txt-disabled') || !data.element.classList.contains('txt-active-day')) {
        return;
      }
      document.querySelectorAll(".week-active").forEach(function (week) {
        week.classList.remove("week-active");
      });
      document.querySelectorAll(".prev-week-active").forEach(function (week) {
        week.classList.remove("prev-week-active");
      });
      data.element.parentNode.classList.add("week-active");
      data.element.parentNode.previousSibling.classList.add("prev-week-active");
    }
  }
}
</script>

<template>
  <div class='demo-app'>
    <div class="row"> 
    <div class="row col-10 mb-3">
      <div class="col-3">
        <label class="p-0 txt-right txt-required">Property ID</label>
        <div class="col-12 p-0">
          <v-select title="Property" class="" @search="SearchProperty" placeholder="Choose" v-model="selProperty" :options="listProperty" label="name">
            <span slot="no-options" @click="$refs.select.open = false">
              Empty data
            </span>
          </v-select>
          <input class="input_tmp_validator" id="property">
        </div>
      </div>

      <div class="col-3">
        <label class="p-0 txt-right">From - to</label>
        <div class="col-12 p-0">
          <Calendar class="w-100" :showOnFocus="false" dateFormat="MM dd, yy" :showIcon="true" selectionMode="range"
            appendTo="body" :showClear="true" :showButtonBar="true" :manualInput="false" @clear-click="onFilter"
            @date-select="onFilter" v-model="from_to" placeholder="Choose"> <template #footer>
              <CalendarFooter />
            </template></Calendar>
        </div>
      </div>
      <div class="col-3">
        <label class="p-0 txt-right">Status</label>
        <div class="col-12 p-0">
          <v-select title="status" :clearable="true" class="" placeholder="Choose" v-model="status"
            :options="$constants.VISIT_STATUS" label="label">
            <span slot="no-options" @click="$refs.select.open = false">
              Empty data
            </span>
          </v-select>
        </div>
      </div>
      <div class="col-3">
        <label class="p-0 txt-right">Owner</label>
        <div class="col-12 p-0">
          <v-select title="Owner" :clearable="true" class="" @search="SearchOwner" placeholder="Choose"
            v-model="selOwner" :options="listOwner" label="fullname">
            <span slot="no-options" @click="$refs.select.open = false">
              Empty data
            </span>
          </v-select>
        </div>
      </div>

    </div>
     <div class="row col-2 mb-3 flex-container">
      <Button
      v-if="!$commonFuction.isEmpty(selProperty) 
      || !$commonFuction.isEmpty(from_to)
      || !$commonFuction.isEmpty(status)
      || !$commonFuction.isEmpty(selOwner)
      "
              label="Clear filter"
              @click="clearFilter()"
              id="back"
              class="main-style-button"
              style="width: 120px;"
          />

          <!--   @click="saveData()" -->
     </div>
     </div>
    <div class="row col-12 p-0">
      <!-- <FullCalendar
        v-if="selProperty"
        class='visit-calendar col-6 p-0'
        :options='calendarOptions'
        ref="fullCalendar"
      /> -->
      <div class="col-6 p-0 pl-3 pr-3">
        <div class="visit-calendar-header">
          <div class="months-container" style="opacity: 1; display: block;">
            <div class="month-container month-12">
              <table class="month">
                <thead>
                  <tr>
                    <th class="week-number">Week</th>
                    <th class="day">
                      <div class="day-content">Mon</div>
                    </th>
                    <th class="day">
                      <div class="day-content">Tue</div>
                    </th>
                    <th class="day">
                      <div class="day-content">Wed</div>
                    </th>
                    <th class="day">
                      <div class="day-content">Thu</div>
                    </th>
                    <th class="day">
                      <div class="day-content">Fri</div>
                    </th>
                    <th class="day">
                      <div class="day-content">Sat</div>
                    </th>
                    <th class="day">
                      <div class="day-content">Sun</div>
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
        <FullCalendar :year="year" @click-day="clickDay" @render-end="renderEnd" @select-range="selectRange"
          :min-date="minDate" :max-date="maxDate" :enable-range-selection="true" class='visit-calendar col-12'
          :render-style="style" week-start="1" :data-source="event" :display-week-number="true"
          :display-header="false" />
      </div>
      <div class="col-6 p-0 m-0 detail-box">
        <div class="pl-3 pr-3 mt-2 mb-2" v-for="(data,index) in listWeekChoose" :key="`parents-${index}`">
          <div class="col-12 p-0 d-flex justify-content-between" v-for="(items,ind) in data" :key="`child-${ind}`">
            <div class="col-6 p-0 txt-left">
              <b>{{items.label}}</b>
            </div>
            <div class="col-6 p-0 txt-right fw-400">
              {{items.value}}
            </div>
          </div>
          <div class="center-line" v-if="index != (listWeekChoose.length -1)"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.center-line {
  margin: auto;
  background-color: #D0D4D9;
  width: 100%;
  height: 2px;
  margin: 1rem 0;
}
.detail-box {
  border: 1px solid #D0D4D9;
  border-radius: 2px;
  padding: 20px;
  max-height: 540px;
  overflow-y: auto;
}
.event-part-01 {
  background-color: #BBB2F9 !important;
}

.event-part-02 {
  background-color: #F8D8AB !important;
}

.event-part-03 {
  background-color: #BBEFA8 !important;
}

.event-part-04 {
  background-color: #A7C3FA !important;
}

.event-part-05 {
  background-color: #F3B1AF !important;
}
.visit-calendar a{
  color: #666666;
}
/* .fc-multimonth-month {
    border-right: 15px solid #d0e0ff;
    border-top: 15px solid #d0e0ff;
} */
.fc .fc-bg-event {
  opacity: 1 !important;
  border: none !important;
}
/* .fc-multimonth-month:nth-child(1), .fc-multimonth-month:nth-child(2), .fc-multimonth-month:nth-child(3), .fc-multimonth-month:nth-child(4) {
  border-top: none;
}

.fc-multimonth-month:nth-child(4), .fc-multimonth-month:nth-child(8), .fc-multimonth-month:nth-child(12) {
  border-right: none;
} */
.fc-multimonth-multicol, .fc-multimonth-month {
  border: none !important;
}

.visit-calendar .fc-daygrid-day {
  height: 20px !important;
}

.visit-calendar .fc-scrollgrid-sync-table {
  height: 100px !important;
}
.visit-calendar .fc-multimonth-title {
  text-align: left !important;
}
.visit-calendar thead[role="rowgroup"] .fc-col-header-cell-cushion {
  display: none !important;
}
.visit-calendar .fc-multimonth-daygrid-table {
  max-height: 100px;
}
.visit-calendar tbody[role="rowgroup"] tr[role="row"] {
  cursor: pointer;
}
.visit-calendar .month-container table.month td {
  border: 2px solid #fff !important;
  background-color: #f8f8f86b;
}
.visit-calendar .month-container table.month tr.prev-week-active td {
  border-bottom: 2px solid #1D76BC !important;
}
.visit-calendar tbody[role="rowgroup"] tr[role="row"]:has(.fc-daygrid-week-number.active) td, .visit-calendar .month-container table.month tr.week-active td{
  border: 2px solid #1D76BC !important;
  border-left: 1px solid #fff !important;
  border-right: 1px solid #fff !important;
  border-top-width: 2px !important;
}
.visit-calendar tbody[role="rowgroup"] tr[role="row"]:has(.fc-daygrid-week-number.active) td:first-child, .visit-calendar .month-container table.month tr.week-active td:first-child{
  border-left: 5px solid #1D76BC !important;
  border-left-width: 5px !important;
}

.visit-calendar tbody[role="rowgroup"] tr[role="row"]:has(.fc-daygrid-week-number.active) td:last-child, .visit-calendar .month-container table.month tr.week-active td:last-child{
  border-right: 2px solid #1D76BC !important;
  border-right-width: 2px !important;
}
/* .visit-calendar .month-container table.month tr.week-active td{
  border: 2px solid #1D76BC !important;
  border-left: 5px solid #1D76BC !important;
} */
.visit-calendar .month-title {
  text-align: left;
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}
.visit-calendar .month-container table.month {
  width: 100%;
}
.visit-calendar .month-container.month-6,
.visit-calendar .month-container.month-3,
.visit-calendar .month-container.month-4,
.visit-calendar .month-container.month-12 {
  width: 100% !important;
}
.visit-calendar .month-container table.month thead tr:last-child {
  display: none !important;
}
.visit-calendar .month-container table.month td.week-number{
  background-color: #F3F4F7 !important;
}
.visit-calendar-header {
  padding: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  direction: ltr;
  overflow-x: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.visit-calendar-header table td, .visit-calendar-header table th {
  text-align: center;
  width: 20px;
  height: 20px;
  border: none;
  /* padding: 4px 5px; */
  font-size: 12px;
  background-color: #F3F4F7 !important;
  border: 2px solid #fff !important;
}

.visit-calendar table.month td .day-content:hover {
  background-color: unset !important;
  color: unset !important;
}
.visit-calendar{
  max-height: 500px;
  overflow-y: auto;
}
.visit-calendar-header table.month th.week-number{
  padding: 5px;
}
.visit-calendar-header table.month th.day .day-content{
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  padding: 5px 15px;
}
.visit-calendar-header table.month {
  width: 100%;
}
.visit-calendar table.month td.day .day-content{
  position: relative !important;
}
.visit-calendar table.month td.day .day-content.arrival::before{
  content: "a";
  background-image: url('../../../assets/arrival.png');
  background-size: 12px;
  background-repeat: no-repeat;
  color: transparent;
  position: absolute;
  left: 5%;
  background-position: center;
  padding: 0 5px;
}
.visit-calendar table.month td.day .day-content.departure::after{
  content: "b";
  background-image: url('../../../assets/departure.png');
  background-size: 12px;
  background-repeat: no-repeat;
  color: transparent;
  position: absolute;
  right: 5%;
  background-position: center;
  padding: 0 5px;
}
.day.txt-disabled {
  opacity: 0.5 !important;
}
.fw-400 {
    font-weight: 400 !important;
}
.flex-container {
  display: flex;
  flex-direction: column;  /* Sắp xếp các phần tử theo chiều dọc */
  justify-content: flex-start; /* Căn các phần tử ở đầu */
  margin-top: auto; /* Đẩy nút xuống dưới cùng */
  /* padding-top: 10px; */
}

</style>