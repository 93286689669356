<template>
  <form id="frm_action">
    <div class="p-formgrid">
      <Toolbar class="fixed-bottom-toolbar div-button css-button-top-right">
        <template slot="right">
          <Button
              label="Back"
              @click="backToList()"
              id="back"
              class="main-style-button"
          />
        </template>
      </Toolbar>
      <div class="col-6">
        <div class="p-card-field">
          <label class="p-col-fixed txt-right" :class="`${mode=='edit' && ['ST001', 'ST002'].includes(status.value) ? 'txt-required' : ''}`">Request status</label>
          <div class="p-col">
            <Dropdown :disabled="mode=='add' || !['ST001', 'ST002'].includes(checkStatus)" v-model="status" :options="$constants.REQUEST_STATUS" optionLabel="label" placeholder="Choose status" />
            <input class="input_tmp_validator" id="status">
          </div>
        </div>
        <div class="p-card-field" v-if="mode == 'edit'">
          <label class="p-col-fixed txt-right">Request ID</label>
          <div class="p-col pl-1">
            <div class="txt-disabled">{{code}}</div>
          </div>
        </div>
        <div class="p-card-field" v-if="mode == 'edit'">
          <label class="p-col-fixed txt-right" :class="`${mode=='add' ? 'txt-required' : ''}`">Property ID</label>
          <div class="p-col">
            <v-select :disabled="mode=='edit'" :clearable="false" title="Property" class="" @search="SearchProperty" placeholder="Choose" v-model="selProperty" :options="listProperty" label="name"  >
              <span slot="no-options" @click="$refs.select.open = false">
                Empty data
              </span>
            </v-select>
          </div>
        </div>
        <div class="p-card-field">
          <label class="p-col-fixed txt-right" :class="`${mode=='add' ? 'txt-required' : ''}`">Property</label>
          <div class="p-col">
            <v-select :disabled="mode=='edit'" :clearable="false" title="Property" class="" @search="SearchProperty" placeholder="Choose" v-model="selProperty" :options="listProperty" label="name"  >
              <span slot="no-options" @click="$refs.select.open = false">
                Empty data
              </span>
            </v-select>
            <input class="input_tmp_validator" id="property">
          </div>
        </div>
        <div class="p-card-field">
          <label class="p-col-fixed txt-right" :class="`${mode=='add' ? 'txt-required' : ''}`">Week</label>
          <div class="p-col">
            <v-select :disabled="mode=='edit'" :getOptionLabel="filterUser"  :clearable="false" title="Week" class="" placeholder="Choose" v-model="selVisit" :options="listVisit" >
            
              <span slot="no-options" @click="$refs.select.open = false">
                Empty data
              </span>
              <template slot="option" slot-scope="option">
                  {{ `Week ${option.week_number} (${getFilterDate(option.visit_start)} - ${getFilterDate(option.visit_end)})`}}
              </template>
              
              <template slot="selected-option" slot-scope="option">
                  {{ `Week ${option.week_number} (${getFilterDate(option.visit_start)} - ${getFilterDate(option.visit_end)})`}}
              </template>
            </v-select>
            <input class="input_tmp_validator" id="visit">
          </div>
        </div>
        <div class="p-card-field">
          <label class="p-col-fixed txt-right">User</label>
          <div class="p-col">
            <InputText type="text" class="" v-model="user_fullname" :disabled="true" placeholder="Input"/>
          </div>
        </div>
        <div class="p-card-field">
          <label class="p-col-fixed txt-right" :class="`${mode=='add' ? 'txt-required' : ''}`">Service</label>
          <div class="p-col">
            <v-select :disabled="mode=='edit'" :clearable="false" title="Service" class="" @search="SearchService" placeholder="Choose" v-model="selService" :options="listService" label="name"  >
              <span slot="no-options" @click="$refs.select.open = false">
                Empty data
              </span>
            </v-select>
            <input class="input_tmp_validator" id="service">
          </div>
        </div>
        <div class="p-card-field" v-if="mode == 'edit'">
          <label class="p-col-fixed txt-right">Price</label>
          <div class="p-col">
            <div class="p-inputgroup">
              <InputText v-model="price" :disabled="true"/>
              <span class="p-inputgroup-addon">EUR</span>
            </div>
          </div>
        </div>
        
        <div class="p-card-field">
          <label class="p-col-fixed txt-right" :class="`${['ST001', 'ST002'].includes(status.value) ? 'txt-required' : ''}`">Total</label>
          <div class="p-col">
            <div class="p-inputgroup">
              <InputNumber v-model="total" :min="0" id="total" :disabled="!['ST001', 'ST002'].includes(status.value)" :minFractionDigits="2"/>
              <span class="p-inputgroup-addon">EUR</span>
            </div>
          </div>
        </div>
        
        <div class="p-card-field row">
          <div class="col-6">
            <label class="p-col-fixed txt-right">Payment method</label>
            <div class="p-col">
              <InputText type="text" class="" v-model="payment_method" :disabled="true"/>
            </div>
          </div>
          
          <div class="col-6">
            <label class="p-col-fixed txt-right">Payment status</label>
            <div class="p-col">
              <InputText type="text" class="" v-model="payment_status" :disabled="true"/>
            </div>
          </div>
        </div>
        <div class="p-card-field" v-if="order_code">
          <label class="p-col-fixed txt-right">Order ID</label>
          <div class="p-col pl-1">
            <div class="txt-disabled"><a class="txt-link" @click="goToPath(order_id)">{{order_code}}</a></div>
          </div>
        </div>
        <div class="p-card-field" v-if="showAssign">
          <label class="p-col-fixed txt-right">Assign</label>
          <div class="p-col">
            <v-select :clearable="false" title="Assign" class="" @search="SearchAssign" placeholder="Choose" v-model="selAssign" :options="listAssign" label="fullname"  >
              <span slot="no-options" @click="$refs.select.open = false">
                Empty data
              </span>
            </v-select>
          </div>
        </div>
        <div class="p-card-field" v-if="showTTF">
          <label class="p-col-fixed txt-right">Time to be finish</label>
            <Calendar 
              :showOnFocus="true" 
              dateFormat="MM dd, yy"
              class="pl-1"
              id="time_to_finish"
              :showIcon="true" 
              appendTo="body"
              :showClear="true" 
              :manualInput="false"
              v-model="time_to_finish"
              :showTime="true"
              placeholder="Choose"
            />
        </div>
        <div class="p-card-field mt-2 mb-2">
          <h5 class="pl-2">NOTE</h5>
        </div>
        <div class="p-card-field" v-if="mode == 'edit'">
          <label class="p-col-fixed txt-right">User's note</label>
          <div class="p-col">
            <Textarea maxLength="2000" :disabled="true" rows="2" cols="30" :autoResize="true" v-model="note"/>
          </div>
        </div>
        
        <div class="p-card-field">
          <label class="p-col-fixed txt-right">Admin's note</label>
          <div class="p-col">
            <Textarea maxLength="2000" rows="2" cols="30" :autoResize="true" v-model="admin_note"/>
          </div>
        </div>
        
      </div>
      <Toolbar class="fixed-bottom-toolbar div-button">
        <template slot="right">
          <Button
              :label="$constants.TEXT_BUTTON.BACK"
              @click="backToList()"
              class="p-button-outlined"
              id="back"
          />
          <Button
              v-if="mode == 'edit'"
              :label="$constants.TEXT_BUTTON.EDIT"
              @click="saveData()"
              class="main-style-button"
          />
          <Button
              v-else
              :label="$constants.TEXT_BUTTON.ADD"
              @click="saveData()"
              class="main-style-button"
          />
        </template>
      </Toolbar>
    </div>
  </form>
</template>
<script>
import DataServices from '@/core/DataServices';
import moment from "moment-timezone";
export default {
  components: {
  },
  props: {
    mode: String,
    model: Object,
    users: Array
  },
  data() {
    return {
      total: 0,
      code: null,
      selService: null,
      listService: [],
      listServiceAll: [],
      order_id: null,
      order_code: null,
      selAssign: null,
      listAssign: [],
      listAssignAll: [],
    
      listProduct: [],
      listProductAll: [],
      
      selVisit: null,
      listVisit: [],
      listVisitAll: [],
      note: null,
      status: {
          label: 'New',
          value: 'ST001'
      },
      checkStatus: 'ST001',
      payment_method: null,
      payment_status: null,
      selProperty: null,
      listProperty: [],
      listPropertyAll: [],
      time_to_finish: null,
      showAssign: false,
      showTTF: false,
      user_fullname: null,
      admin_note: null,
      qty: 1,
      price: 0,
      type_of_price: null,
      products: [],
      time_zone_local : moment.tz.guess(true),
      arrival: null,
      leaving: null,
      componentKey: 0
    }
  },
  computed: {
    // getTotal() {
    //   var total = 0;
    //   if (this.type_of_price == 4) {
    //     total = this.products.reduce((a, b) => a + ((b?.qty ?? 0) * (b?.product?.price ?? 0)),0);
    //     console.log(total);
    //   } else {
    //     total = this.qty * this.price;
    //   }
    //   return total;
    // }
  },
  watch: {
    async "selVisit"() {
      this.user_fullname = this.selVisit?.fullname;
    },
    async "selProperty"() {
      if (!this.$commonFuction.isEmptyObject(this.selProperty)) {
        await this.getListVisit();
        let type_arr = this.selService?.type_of_property_arr || [];
        this.price = type_arr.filter(f => f.type_id == this.selProperty?.type_id)[0]?.call_to_action_price || null;
      }
    },
    async "selService"() {
      // this.type_of_price = this.selService?.type_of_price || null;
      // console.log("this.selService", this.selService);
      let type_arr = this.selService?.type_of_property_arr || [];
      console.log("type_arr", type_arr);
      this.price = type_arr.filter(f => f.type_id == this.selProperty?.type_id)[0]?.call_to_action_price || null;
      this.showAssign = this.selService?.need_assign;
      this.showTTF = this.selService?.time_to_finish;
      // if (this.type_of_price == 4) {
      //   this.getListProduct();
      // }
    },
    // async "type_of_price"() {
    //   if (this.type_of_price == 2) {
    //     this.qty = 1;
    //   }
    //   if (this.type_of_price == 4 && this.products.length < 1) {
    //       this.addProduct();
    //   }
    // },
    // async "qty"() {
    //   this.total = this.qty * this.price;
    // },
    // async "price"() {
    //   this.total = this.qty * this.price;
    // },
    // async "products.price"() {
    //   if (this.type_of_price == 4) {
    //     this.total = this.products.reduce((a, b) => a + ((b?.qty ?? 0) * (b?.product?.price ?? 0)),0);
    //     console.log(this.total);
    //   }
    // }
  },
  async mounted() {
    await this.getListProperty();
    await this.getListService();
    await this.getListAssign();
    if (this.model !== undefined && Object.keys(this.model).length > 0) {
      this.code = this.model?.request?.code;
      this.selProperty = {id : this.model.request.property.id, name : this.model.request.property.name, code: this.model.request.property.code, type_id: this.model.request.property.type_id};
      this.selService = this.model?.obj_service ? {id : this.model.obj_service.id, name :this.model.obj_service.name, type_of_price: this.model.obj_service.type_of_price, type_of_property_arr: this.model.obj_service.type_of_property_arr, need_assign: this.model.obj_service.need_assign, time_to_finish: this.model.obj_service.time_to_finish} : null;
      this.selAssign = this.model.assign;
      this.payment_method = this.model?.order_item?.order?.payment_method ? [{value: 1, label: 'Online'}, {value: 2, label: 'Cash'}].filter(f => f.value == this.model?.order_item?.order?.payment_method)[0]?.label : '';
      this.payment_status = this.model?.order_item?.order?.status ? [{value: 'ST001', label: 'Waiting'}, {value: 'ST002', label: 'Paid'}, {value: 'ST003', label: 'Failed'}].filter(f => f.value == this.model?.order_item?.order?.status)[0]?.label : '';
      this.order_id = this.model?.order_item?.order?.id || null;
      this.order_code = this.model?.order_item?.order?.code || null;
      this.time_to_finish = this.model?.time_to_finish ? moment.tz(this.model.time_to_finish, this.time_zone_local).format("LL HH:mm") : null;
      this.note = this.model.note;
      this.admin_note = this.model.admin_note;
      this.status = this.$constants.REQUEST_STATUS.filter(f => f.value == this.model.payment_status)[0];
      this.checkStatus = this.model.status;
      this.price = this.model.obj_service?.call_to_action_price || '';
      this.total = this.model.price;
      this.selVisit = {id : this.model.request.visit.id, uid: this.model.request.visit.user.id, fullname: this.model.request.visit.user.fullname, week_number: this.model.request.visit.week_number, visit_start: this.model.request.visit.visit_start, visit_end: this.model.request.visit.visit_end};
      this.user_fullname = this.model.request.visit.user.fullname;
    }
  },
  methods: {
    goToPath(id) {
      this.$router.push({path: `/admin/list-service-order/view/${id}`});
    },
    removeProduct(index) {
      this.products.splice(index, 1);
    },
    addProduct() {
      this.products.push({
        product: {price: 0},
        price: 0,
        qty: 0
      })
    },
    getFilterDate(date) {
        return moment.tz(date, this.time_zone_local).format("LL");
    },
    async getListProperty(){

      var where_key = {
        deleted : {_eq : false},
        // booking: {id: {_is_null: true} },
      }
      let {data} = await this.$apollo.query({
        query: DataServices.getList('property',{"fields" : `id text_group_property code name type_id`}),
        variables: {
          where_key: where_key,
          orderBy:[{created_at : 'desc'}]
        },
        fetchPolicy: "network-only"
      })
      var list = data[Object.keys(data)];
      var list_property = [];
      // console.log("list===",list);
      for (let i = 0; i < list.length; i++) {
        const e = list[i];
        //if (!e?.booking?.id || (this.selProperty?.id && this.selProperty?.id == e.id)) {
          list_property.push({id : e.id, name :e.text_group_property, code: e.code, type_id: e.type_id});
        //}
      }
      this.listProperty = list_property;
      this.listPropertyAll = list_property;
    },
    
    async SearchProperty(search, loading){
      console.log(loading);
      if(search !== '' && search !== null){
        var where_key = {
          deleted : {_eq : false},
          // booking: {id: {_is_null: true} },
          _or: {
            text_group_property: {_iregex: this.$commonFuction.search(search)},
          }
        };
        let {data} = await this.$apollo.query({
          query: DataServices.getList('property',{"fields" : `id text_group_property code name type_id`}),
          variables: {
            where_key: where_key,
            orderBy:[{created_at : 'desc'}]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_property = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          //if (!e?.booking?.id || (this.selProperty?.id && this.selProperty?.id == e.id)) {
            list_property.push({id : e.id, name :e.text_group_property, code: e.code, type_id: e.type_id});
          //}
        }
        this.listProperty = list_property;
      }else{
        // cosnole.log("dasdasd")
        this.listProperty = this.listPropertyAll
      }
    },
    async getListService(){

      var where_key = {
        deleted : {_eq : false},
        type_of_price: {_eq: '3'},
      }
      let {data} = await this.$apollo.query({
        query: DataServices.getList('service',{"fields" : `id name type_of_price need_assign time_to_finish type_of_property_arr{call_to_action_price type_id}`}),
        variables: {
          where_key: where_key,
          orderBy:[{created_at : 'desc'}]
        },
        fetchPolicy: "network-only"
      })
      var list = data[Object.keys(data)];
      var list_service = [];
      // console.log("list===",list);
      for (let i = 0; i < list.length; i++) {
        const e = list[i];
        //if (!e?.booking?.id || (this.selProperty?.id && this.selProperty?.id == e.id)) {
          list_service.push({id : e.id, name :e.name, type_of_price: e.type_of_price, type_of_property_arr: e.type_of_property_arr, need_assign: e.need_assign, time_to_finish: e.time_to_finish});
        //}
      }
      this.listService = list_service;
      this.listServiceAll = list_service;
    },
    
    async SearchService(search, loading){
      console.log(loading);
      if(search !== '' && search !== null){
        var where_key = {
          deleted : {_eq : false},
          type_of_price: {_eq: '3'},
          _or: {
            name: {_iregex: this.$commonFuction.search(search)},
          }
        };
        let {data} = await this.$apollo.query({
          query: DataServices.getList('service',{"fields" : `id name type_of_price need_assign time_to_finish type_of_property_arr{call_to_action_price type_id}`}),
          variables: {
            where_key: where_key,
            orderBy:[{created_at : 'desc'}]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_service = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          //if (!e?.booking?.id || (this.selProperty?.id && this.selProperty?.id == e.id)) {
            list_service.push({id : e.id, name :e.name, type_of_price: e.type_of_price, type_of_property_arr: e.type_of_property_arr, need_assign: e.need_assign, time_to_finish: e.time_to_finish});
          //}
        }
        this.listService = list_service;
      }else{
        // cosnole.log("dasdasd")
        this.listService = this.listServiceAll
      }
    },
    async getListProduct(){
      if (!this.selService?.id) {
        this.listProduct = [];
        this.listProductAll = [];
        return;
      }
      var where_key = {
        deleted : {_eq : false},
        active: {_eq: true},
        service_id: {_eq: this.selService?.id},
      }
      let {data} = await this.$apollo.query({
        query: DataServices.getList('product',{"fields" : `id name price`}),
        variables: {
          where_key: where_key,
          orderBy:[{created_at : 'desc'}]
        },
        fetchPolicy: "network-only"
      })
      var list = data[Object.keys(data)];
      var list_produce = [];
      // console.log("list===",list);
      for (let i = 0; i < list.length; i++) {
        const e = list[i];
        //if (!e?.booking?.id || (this.selProperty?.id && this.selProperty?.id == e.id)) {
          list_produce.push({id : e.id, name :e.name, price: e.price});
        //}
      }
      this.listProduct = list_produce;
      this.listProductAll = list_produce;
    },
    
    async SearchProduct(search, loading){
      console.log(loading);
      if (!this.selService?.id) {
        this.listProduct = [];
        this.listProductAll = [];
        return;
      }
      if(search !== '' && search !== null){
        var where_key = {
          deleted : {_eq : false},
          active: {_eq: true},
          service_id: {_eq: this.selService?.id},
          _or: {
            name: {_iregex: this.$commonFuction.search(search)},
          }
        };
        let {data} = await this.$apollo.query({
          query: DataServices.getList('product',{"fields" : `id name price`}),
          variables: {
            where_key: where_key,
            orderBy:[{created_at : 'desc'}]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_produce = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          //if (!e?.booking?.id || (this.selProperty?.id && this.selProperty?.id == e.id)) {
            list_produce.push({id : e.id, name :e.name, price: e.price});
          //}
        }
        this.listProduct = list_produce;
      }else{
        // cosnole.log("dasdasd")
        this.listProduct = this.listProductAll
      }
    },
    filterUser(option) {
      if (typeof option === 'object') {
        return `Week ${option.week_number} (${this.getFilterDate(option.visit_start)} - ${this.getFilterDate(option.visit_end)})`;
      }
      return option;
    },
    async getListVisit(){
      if (!this.selProperty?.id) {
        this.listVisit = [];
        this.listVisitAll = [];
        return;
      }
      var where_key = {
        deleted : {_eq : false},
        property_id: {_eq: this.selProperty?.id},
      }
      let {data} = await this.$apollo.query({
        query: DataServices.getList('visit',{"fields" : `id week_number visit_start visit_end user {id fullname}`}),
        variables: {
          where_key: where_key,
          orderBy:[{created_at : 'desc'}]
        },
        fetchPolicy: "network-only"
      })
      var list = data[Object.keys(data)];
      var list_visit = [];
      // console.log("list===",list);
      for (let i = 0; i < list.length; i++) {
        const e = list[i];
        //if (!e?.booking?.id || (this.selProperty?.id && this.selProperty?.id == e.id)) {
          list_visit.push({id : e.id, uid: e.user.id, fullname :e.user.fullname, week_number: e.week_number, visit_start: e.visit_start, visit_end: e.visit_end});
        //}
      }
      this.listVisit = list_visit;
      this.listVisitAll = list_visit;
    },
    
    // async SearchVisit(search, loading){
    //   console.log(loading);
    //   if (!this.selProperty?.id) {
    //     this.listVisit = [];
    //     this.listVisitAll = [];
    //     return;
    //   }
    //   if(search !== '' && search !== null){
    //     var where_key = {
    //       deleted : {_eq : false},
    //       property_id: {_eq: this.selProperty?.id},
    //       _or: {
    //         _iregex: this.$commonFuction.search(search),
    //       }
    //     };
    //     let {data} = await this.$apollo.query({
    //       query: DataServices.getList('visit',{"fields" : `id week_number visit_start visit_end user {id fullname}`}),
    //       variables: {
    //         where_key: where_key,
    //         orderBy:[{created_at : 'desc'}]
    //       },
    //       fetchPolicy: "network-only"
    //     })
    //     var list = data[Object.keys(data)];
    //     var list_visit = [];
    //     // console.log("list===",list);
    //     for (let i = 0; i < list.length; i++) {
    //       const e = list[i];
    //       //if (!e?.booking?.id || (this.selProperty?.id && this.selProperty?.id == e.id)) {
    //         list_visit.push({id : e.id, uid: e.user.id, fullname :e.user.fullname, week_number: e.week_number, visit_start: e.visit_start, visit_end: e.visit_end});
    //       //}
    //     }
    //     this.listVisit = list_visit;
    //   }else{
    //     // cosnole.log("dasdasd")
    //     this.listVisit = this.listVisitAll;
    //   }
    // },
    async getListAssign(){

      var where_key = {
        deleted : {_eq : false},
        // booking: {id: {_is_null: true} },
      }
      let {data} = await this.$apollo.query({
        query: DataServices.getList('users',{"fields" : `id fullname`}),
        variables: {
          where_key: where_key,
          orderBy:[{created_at : 'desc'}]
        },
        fetchPolicy: "network-only"
      })
      var list = data[Object.keys(data)];
      var list_assign = [];
      // console.log("list===",list);
      for (let i = 0; i < list.length; i++) {
        const e = list[i];
        //if (!e?.booking?.id || (this.selProperty?.id && this.selProperty?.id == e.id)) {
          list_assign.push({id : e.id, fullname :e.fullname});
        //}
      }
      this.listAssign = list_assign;
      this.listAssignAll = list_assign;
    },
    
    async SearchAssign(search, loading){
      console.log(loading);
      if(search !== '' && search !== null){
        var where_key = {
          deleted : {_eq : false},
          // booking: {id: {_is_null: true} },
          _or: {
            fullname: {_iregex: this.$commonFuction.search(search)},
          }
        };
        let {data} = await this.$apollo.query({
          query: DataServices.getList('users',{"fields" : `id fullname`}),
          variables: {
            where_key: where_key,
            orderBy:[{created_at : 'desc'}]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_assign = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          //if (!e?.booking?.id || (this.selProperty?.id && this.selProperty?.id == e.id)) {
            list_assign.push({id : e.id, fullname :e.fullname});
          //}
        }
        this.listAssign = list_assign;
      }else{
        // cosnole.log("dasdasd")
        this.listAssign = this.listAssignAll;
      }
    },
    backToList() {
      this.$emit('back');
    },
    async validateData() {
      this.validationErrors = {};


      let property_add = document.getElementById("property");
      if (this.$commonFuction.isEmptyObject(this.selProperty)){
        property_add.setCustomValidity("Please choose the property!");
      }else {
        property_add.setCustomValidity("");
      }
      
      let visit_add = document.getElementById("visit");
      if (this.$commonFuction.isEmptyObject(this.selVisit)){
        visit_add.setCustomValidity("Please choose the week!");
      }else {
        visit_add.setCustomValidity("");
      }
      
      let service_add = document.getElementById("service");
      if (this.$commonFuction.isEmptyObject(this.selService)){
        service_add.setCustomValidity("Please choose the service!");
      }else {
        service_add.setCustomValidity("");
      }

      if (['ST001', 'ST002'].includes(this.status.value)) {
        let qty_add = document.getElementById("total");
        if (this.$commonFuction.isEmpty(this.total)){
          qty_add.setCustomValidity("Please enter the total!");
        }else {
          qty_add.setCustomValidity("");
        }
      }

      var inpObj = document.getElementById("frm_action");
      if (!inpObj.checkValidity()){
        inpObj.reportValidity();
        return true;
      }
    },

    async saveData() {
      this.$commonFuction.is_loading(true);
      if (await this.validateData()) {
        this.$commonFuction.is_loading(false);
        return
      }
      var params = {
        property_id: this.selProperty?.id,
        service_id: this.selService?.id,
        assign_id: this.selAssign?.id,
        time_to_finish: moment(this.time_to_finish, this.time_zone_local),
        status: this.status?.value,
        price: this.total,
        visit_id: this.selVisit?.id,
        uid: this.selVisit?.uid,
        admin_note: this.admin_note
      }
      console.log('saveData', params)
      this.$emit('save', params)
    },
  },
}
</script>

<style lang="scss">
.p-multiselect {
  width: 450px;
}
.p-multiselect-label:not(.p-placeholder) {
  padding-top: .25rem;
  padding-bottom: .25rem;

}
.category-item-value {
  padding: .25rem .5rem;
  border-radius: 3px;
  display: inline-flex;
  margin-right: .2rem;
  background-color: var(--primary-color);
  color: var(--primary-color-text);
}
.p-multiselect-label-container{
  height: 40px;
}
.p-inputtext{
  margin:  0px 0px 0px 4px;
}

//style form add-edit
@media screen and (min-width: 1001px) {
  .input_distance {
    width: 728px !important;
  }
}
label.p-col-fixed txt-right{
  margin-bottom: 0px!important;
}
div.p-card-field{
  padding: 0px!important;
}
.p-col-fixed {
  padding: 0px !important;
  margin:0px 0px 0px 9px !important;
}
.input_text{
  width: 760px!important;
  height: 48px!important;
}
.txt-right{
  font-weight: bold;
}

.multiselect{
  width: 760px!important;
}
.layout-main{
  background: #00000029;
}

.vs__clear{
  display: none;
}
.input_width{
  width: 760px!important;
  height: 48px!important;
}
#distance{
  width: 1px!important;
  height: 48px!important;
  border-left: 0px;
  border-top: 0px;
  border: 1px solid #C8C8C8;
  border-radius: 0px;
  //border-top: none!important;
  //border: 1px solid rgba(60, 60, 60, 0.26);;
}
.manager-title{
  font-size: 24px!important;
}
.input-dif{
  margin-left: 5px!important;
  border-top: 0px;
}
.p-inputgroup-addon{
  height: 48px ;
}
.txt-right{
  font-weight: bold;
}
.p-col-fixed {
  padding: 0px !important;
  //margin:0px 0px 0px 10px !important;
}
label.p-col-fixed txt-right{
  margin-bottom: 0px!important;
}
div.p-card-field{
  padding: 0px!important;
}
.margin{
  margin-bottom: 12px!important;
}

.border_color{
  border: 1px solid #C8C8C8;
}
.text{
  font-weight: normal!important;
  margin-left: 15px!important;
}
.div_check{
  margin-top: 12px!important;
}


//responsive
@media screen and (max-width: 600px) {
  .input_width{
    width: 100%!important;
  }
  .div-button{
    padding: 8px!important;
  }
  #distance{
    text-align: left!important;
    padding: 6.8px;
    width: 100%!important;
  }
  .p-inputgroup-addon{
    margin-right: 12px;
  }

}

@media screen and (max-width: 1000px) {
  .input_width{
    width: 100%!important;
  }
  .div-button{
    padding: 8px!important;
  }
  .p-inputgroup-addon{
    margin-right: 12px;
  }
  #distance{
    text-align: left!important;
    padding: 6.8px;
  }
}
.p-button-outlined {
  .p-button-label {
    color: #007ad9 !important;
  }
}
.txt-disabled {
  padding: 0.429rem 0.429rem;
  margin: 0 0 0 4px;
  border: 1px solid #a6a6a6;
  background-color: #eeeeee;
  border-radius: 5px;
  opacity: 0.5;
}
</style>
